import React, { Component } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import Trendingslider from '../trendingslider';
import TokenListModal from "../Launchpad/TokenListModal";
import Walletmodal from "../Walletmodal";

import "../../css/styles.css";
import favicon from "../../images/Bitdeal/logo_curve.png";

import {
  Container,
  InputGroup,
  FormControl
} from "react-bootstrap";
import { validliquiditytoken, validstandardtoken } from "../../hooks/kycvalidation";
import { CreateLiquidityToken, CreateStandardToken, GetAdminfee, Getbalance, sleep, toFixedNumber } from "../../hooks/useContract";
import { UserTokenList, addTokenCreationhook } from "../../hooks/usebackend";
import { getAccount } from "../../hooks/useAccount";

class CreateToken extends Component {


  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }


  showLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("loader_div_connect").classList.remove("d-none");
    document.getElementById("loader_div_connect").classList.add("d-block");


  }

  hideLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("loader_div_connect").classList.remove("d-block");
    document.getElementById("loader_div_connect").classList.add("d-none");
  }

  async fetchdata1() {
    console.log("coming1", await window?.ethereum?._metamask?.isUnlocked())
    if (!localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")) {
      console.log("coming", await window?.ethereum?._metamask?.isUnlocked())
      this.showLoaderConnect()
    }
    else {
      console.log("hiding")
      this.hideLoaderConnect();
    }
  }


  onDismiss() {
    this.setState({ walletModal: false });
    if (sessionStorage.getItem("accountInfo")) {
      this.hideLoaderConnect();
    }
  }

  componentDidMount() {
    document.getElementById("launchpad_parent").classList.add("active");
    this.hideLoader();
    this.getfee();
    this.gettokensData();
    this.fetchdata1()

  }
  getfee = async () => {
    let fee = await GetAdminfee();
    console.log("fee", fee);
    this.setState({ poolfee: fee.tokenfee / 10 ** 18 });
    this.setState({ liqfee: fee.liquidityTokenFee / 10 ** 18 });
  }



  gettokensData = async () => {

    var accountInfo = await getAccount();
    var Tokensinfo = await UserTokenList(accountInfo);
    this.setState({ tokenlength: Tokensinfo?.data?.length })
  };



  constructor(props) {
    super(props);
    this.state = {
      walletModal: false,
      accountInfo: '',

      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: "",
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      setUpdateCms: false,
      tokenListModal: false,
      tokenlength: 0,
      tokentype: "Standard Token",
      name: "",
      symbol: "",
      decimal: "",
      tokensupply: "",
      errors: {},
      yieldfee: "",
      liquidityfee: "",
      exchangethresold: "",
      maximumtransaction: "",
      userbalance: Getbalance(),
      poolfee: "",
      liqfee: "",
      useraddress: getAccount(),
    };
  }





  handlecreatetoken = async () => {
    console.log("userbalance", this.state.userbalance)

    if (this.state.tokentype == "Standard Token") {
      let payload = {
        name: this.state.name,
        symbol: this.state.symbol,
        decimal: this.state.decimal,
        totalsupply: this.state.tokensupply
      }
      let validate = await validstandardtoken(payload);
      if (validate?.isValid) {
        let arg = [];
        arg[0] = this.state.name;
        arg[1] = this.state.symbol;
        arg[2] = this.state.decimal;
        arg[3] = (toFixedNumber(this.state.tokensupply * 10 ** this.state.decimal)).toString()
        let tokendata = await CreateStandardToken(arg).then(async (data) => {
          console.log("data>>>>", data);
          await sleep(1000)
          if (data) {
            const tokenList = {
              useraddress: data?.from,
              tokenaddress: data?.events[0].address,
              name: this.state.name,
              symbol: this.state.symbol,
              decimal: this.state.decimal,
              type: "StandardToken"
            }
            await addTokenCreationhook(tokenList)
          }


          console.log("tokendata", data);
          sessionStorage.setItem("HASH", JSON.stringify(data))
          this.props.history.push("/tokensuccess", { state: "token" })
        })
      }
      else {
        this.setState({ errors: validate?.errors })
      }
    }

    if (this.state.tokentype == "Liquidity Generator Token") {
      let payload = {
        name: this.state.name,
        symbol: this.state.symbol,
        decimal: this.state.decimal,
        totalsupply: this.state.tokensupply,
        yieldfee: this.state.yieldfee,
        liquidityfee: this.state.liquidityfee,
        maximumtransaction: this.state.maximumtransaction,
        exchangethresold: this.state.exchangethresold
      }
      console.log("payload", payload);
      let validate = await validliquiditytoken(payload);
      if (validate?.isValid) {
        let arg = [];
        arg[0] = this.state.name;
        arg[1] = this.state.symbol;
        arg[2] = this.state.decimal;
        arg[3] = this.state.tokensupply;
        arg[4] = this.state.yieldfee;
        arg[5] = this.state.liquidityfee;
        arg[6] = this.state.maximumtransaction;
        arg[7] = this.state.exchangethresold;
        let tokendata = await CreateLiquidityToken(arg).then(async (data) => {
          console.log("data>>>>>>", data);
          await sleep(1000)
          if (data) {
            const tokenList = {
              useraddress: data?.from,
              tokenaddress: data?.events[0].address,
              name: this.state.name,
              symbol: this.state.symbol,
              decimal: this.state.decimal,
              type: "LiquidityToken"
            }
            await addTokenCreationhook(tokenList)
          }



          sessionStorage.setItem("HASH", JSON.stringify(data))
          this.props.history.push("/tokensuccess", { state: "token" })
        })
      }
      else {
        this.setState({ errors: validate?.errors })
      }
    }
  }


  render() {
    console.log("userbalance", this.state.userbalance)

    const location = this.props.location.pathname.split("/")[1];

    const {
      tokenListModal,
      walletModal
    } = this.state;

    return (

      <div id="loader_main">
        <div id="loader_div">
          {tokenListModal && <TokenListModal onDismiss={() => this.setState({ tokenListModal: false })} />}
          <span className="spin_round"></span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className="logo_overlay" id="logo_overlay">
          <div className="whole_bg">
            <Header />

            <div className="whole_sec pb-5">
              <div className="flex_side_right">
                <Sidebar />
                <div className="right_side_sec pb-5">
                  <div id="loader_div_connect">

                    <div className='load_connect_text'>
                      <button className="get-started-btn btn_connect_loader" onClick={() => this.setState({ walletModal: true })}>
                        Connect Wallet to Continue
                      </button>
                    </div>

                  </div>

                  <Trendingslider />
                  <div className="right_side_spacing">
                    <Container className="mt-5 pb-3 px-1">
                      <div className='col-12  col-md-12 col-lg-12 mx-auto px-0'>
                        <div className="tab_img">
                          <div className='card_grad_new card'>
                            <div className='card-body'>
                              <p className='overlay_card_head text-blak pb-3'>Create Token</p>
                              <div>
                                {this.state.tokenlength > 0 ?
                                  <div className=" text-right ">
                                    <button className="btn_black" onClick={() => this.setState({ tokenListModal: true })}>View Token List</button>
                                  </div>
                                  : <></>}
                                <div className='row'>


                                  <div className='col-12 col-md-12 mb-0'>
                                    <p className='input_desc_sm'>Token Type <span className="text-success">*</span> </p>
                                    <div className="inputs input-groups">


                                      <select className="form-control custm_sel" id="exampleFormControlSelect3"
                                        value={this.state.tokentype}
                                        onChange={(e) => { this.setState({ "tokentype": e.target.value }) }} placeholder="Standard Token"
                                      >
                                        <option value="Standard Token">Standard Token</option>
                                        <option value="Liquidity Generator Token">Liquidity Generator Token</option>

                                      </select>

                                    </div>
                                    <div className='note_desc mt-1 mb-0'>
                                      <p>Token Creation Fee {this.state?.tokentype == "Standard Token" ? this.state.poolfee : this.state.liqfee} BNB</p>
                                    </div>
                                  </div>

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>Name <span className="text-success">*</span></p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="hardcap" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }); }} placeholder="Ex:Ethereum"
                                          aria-describedby="basic-addon2"
                                        />

                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.name}</span>
                                    </div>
                                  </div>

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>Symbol <span className="text-success">*</span></p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="mincontribution" value={this.state.Symbol} onChange={(e) => this.setState({ symbol: e.target.value })} placeholder="Ex:BNB"
                                          aria-describedby="basic-addon2"

                                        />

                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.symbol}</span>
                                    </div>
                                  </div>

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>Decimals <span className="text-success">*</span></p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="decimals" placeholder="Ex: 18"
                                          aria-describedby="basic-addon2"
                                          onChange={(e) => this.setState({ decimal: e?.target?.value })}
                                        />

                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.decimal}</span>
                                    </div>
                                  </div>

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>Total Supply <span className="text-success">*</span></p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="totalsupply" placeholder="Ex: 100000000000"
                                          aria-describedby="basic-addon2"
                                          onChange={(e) => this.setState({ tokensupply: e?.target?.value })}
                                        />

                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.totalsupply}</span>
                                    </div>
                                  </div>

                                  {this.state.tokentype == "Liquidity Generator Token" &&
                                    <>
                                      <div className='col-12 col-md-12 mb-3'>
                                        <p className='input_desc_sm'>Transaction fee to generate yield (%) <span className="text-success">*</span></p>
                                        <div className="inputs input-groups">
                                          <InputGroup className="">
                                            <FormControl id="totalsupply" placeholder="Ex: 51"
                                              aria-describedby="basic-addon2"
                                              onChange={(e) => this.setState({ yieldfee: e?.target?.value })}
                                            />

                                          </InputGroup>
                                          <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.yieldfee}</span>
                                        </div>
                                      </div>

                                      <div className='col-12 col-md-12 mb-3'>
                                        <p className='input_desc_sm'>Transaction fee to generate liquidity (%) <span className="text-success">*</span></p>
                                        <div className="inputs input-groups">
                                          <InputGroup className="">
                                            <FormControl id="totalsupply" placeholder="Ex: 51"
                                              aria-describedby="basic-addon2"
                                              onChange={(e) => this.setState({ liquidityfee: e?.target?.value })}
                                            />

                                          </InputGroup>
                                          <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.liquidityfee}</span>
                                        </div>
                                      </div>

                                      <div className='col-12 col-md-12 mb-3'>
                                        <p className='input_desc_sm'>Maximum Transaction Amount<span className="text-success">*</span></p>
                                        <div className="inputs input-groups">
                                          <InputGroup className="">
                                            <FormControl id="hardcap" value={this.state.maximumtransaction} onChange={(e) => { this.setState({ maximumtransaction: e.target.value }); }} placeholder="Ex:10"
                                              aria-describedby="basic-addon2"
                                            />

                                          </InputGroup>
                                          <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.maximumtransaction}</span>
                                        </div>
                                      </div>

                                      <div className='col-12 col-md-12 mb-3'>
                                        <p className='input_desc_sm'>Exchange Thersold <span className="text-success">*</span></p>
                                        <div className="inputs input-groups">
                                          <InputGroup className="">
                                            <FormControl id="hardcap" value={this.state.exchangethresold} onChange={(e) => { this.setState({ exchangethresold: e.target.value }); }} placeholder="Ex:10"
                                              aria-describedby="basic-addon2"
                                            />

                                          </InputGroup>
                                          <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.exchangethresold}</span>
                                        </div>
                                        <div className='note_desc mt-1 mb-0'>
                                          <p>If you keep exchange threshold  is lower than adding liquidity, will be frequent</p>
                                        </div>
                                      </div>
                                    </>
                                  }

                                  <div className='col-12 col-md-12 pb-4 text-center'>
                                    <button className="btn_black mt-2"

                                      disabled={this.state.userbalance < 0.01}
                                      onClick={() => {
                                        this.handlecreatetoken()

                                      }}
                                    >
                                      Create Token
                                    </button>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Container>

                  </div>

                  <div className="bg_layer mb-5 mt-4 pt-2">
                    <div className="right_side_spacing py-5">

                      <div className="row">

                        <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                          <h3 className="h3_res">Premium Launch Order</h3>
                          <h5 className="h5_res">All in one package </h5>
                          <div className='mt-4 pb-4 div_btn_ful_width'>
                            <button className='get-started-btn orbitron_font text-white'>
                              Contact Agent
                            </button>
                            <button className='get-started-btn-border orbitron_font ml-3'>
                              Learn Premium
                            </button>
                          </div>

                        </div>
                        <div className="col-12 col-md-5 col-xl-6">
                          <p className="title_span mt-4">Ultimate guideline for launching with 1 v 1 support during the process. Instructing project from technical to marketing/branding aspect for the best outcome.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {walletModal && <Walletmodal connect={"string"} onDismiss={() => this.onDismiss()} />}

      </div>
    );
  }
}

export default CreateToken;
