import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Footer from "../Footer";
import EditTrendingModal from "./EditTrendingModal";

import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"

import { Tab, Row, Col } from 'react-bootstrap';
import { getsettinghook, gettrendinghook } from '../../hooks/usebackend';
const cookies = new Cookies();

class AdminTrending extends Component {

  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");



  }

  componentDidMount() {
    this.restrict()
    this.hideLoader();
    this.gettrending()

  }

  restrict = async () => {
    var email = cookies.get('cryp-launch-admin-email');
    console.log("email", email);
    if (!email) {
      this.props.history.push("/login")
    }
  }

  gettrending = async (req, res) => {
    let payload = {
      skip: this.state.skip,
      limit: this.state.limit
    }

    let trending = await gettrendinghook(payload);
    if (trending.data.success == true || trending.data.success == "true") {
      console.log("trending result", trending);
      this.setState({ trending: trending.data.data })
    }
    let count = Math.ceil(trending?.data?.count / this.state.limit)
    this.setState({ "pagecount": count });
  }




  constructor(props) {
    super(props);
    this.state = {
      edittrendingModal: false,
      addtrendingModal: false,
      trending: [],
      singletrending: {},
      skip: 0,
      limit: 10,
      pagecount: 0,
      button: 0,
      currentpage: 1,

      stepcount: 5
    };
  }

  onDismiss() {
    this.setState({ edittrendingModal: false });
  }

  onDismissAdd() {
    this.setState({ addtrendingModal: false });
  }


  handlenextpage = async () => {
    let payload = {
      limit: this.state.limit,
      skip: this.state.skip + this.state.limit
    }
    let result = await getsettinghook(payload);
    this.setState({ "settings": result?.data?.data })
    if (this.state.currentpage % 5 == 0) {
      this.setState({ "button": this.state.button + 5 })
    }
    this.setState({ "skip": this.state.skip + this.state.limit })
    this.setState({ "currentpage": this.state.currentpage + 1 })
  }

  handleprevpage = async () => {
    let payload = {
      limit: this.state.limit,
      skip: this.state.skip - this.state.limit
    }
    let result = await getsettinghook(payload);
    this.setState({ "settings": result?.data?.data })
    if ((this.state.currentpage - 1) % 5 == 0) {
      this.setState({ "button": this.state.button - 5 })
    }
    this.setState({ "skip": this.state.skip - this.state.limit });
    this.setState({ "currentpage": this.state.currentpage - 1 });
  }

  handlenumpage = async (val) => {
    this.setState({ "currentpage": parseInt(val) })
    let payload = {
      limit: this.state.limit,
      skip: (parseInt(val) * 10) - 10
    }

    let result = await getsettinghook(payload);
    this.setState({ "settings": result?.data?.data })
    this.setState({ "skip": (parseInt(val) * 10) - 10 })

  }

  render() {


    const location = this.props.location.pathname.split('/')[1];

    const { edittrendingModal, addtrendingModal } = this.state

    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">

          <div className="whole_bg">
            <Adminheader />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Adminsidebar />
                <div className='right_side_sec pb-5'>

                  <div className="right_side_spacing">

                    <div className='row my-5 row_ppos'>
                      <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                        <div className="tab_img">
                          <div className='bg_layer'>
                            <div className='card-body'>
                              <p className='overlay_card_head mb-0 mt-2'>OnTop List</p>

                              <div className="tab_par px-0 py-3">
                                <Tab.Container defaultActiveKey="second">
                                  <Row className="container-fluid w-100 mx-0 px-0">


                                    <Col lg={12} className="img_center_lg px-0">
                                      <div className='container container_custom px-0'>
                                        <Tab.Content className='min_heighttab_cont'>

                                          <Tab.Pane eventKey="second">
                                            <div class="table-responsive">
                                              {!this.state.trending.length == 0 &&
                                                <table className="table recepients_table">
                                                  <thead>
                                                    <tr>
                                                      <th>Position</th>
                                                      <th>Sale Address</th>
                                                      <th>Display Name</th>
                                                      <th>Status</th>
                                                    </tr>
                                                  </thead>
                                                  {this.state.trending && this.state.trending.map((data, i) => <>
                                                    <tr>
                                                      <td>


                                                        <div className="p-0 table_det">
                                                          <span className="table_text">{data?.position}</span>

                                                        </div>
                                                      </td>
                                                      <td>


                                                        <div className="p-0 table_det">
                                                          <span className="table_text">{data?.saleaddress}</span>

                                                        </div>
                                                      </td>
                                                      <td>{data?.displayname}</td>
                                                      <td>{data?.status}</td>
                                                      <td className="d-flex justify-content-end"><a className="link_text" onClick={() => {
                                                        this.setState({ singletrending: data })
                                                        this.setState({ edittrendingModal: true })
                                                      }}>Edit</a></td>

                                                    </tr>
                                                  </>)}

                                                </table>}
                                            </div>

                                            <div className={this.state.trending.length ? "mt-2 d-flex justify-content-center align-items-center btn_pagin_wrap" : "d-none"}>
                                              <button className="pagination_btn"
                                                onClick={this.handleprevpage}
                                                disabled={this.state.currentpage == 1}
                                              ><i class="fa fa-angle-left" aria-hidden="true"

                                              ></i></button>


                                              {[...Array(this.state.stepcount)].map((val, i) => {
                                                if ((this.state.button + i + 1) <= this.state.pagecount)
                                                  return (
                                                    <button className={this.state.currentpage == (this.state.button + i + 1) ? "pagination_btn active" : "pagination_btn"} value={this.state.button + i + 1} onClick={
                                                      (e) => {
                                                        console.log("e", e?.target?.value);
                                                        this.handlenumpage(e?.target?.value)
                                                      }
                                                    }>{this.state.button + i + 1}</button>
                                                  )
                                              }

                                              )}

                                              <button className="pagination_btn"
                                                onClick={this.handlenextpage}
                                                disabled={this.state.currentpage == this.state.pagecount}
                                              ><i class="fa fa-angle-right" aria-hidden="true"

                                              ></i></button>
                                            </div>

                                            {this.state.trending.length == 0 && <p className='no_data_text'>No Data Found</p>}
                                          </Tab.Pane>


                                        </Tab.Content>
                                      </div>
                                    </Col>
                                  </Row>
                                </Tab.Container>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                  </div>

                  <div className="bg_layer mb-5">
                    <div className="right_side_spacing py-5">

                      <div className="row">

                        <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                          <h3 className="h3_res">Premium Launch Order</h3>
                          <h5 className="h5_res">All in one package </h5>
                          <div className='mt-4 pb-4 div_btn_ful_width'>
                            <button className='get-started-btn orbitron_font text-white'>
                              Contact Agent
                            </button>
                            <button className='get-started-btn-border orbitron_font ml-3'>
                              Learn Premium
                            </button>
                          </div>

                        </div>
                        <div className="col-12 col-md-5 col-xl-6">
                          <p className="title_span mt-4">Ultimate guideline for launching with 1 v 1 support during the process. Instructing project from technical to marketing/branding aspect for the best outcome.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {edittrendingModal && <EditTrendingModal data={this.state.singletrending} onDismiss={() => this.onDismiss()} getset={() => this.gettrending()} />}
          </div>

          <Footer />
        </div>
      </div>
    )
  }
}

export default AdminTrending