import React, { useEffect, useState } from "react";
import {
    useParams,
    useHistory,
    useLocation
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import {
    userEmailActivation,
    sentVerifyLink,
    verifyNewEmail
} from '../../hooks/usebackend';

import toast from "react-hot-toast";


const EmailVerification = (props) => {
    const { authToken } = useParams();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    // state
    const [page, setPage] = useState('loading')


    const verifOldEmail = async () => {
        console.log("verifOldEmail")
        const verify = await sentVerifyLink(authToken);
        console.log( verify,"verifOldEmail")
        if (verify?.data?.data?.status) {
            console.log( verify,"verifOldEmail")
            history.push("/login");
            toast.success(verify?.data?.data?.message);
        } else {
            setPage('error');
        }
    }

    const verifNewEmail = async () => {
        console.log("newEmail")
        const newEmail = await verifyNewEmail(authToken);
        console.log("newEmail",newEmail)
        if (newEmail?.data?.data?.status) {
            history.push("/login");
            toast.success(newEmail?.data?.data?.message);
        } else {
            setPage('error');
            history.push("/login");
        }
    }


    useEffect(() => {
        let pathname = location.pathname;
        if (pathname == '/verify-old-email/' + authToken) {
            console.log(pathname,"authTokenauthToken")
            verifOldEmail();
        } else if (pathname == '/verify-new-email/' + authToken) {
            verifNewEmail();
        }
    }, [authToken])

    return (
        <>
            {
                page == 'loading' && <p>{t('LOADING')}</p>
            }
            {
                page == 'error' && <p>{t('INVALID_URL')}</p>
            }
        </>
    )


}

export default EmailVerification;