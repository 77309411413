import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';


class DeleteIpmodal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteIpModal: true,
            ipAddress: "",
            errors: {}
        };
    }


    
    render() {


        const { deleteIpModal } = this.state


        return (



            <Modal className="wallet-modal" show={deleteIpModal} centered size="md">
                <Modal.Header className='pt-3 header_odal_head'>
                    <h3 className="sec-head ">Delete IP</h3>
                    <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet modal_body pt-0 pb-0 px-0">

                    <div className="wallet-lists px-2">

                        <div className='mod_pad_space pt-0'>
                            <div className='row'>
                                <div className='col-12 col-md-12 px-1 mb-3'>



                                </div>
                                <div className='col-12 col-md-12 px-1 mb-3'>
                                    <p className='input_desc_sm'>Are you sure want to delete this IP Address?</p>
                                </div>


                                <div className='col-12 col-md-12 px-1 text-center pb-4'>
                                <button className="btn_black mt-2 mx-2"
                                onClick={() => { this.props.OnDelete() }}
                            >
                                        Yes
                                    </button>
                                <button className="btn_black_outline mt-2 mx-2" onClick={() => this.props.onDismiss()}>
                                        No
                                    </button>
                                  

                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>



        )
    }

}


export default DeleteIpmodal