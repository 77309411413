import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import wall1 from "../images/metamask_wallet.png";
import wall2 from "../images/connect_wallet.png";

import { MetamaskWallet, WalletConnect } from "../hooks/useWallet"


class Walletmodal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            walletModal: true,
            checkBox: false
        };
    }


    MetaMask = async () => {
        try {
            await MetamaskWallet()
            this.setState({ accountInfo: localStorage.getItem("accountInfo") });
            console.log("Account : ", localStorage.getItem("accountInfo"));
            if (localStorage.getItem("accountInfo")) {
                console.log("accountInfo", localStorage.getItem("accountInfo"))
                this.setState({ walletModal: false })
                window.location.reload()
                toast.success("MetaMask wallet connected", { duration: 5000 })
            }
        }
        catch (e) {
            console.log("e", e)
        }

    }


    enableWallet = async () => {
        this.setState({ walletModal: false })

        const provider = await WalletConnect()
        this.setState({ walletConnect: provider })
        this.setState({ accountInfo: localStorage.getItem("accountInfo") });
        this.setState({ walletModal: false })
        if (localStorage.getItem("accountInfo")) {
            this.setState({ walletModal: false })
            window.location.reload()
            toast.success("Wallet connected", { duration: 5000 })

        }
    }





    render() {


        const { walletModal } = this.state
        console.log("walletModal", walletModal)

        return (

            <Modal className="wallet-modal" show={walletModal} centered size="sm">
                <Modal.Header className='pt-3 header_odal_head'>
                    <h2 className="sec-head walletmname">Connect to a wallet</h2>
                    <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet modal_body pt-0 pb-3 px-0">

                    <div className='walletlist px-3'>

                        <p>Accept <a href=''>Terms of Services</a> and <a href=''>Privacy Policy.</a> </p>

                        <div className="custom-control custom-checkbox wallet_chekc">
                            <input className="custom-control-input" type="checkbox" value="" id="flexCheckDefault"
                                onChange={(e) => {
                                    console.log(e.target.checked, "e.target.check")
                                    if (e.target.checked) {
                                        this.setState({ "checkBox": true })
                                    } else {
                                        this.setState({ "checkBox": false })
                                    }

                                }}
                            />
                            <label className="custom-control-label" for="flexCheckDefault"> I read and accept.</label>

                        </div>



                        <div className='mt-4'>
                            <p className='orbitron_font'>Choose Wallet </p>

                        </div>

                    </div>

                    <div className="wallet-lists px-2 wallet-lists-new-m">
                        <ul className="mb-0">
                            {this.state.checkBox == true ?
                                <li onClick={this.MetaMask} className="liquid_raised mx-2 mx-sm-2 w-100 text-center">
                                    <div className="img">
                                        <img src={wall1} alt="img" />
                                    </div>
                                    <div className="wal-option">
                                        <h3 className="side-head-li mb-0 text-center"> Metamask</h3>

                                    </div>
                                </li> :
                                <li className="liquid_raised mx-2 mx-sm-2 w-100 text-center diable_network_div">
                                    <div className="img">
                                        <img src={wall1} alt="img" />
                                    </div>
                                    <div className="wal-option">
                                        <h3 className="side-head-li mb-0 text-center"> Metamask</h3>

                                    </div>
                                </li>
                            }

                            {this.state.checkBox == true ?
                                <li onClick={this.enableWallet} className="liquid_raised mx-2 mx-sm-2 w-100 text-center">
                                    <div className="img">
                                        <img src={wall2} alt="img" />
                                    </div>
                                    <div className="wal-option">
                                        <h3 className="side-head-li mb-0 text-center">Wallet Connect</h3>
                                    </div>
                                </li> :
                                <li className="liquid_raised mx-2 mx-sm-2 w-100 text-center diable_network_div">
                                    <div className="img">
                                        <img src={wall2} alt="img" />
                                    </div>
                                    <div className="wal-option">
                                        <h3 className="side-head-li mb-0 text-center">Wallet Connect</h3>
                                    </div>
                                </li>
                            }

                        </ul>

                    </div>
                </Modal.Body>
            </Modal>



        )
    }

}


export default Walletmodal