import React, { Component } from 'react';

import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Footer from "../Footer";


import toast from "react-hot-toast";

import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"

import { InputGroup, FormControl } from 'react-bootstrap';
import { profileSettings } from '../../hooks/usebackend';
import { validateProfileEmail, validateProfilePass } from "../../hooks/kycvalidation";


class Settings extends Component {

  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");



  }

  componentDidMount() {
    this.hideLoader();
  }


  constructor(props) {
    super(props);
    this.state = {
      passwordeye: false,
      newpasswordeye: false,
      newEmail: '',
      oldPassword: '',
      newPassword: '',
      errors: ''
    }

  }


  handleEmail = async () => {
    let payload = {
      newEmail: this.state.newEmail,
    }

    let validate = await validateProfileEmail(payload);
    if (validate.isValid) {
      let data = {
        newEmail: this.state.newEmail,
        password: this.state.newPassword
      }
      var settings = await profileSettings(data);
      console.log("settings", settings)
      if (settings?.data?.data?.status == "false" || !settings?.data?.data?.status) {
        this.setState({ "errors": settings?.data?.data?.errors })
      } else {
        toast.success(settings?.data?.data?.message)
        console.log("settings_settings", settings);
        this.setState({ errors: '' })
        this.setState({ newEmail: '' })

      }
    }
    else {

      this.setState({ "errors": validate?.errors })
    }
  }

  handlePassword = async () => {
    let payload = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword
    }

    let validate = await validateProfilePass(payload);
    if (validate.isValid) {
      let data = {
        newEmail: this.state.newEmail,
        password: this.state.newPassword,
        oldPassword: this.state.oldPassword,

      }
      var settings = await profileSettings(data);
      console.log("settings", settings)
      if (settings?.data?.data?.status == "false" || !settings?.data?.data?.status) {
        this.setState({ "errors": settings?.data?.data?.errors })
      } else {
        toast.success(settings?.data?.data?.message)
        console.log("settings_settings", settings);
        this.setState({ oldPassword: '' })
        this.setState({ newPassword: '' })
        this.setState({ errors: '' })
      }

    }
    else {
      this.setState({ "errors": validate?.errors })
    }
  }

  render() {


    const location = this.props.location.pathname.split('/')[1];

    console.log("newmail", this.state.newEmail)
    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">

          <div className="whole_bg">
            <Adminheader />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Adminsidebar />
                <div className='right_side_sec pb-5'>

                  <div className="right_side_spacing">
                    <div className="container">

                      <div className='row my-5'>
                        <div className='col-12 mx-auto px-0'>
                          <div className="tab_img">
                            <div className='bg_layer mb-5'>
                              <div className='card-body pos_z_body_car'>
                                <p className='overlay_card_head'>Profile Settings</p>

                                <p className='input_desc_sm mt-4'>Email Address</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl id="email" placeholder="Enter your email address"
                                      aria-describedby="basic-addon2"
                                      value={this.state.newEmail}
                                      onChange={(e) => {
                                        this.setState({ "newEmail": e?.target?.value })
                                      }}
                                    />

                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.newEmail}
                                  </span>
                                </div>
                                <div className='text-center'>
                                  <button className="get-started-btn orbitron_font btn_min_wid my-3" onClick={() => { this.handleEmail() }}>

                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className='bg_layer'>
                              <div className='card-body pos_z_body_car'>
                                <p className='overlay_card_head'>Password Settings</p>

                                <p className='input_desc_sm mt-4'>Old Password</p>
                                <div className="inputs input-groups date_inoput_grps btn_positoned">
                                  <InputGroup className="datepicker_input">

                                    <FormControl id="password" placeholder="Enter your password" type={!this.state.passwordeye ? "password" : "text"}
                                      className="center_icon"
                                      autocomplete="off"
                                      aria-describedby="basic-addon2"
                                      value={this.state.oldPassword}
                                      onChange={(e) => {
                                        this.setState({ "oldPassword": e?.target?.value })
                                      }}
                                    />
                                    {!this.state.passwordeye ?
                                      <InputGroup.Append className='cur_pointer mob_btn_grp_new'><button className='fa fa-eye-slash trans_cal_btn' onClick={() => this.setState({ passwordeye: !this.state.passwordeye })}></button></InputGroup.Append>
                                      : <InputGroup.Append className='cur_pointer mob_btn_grp_new'><button className='fa fa-eye trans_cal_btn' onClick={() => this.setState({ passwordeye: !this.state.passwordeye })}></button></InputGroup.Append>
                                    }
                                  </InputGroup>

                                </div>
                                <p className="text-danger f-12 d-block text-left">
                                  {this?.state?.errors?.oldPassword}
                                </p>

                                <p className='input_desc_sm mt-3'>New Password</p>
                                <div className="inputs input-groups date_inoput_grps btn_positoned">
                                  <InputGroup className="datepicker_input">
                                    <FormControl id="password" placeholder="Enter your password" type={!this.state.newpasswordeye ? "password" : "text"}
                                      className="center_icon"
                                      autocomplete="off"
                                      aria-describedby="basic-addon2"
                                      value={this.state.newPassword}
                                      onChange={(e) => {
                                        this.setState({ "newPassword": e?.target?.value })
                                      }}
                                    />
                                    {!this.state.newpasswordeye ?
                                      <InputGroup.Append className='cur_pointer mob_btn_grp_new'><button className='trans_cal_btn fa fa-eye-slash' onClick={() => this.setState({ newpasswordeye: !this.state.newpasswordeye })}></button></InputGroup.Append>
                                      : <InputGroup.Append className='cur_pointer mob_btn_grp_new'><button className='trans_cal_btn fa fa-eye' onClick={() => this.setState({ newpasswordeye: !this.state.newpasswordeye })}></button></InputGroup.Append>
                                    }
                                  </InputGroup>
                                </div>
                                <p className="text-danger f-12 d-block text-left">
                                  {this?.state?.errors?.newPassword}
                                </p>


                                <div className='text-center'>
                                  <button className="get-started-btn orbitron_font btn_min_wid my-3" onClick={() => { this.handlePassword() }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="bg_layer mb-5">
                    <div className="right_side_spacing py-5">

                      <div className="row">

                        <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                          <h3 className="h3_res">Premium Launch Order</h3>
                          <h5 className="h5_res">All in one package </h5>
                          <div className='mt-4 pb-4 div_btn_ful_width'>
                            <button className='get-started-btn orbitron_font text-white'>
                              Contact Agent
                            </button>
                            <button className='get-started-btn-border orbitron_font ml-3'>
                              Learn Premium
                            </button>
                          </div>

                        </div>
                        <div className="col-12 col-md-5 col-xl-6">
                          <p className="title_span mt-4">Ultimate guideline for launching with 1 v 1 support during the process. Instructing project from technical to marketing/branding aspect for the best outcome.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <Footer />
        </div>
      </div>
    )
  }
}

export default Settings