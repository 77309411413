import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../css/footer.css";

import medium from "../images/medium_footer.svg";
import telegram from "../images/telegram_footer.svg";
import twitter from "../images/twitter_footer.svg";
import footerCenter from "../images/launchpadHead.png";
import footerBg from "../images/footer_bg.png";
import audit from "../images/audited.png";
// import logo from "../assests/images/headerlogo.png";
import logo from "../images/logo.svg";
import zksync from "../images/zksync.png";
import { getAccount, getChainId, setChainId } from "../hooks/useAccount";
import { getsettinghook } from "../hooks/usebackend";
import { getProxyOwner } from "../hooks/useContract";

class Footer extends Component {
  constructor(props) {
    super(props);
    console.log("props", this.props);
    this.state = {
      location: false,
      walletModal: false,
      accountInfo: "",
      walletConnect: "",
      proxyOwner: "",
      currentChain: 0,
      settingsModal: false,
      sidebarShrink: false,
      setActive: 0,
      setting: [],
      telegram: "",
      Skype: "",
      twitter: "",
      discord: "",
      Docs: "",
      dex: "",
      support: "",
      medium: "",
    };
  }

  getOwner = async () => {
    const owner = await getProxyOwner();
    this.setState({ proxyOwner: owner });
  };

  componentDidMount() {
    document.body.classList.remove("sidebar_shr");
    console.log("history", this.props);
    this.setState({ accountInfo: getAccount() });
    this.setState({ currentChain: getChainId() });
    this.getOwner();
    this.getsetting();
  }

  async getsetting() {
    let data = await getsettinghook();
    console.log("data setting", data, data?.data?.data);
    console.log(
      "Array",
      typeof data?.data?.data == "Array",
      typeof data?.data?.data
    );
    this.setState({ setting: data?.data?.data });
    typeof data?.data?.data == "object" &&
      data?.data?.data?.length > 0 &&
      data?.data?.data?.map((val, i) => {
        console.log("val", val);
        if (val.settingname == "Docs" || val.settingname == "docs") {
          this.setState({ Docs: val.settingvalue });
        }
        if (val.settingname == "Twitter" || val.settingname == "twitter") {
          this.setState({ twitter: val.settingvalue });
        }
        if (val.settingname == "Telegram" || val.settingname == "telegram") {
          this.setState({ telegram: val.settingvalue });
        }
        if (val.settingname == "Support" || val.settingname == "support") {
          this.setState({ support: val.settingvalue });
        }
        if (val.settingname == "Dex" || val.settingname == "dex") {
          this.setState({ dex: val.settingvalue });
        }

        if (val.settingname == "Medium" || val.settingname == "medium") {
          this.setState({ medium: val.settingvalue });
        }
      });
  }

  render() {
    return (
      <footer className="newfooter position-relative">
        <img
          src={footerBg}
          alt="footer"
          className="footerBg position-absolute"
        />
        <div className="container">
          <div className="row flex-column flex-lg-row">
            <div className="col-lg-6">
              <div className="left">
                <div className="copyright">
                  <div className="text">© Copyright Zklaunch</div>
                </div>

                <Link to="/">
                  <div className="logo  w-fit">
                    <img src={zksync} alt="logo" className="img-fluid" />
                  </div>
                </Link>

                <h1 className="textOrbiXxl">ZK World</h1>

                <div className="social d-flex align-items-center">
                  <button>
                    <a
                      href={this.state.twitter}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitter} alt="twitter" className="img-fluid" />
                    </a>
                  </button>{" "}
                  <button>
                    <a
                      href={this.state.medium}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={medium} alt="medium" className="img-fluid" />
                    </a>
                  </button>{" "}
                  <button>
                    <a
                      href={this.state.telegram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={telegram}
                        alt="telegram"
                        className="img-fluid"
                      />
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center position-relative">
              <div className="right d-flex footer_links_wrap">
                <div className="footer_links">
                  <div className="headline">Launching Solution</div>
                  <ul className="footer_links_list">
                    <li>Launchpad List</li>
                    <li>Private Sale List</li>
                    <li>Premium Launch</li>
                    <li>Token Lock</li>
                  </ul>
                </div>

                <div className="footer_links">
                  <div className="headline">Documents</div>
                  <ul className="footer_links_list">
                    <li>Whitepaper</li>
                    <li>Terms of Use</li>
                    <li>Privacy Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
