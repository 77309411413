import axios from "axios";
import { API_URL } from "../config/env";
import { addReferrer ,GetReferrer} from "../routes/adminroutesfront";


export const UseAddReferrer = async(param) => {

    console.log("log>>>>>>>>>>>",param);

    try{
      console.log("try");
      const data = await axios.post(API_URL + addReferrer, param);

      console.log("datadatadata",data);

      if(data){
        return data;
      }
    }
   catch (e) {
        console.log("error", e);
    }
    
};

export const UseUserReferrerData = async(useraddress) => {
  console.log("call");
   
  const tokeninfo =  await   axios.get(`${API_URL + GetReferrer}/${useraddress}`)
        return tokeninfo.data;
}