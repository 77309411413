import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal, FormControl, InputGroup } from 'react-bootstrap';
import { addFeevalidation } from '../../hooks/kycvalidation';
import { addFee } from '../../hooks/usebackend';
import toast, { Toaster } from 'react-hot-toast';

import proof from "../../images/proof.webp"

class AddFeemodal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addFeeModal: true,
            feeName: "",
            BNBvalue:0,
            tokenvalue:0,
            errors: {}
        };
    }

    handleadd = async () => {
        console.log("handleadd");
        let payload = {
            feeName: this.state.feeName,
            BNBvalue:this.state.BNBvalue,
            tokenvalue: this.state.tokenvalue
        };
        console.log("payload", payload);
        let validate = await addFeevalidation(payload);
        if (validate.isValid) {
            let feeresult = await addFee(payload);
            console.log("feeresult", feeresult);
            toast.success(feeresult?.data?.data?.message)
            this.props.onDismiss();
            this.props.getFee();
        }
        else {
            this.setState({ "errors": validate.errors })
        }

    }

    render() {


        const { addFeeModal } = this.state


        return (



            <Modal className="wallet-modal" show={addFeeModal} centered size="md">
                <Modal.Header className='pt-3 header_odal_head'>
                    <h3 className="sec-head ">Add Fee</h3>
                    <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet modal_body pt-0 pb-0 px-0">

                    <div className="wallet-lists px-2">

                        <div className='mod_pad_space pt-0'>
                            <div className='row'>
                                <div className='col-12 col-md-12 px-1 mb-3'>



                                </div>
                                <div className='col-12 col-md-12 px-1 mb-3'>
                                    <p className='input_desc_sm'>Fee Name</p>
                                    <div className="inputs input-groups">
                                        <InputGroup className="">
                                            <FormControl id="ipAddress" placeholder="Enter Fee Name"
                                                aria-describedby="basic-addon2"
                                                value={this?.state?.feeName}
                                                onChange={(e) => {
                                                    this.setState({ "feeName": e?.target?.value })
                                                }}
                                            />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.feeName}</span>
                                    </div>
                                </div>

                                <div className='col-12 col-md-12 px-1 mb-3'>
                                    <p className='input_desc_sm'>BNB value</p>
                                    <div className="inputs input-groups">
                                        <InputGroup className="">
                                            <FormControl id="bnb" placeholder="Enter BNB value"
                                                aria-describedby="basic-addon2"
                                                value={this?.state?.BNBvalue}
                                                onChange={(e) => {
                                                    this.setState({ "BNBvalue": e?.target?.value })
                                                }}
                                            />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.BNBvalue}</span>
                                    </div>
                                </div>

                                <div className='col-12 col-md-12 px-1 mb-3'>
                                    <p className='input_desc_sm'>Token value</p>
                                    <div className="inputs input-groups">
                                        <InputGroup className="">
                                            <FormControl id="token" placeholder="Enter token value"
                                                aria-describedby="basic-addon2"
                                                value={this?.state?.tokenvalue}
                                                onChange={(e) => {
                                                    this.setState({ "tokenvalue": e?.target?.value })
                                                }}
                                            />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.tokenvalue}</span>
                                    </div>
                                </div>

                                <div className='col-12 col-md-12 px-1 text-center pb-4'>
                                    <button className="btn_black mt-2" onClick={() => { this.handleadd() }}>
                                        Add
                                    </button>

                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>



        )
    }

}


export default AddFeemodal