import React, { Component } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

import Walletmodal from "../Walletmodal";
import Trendingslider from '../trendingslider';

import CreatetabFair from "./CreatetabFair";

import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"


class CreateFairlaunch extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");



  }


  showLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("loader_div_connect").classList.remove("d-none");
    document.getElementById("loader_div_connect").classList.add("d-block");


  }

  hideLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("loader_div_connect").classList.remove("d-block");
    document.getElementById("loader_div_connect").classList.add("d-none");
  }

  async fetchdata1() {

    if (!localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")) {
      this.showLoaderConnect()
    }
    else {
      this.hideLoaderConnect();
    }
  }


  onDismiss() {
    this.setState({ walletModal: false });
    if (sessionStorage.getItem("accountInfo")) {
      this.hideLoaderConnect();
    }
  }

  componentDidMount() {
    this.hideLoader();
    this.fetchdata1()

  }

  constructor(props) {
    super(props);
    this.state = {
      walletModal: false,
      accountInfo: '',

      loaders: false
    };
  }

  render() {

    const location = this.props.location.pathname.split('/')[1];

    const { walletModal } = this.state



    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
        <div className="whole_bg">
          <Header />

          <div className="whole_sec pb-5">
            <div className='flex_side_right'>
              <Sidebar />
              <div className='right_side_sec pb-5'>

                <div id="loader_div_connect">

                  <div className='load_connect_text'>
                    <button className="get-started-btn btn_connect_loader" onClick={() => this.setState({ walletModal: true })}>
                      Connect Wallet to Continue
                    </button>
                  </div>

                </div>

                <Trendingslider/>
                <div className="right_side_spacing">
                  <CreatetabFair />



                
                </div>
                <div className="bg_layer mb-5 mt-4">
                <div className="right_side_spacing py-5">
                     
                     <div className="row">
                     
                      <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                        <h3 className="h3_res">Premium Launch Order</h3>
                        <h5 className="h5_res">All in one package </h5>
                        <div className='mt-4 pb-4 div_btn_ful_width'>
                          <button className='get-started-btn orbitron_font text-white'>
                          Contact Agent
                          </button>
                          <button className='get-started-btn-border orbitron_font ml-3'>
                          Learn Premium
                          </button>
                        </div>
        
                      </div>
                      <div className="col-12 col-md-5 col-xl-6">
                     <p className="title_span mt-4">Ultimate guideline for launching with 1 v 1 support during the process. Instructing project from technical to marketing/branding aspect for the best outcome.</p>
                      
                      </div>
                     </div>
                    </div>
                    </div>
              </div>
            </div>
          </div>
          </div>

          <Footer />
        </div>
        {walletModal && <Walletmodal connect={"string"} onDismiss={() => this.onDismiss()} />}
      </div>
    )
  }
}

export default CreateFairlaunch