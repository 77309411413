import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { API_URL } from '../../config/env';
import { approvekychook, rejectkychook } from '../../hooks/usebackend';
import folderimage from "../../images/folder_zip.png";
import { FormControl, InputGroup } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


class Kycmodal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kycModal: true,
      kycdetail: {}

    };
  }

  componentDidMount() {
    console.log("this?.props?.detail", this?.props, this?.props?.detail?.proof[0], this?.props?.detail?.proof[0]?.front);
    if (this?.props?.detail?.proof[0]?.identitytype === "Individual") {


      this.setState({
        emailid: this?.props?.detail?.proof[0]?.emailid,
        city: this?.props?.detail?.proof[0]?.city,
        region: this?.props?.detail?.proof[0]?.region,
        country: this?.props?.detail?.proof[0]?.country,
        zipcode: this?.props?.detail?.proof[0]?.zipcode,
        contactnumber: this?.props?.detail?.proof[0]?.contactnumber,
        walletaddress: this?.props?.detail?.proof[0]?.walletaddress,
        idtype: this?.props?.detail?.proof[0]?.idtype,
        idnumber: this?.props?.detail?.proof[0]?.idnumber,
        front: this?.props?.detail?.proof[0]?.front,
        identitytype: this?.props?.detail?.proof[0]?.identitytype
      })
    }
    else if (this?.props?.detail?.proof[0]?.identitytype === "Company") {
      this.setState({
        businessOwnerName: this?.props?.detail?.proof[0]?.businessOwnerName,
        surname: this?.props?.detail?.proof[0]?.surname,
        businessname: this?.props?.detail?.proof[0]?.businessname,
        companynumber: this?.props?.detail?.proof[0]?.companynumber,
        registrationdate: moment(Number(this?.props?.detail?.proof[0]?.registrationdate)).format('DD/MM/YYYY'),
        description: this?.props?.detail?.proof[0]?.description,
        websiteurl: this?.props?.detail?.proof[0]?.websiteurl,
        businessemail: this?.props?.detail?.proof[0]?.businessemail,
        businessaddress: this?.props?.detail?.proof[0]?.businessaddress,
        businesscontactnumber: this?.props?.detail?.proof[0]?.businesscontactnumber,
        businesscity: this?.props?.detail?.proof[0]?.businesscity,
        businessregion: this?.props?.detail?.proof[0]?.businessregion,
        businesscountry: this?.props?.detail?.proof[0]?.businesscountry,
        businesszipcode: this?.props?.detail?.proof[0]?.businesszipcode,
        walletaddress: this?.props?.detail?.proof[0]?.walletaddress,
        idtype: this?.props?.detail?.proof[0]?.idtype,
        idnumber: this?.props?.detail?.proof[0]?.idnumber,
        front: this?.props?.detail?.proof[0]?.front,
        identitytype: this?.props?.detail?.proof[0]?.identitytype
      })
    }
    else {
      this.setState({
        ceoname: this?.props?.detail?.proof[0]?.ceoname,
        organizationname: this?.props?.detail?.proof[0]?.organizationname,
        nonprofitregistrationdate: moment(Number(this?.props?.detail?.proof[0]?.nonprofitregistrationdate)).format('DD/MM/YYYY'),
        nonprofitorganizationaddress: this?.props?.detail?.proof[0]?.nonprofitorganizationaddress,
        nonprofitcity: this?.props?.detail?.proof[0]?.nonprofitcity,
        nonprofitregion: this?.props?.detail?.proof[0]?.nonprofitregion,
        nonprofitcountry: this?.props?.detail?.proof[0]?.nonprofitcountry,
        nonprofitzipcode: this?.props?.detail?.proof[0]?.nonprofitzipcode,
        nonprofitemailid: this?.props?.detail?.proof[0]?.nonprofitemailid,
        nonprofitcontactnumber: this?.props?.detail?.proof[0]?.nonprofitcontactnumber,
        nonprofitwebsiteurl: this?.props?.detail?.proof[0]?.nonprofitwebsiteurl,
        walletaddress: this?.props?.detail?.proof[0]?.walletaddress,
        idtype: this?.props?.detail?.proof[0]?.idtype,
        idnumber: this?.props?.detail?.proof[0]?.idnumber,
        front: this?.props?.detail?.proof[0]?.front,
        identitytype: this?.props?.detail?.proof[0]?.identitytype
      })
    }
  }

  fileDownloader = (URL) => {
    console.log("URL", URL)

    let filename = "MultiFilesDownload";
    const zip = new JSZip()
    const folder = zip.folder('project')

    const blobPromise = fetch(URL)
      .then(function (response) {
        console.log({ response })
        if (response.status === 200 || response.status === 0) {
          return Promise.resolve(response.blob());
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      })
    const name = URL.substring(URL.lastIndexOf('/'))
    folder.file(name, blobPromise)

    zip.generateAsync({ type: "blob" })
      .then(blob => saveAs(blob, filename))
      .catch(e => console.log(e));
  }




  handleapprove = async () => {
    let payload = {
      walletaddress: this?.props?.detail?.walletaddress,
      id: this?.props?.detail?.proof[0]?.id
    }
    let approve = await approvekychook(payload);
    console.log("approve", approve);
    // if (approve?.data?.type == "success") {
    //   Toast.error("Error Try Again !")
    // }
    // else {
    //   Toast.error("Error Try Again !")
    // }
    this.props.onDismiss();
  }

  handlereject = async () => {
    let payload = {
      walletaddress: this?.props?.detail?.walletaddress,
      id: this?.props?.detail?.proof[0]?.id
    }
    let reject = await rejectkychook(payload);
    this.props.onDismiss();
  }

  render() {


    const { kycModal } = this.state


    return (



      <Modal className="wallet-modal" show={kycModal} centered size="md">
        <Modal.Header className='pt-3 header_odal_head'>
          <h3 className="sec-head ">KYC</h3>
          <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

        </Modal.Header>
        <Modal.Body className="select-wallet modal_body pt-0 pb-0 px-0">

          <div className="wallet-lists px-2">

            <div className='mod_pad_space'>

              <div className='row'>
                <div className='col-12 col-md-12 mb-0'>
                  <p className='input_desc_sm bold_text_proof'>{this?.props?.detail?.proof[0]?.idtype}</p>

                </div>
                <>


                  <div className='col-12 col-md-12 mb-3'>

                    <p className='input_desc_sm'>Identity Type</p>
                    <div className="inputs input-groups">
                      <InputGroup className="">
                        <FormControl id="identitytype" placeholder=""
                          aria-describedby="basic-addonceoname" type="text"
                          value={this.state.identitytype}
                          readOnly
                        />

                      </InputGroup>


                    </div>
                  </div>

                  {
                    this.state.identitytype == "Individual" ?

                      <>
                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Email ID</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="emailid" placeholder=""
                                aria-describedby="basic-addonemail" type="email"
                                value={this?.state?.emailid}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>City</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="city" placeholder=""
                                aria-describedby="basic-addoncity" type="text"
                                value={this?.state?.city}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Region</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="region" placeholder=""
                                aria-describedby="basic-addonregion" type="text"
                                value={this?.state?.region}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Country</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="country" placeholder=""
                                aria-describedby="basic-addoncountry" type="text"
                                value={this?.state?.country}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Zip Code</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="zipcode" placeholder=""
                                aria-describedby="basic-addonzipcode" type="text"
                                value={this?.state?.zipcode}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Contact Number</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="contactnumber" placeholder=""
                                aria-describedby="basic-addoncontactnumber" type="text"
                                value={this?.state?.contactnumber}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                      </>
                      :

                      this.state.identitytype == "Company" ?
                        <>
                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Owner Name*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessOwnerName" placeholder=""
                                  aria-describedby="basic-addonname" type="text"
                                  value={this?.state?.businessOwnerName}
                                  readOnly
                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Owner Surname*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="surname" placeholder=""
                                  aria-describedby="basic-addonsurname" type="text"
                                  value={this?.state?.surname}
                                  readOnly
                                />

                              </InputGroup>

                            </div>
                          </div>


                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Name*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessname" placeholder=""
                                  aria-describedby="basic-addonbusinessname" type="text"
                                  value={this?.state?.businessname}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>



                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Company Number*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="companynumber" placeholder=""
                                  aria-describedby="basic-addoncompanynumber" type="text"
                                  value={this?.state?.companynumber}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>
                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Registration Date*</p>
                            <div className="inputs input-groups">
                              <div className="inputs input-groups date_inoput_grps inut_grp_date_inpt_pad">
                                <InputGroup className="datepicker_input">
                                  <FormControl id="registrationdate" placeholder=""
                                    aria-describedby="basic-addonorganizationname" type="text"
                                    value={this?.state?.registrationdate}
                                    readOnly

                                  />
                                </InputGroup>

                              </div>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Type</p>
                            <div className="inputs input-groups text_are_grp text_area_norla_colr">
                              <InputGroup className="">
                                <textarea
                                  id="description" rows="3"
                                  aria-describedby="basic-addon2"
                                  value={this?.state?.description}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Website URL</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="websiteurl" placeholder=""
                                  aria-describedby="basic-addonwebsiteurl" type="text"
                                  value={this?.state?.websiteurl}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Email*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessemail" placeholder=""
                                  aria-describedby="basic-addonbusinessemail" type="email"
                                  value={this?.state?.businessemail}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Address*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessaddress" placeholder=""
                                  aria-describedby="basic-addonbusinessaddress" type="text"
                                  value={this?.state?.businessaddress}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Contact Number*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businesscontactnumber" placeholder=""
                                  aria-describedby="basic-addonbusinesscontactnumber" type="text"
                                  value={this?.state?.businesscontactnumber}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>City</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businesscity" placeholder=""
                                  aria-describedby="basic-addonbusinesscity" type="text"
                                  value={this?.state?.businesscity}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Region</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessregion" placeholder=""
                                  aria-describedby="basic-addonbusinessregion" type="text"

                                  value={this?.state?.businessregion}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>


                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Country</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businesscountry" placeholder=""
                                  aria-describedby="basic-addonbusinesscountry" type="text"
                                  value={this?.state?.businesscountry}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Zip Code</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businesszipcode" placeholder=""
                                  aria-describedby="basic-addonbusinesszipcode" type="text"
                                  value={this?.state?.businesszipcode}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                        </>
                        :
                        this.state.identitytype == "Nonprofit Organization" ?
                          <>
                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Name of CEO*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="ceoname" placeholder=""
                                    aria-describedby="basic-addonceoname" type="text"
                                    value={this?.state?.ceoname}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Organization Name*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="organizationname" placeholder=""
                                    aria-describedby="basic-addonorganizationname" type="text"
                                    value={this?.state?.organizationname}
                                    readOnly


                                  />

                                </InputGroup>

                              </div>
                            </div>


                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Registration Date*</p>
                              <div className="inputs input-groups">
                                <div className="inputs input-groups date_inoput_grps inut_grp_date_inpt_pad">
                                  <InputGroup className="datepicker_input">
                                    <FormControl id="nonprofitregistrationdate" placeholder=""
                                      aria-describedby="basic-addonorganizationname" type="text"
                                      value={this?.state?.nonprofitregistrationdate}
                                      readOnly

                                    />

                                  </InputGroup>

                                </div>

                              </div>
                            </div>



                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Organization Address*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitorganizationaddress" placeholder=""
                                    aria-describedby="basic-addonnonprofitorganizationaddress" type="text"
                                    value={this?.state?.nonprofitorganizationaddress}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>




                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>City*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitcity" placeholder=""
                                    aria-describedby="basic-addonnonprofitcity" type="text"
                                    value={this?.state?.nonprofitcity}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Region*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitregion" placeholder=""
                                    aria-describedby="basic-addonnonprofitregion" type="text"
                                    value={this?.state?.nonprofitregion}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>


                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Country*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitcountry" placeholder=""
                                    aria-describedby="basic-addonnonprofitcountry" type="text"
                                    value={this?.state?.nonprofitcountry}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Zip Code*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitzipcode" placeholder=""
                                    aria-describedby="basic-addonnonprofitzipcode" type="text"
                                    value={this?.state?.nonprofitzipcode}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Email ID*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitemailid" placeholder=""
                                    aria-describedby="basic-addonnonprofitemailid" type="text"
                                    value={this?.state?.nonprofitemailid}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Contact Number*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitcontactnumber" placeholder=""
                                    aria-describedby="basic-addonnonprofitcontactnumber" type="text"
                                    value={this?.state?.nonprofitcontactnumber}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Website URL</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitwebsiteurl" placeholder=""
                                    aria-describedby="basic-addonnonprofitwebsiteurl" type="text"
                                    value={this?.state?.nonprofitwebsiteurl}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>




                          </>
                          :
                          <></>

                  }

                  <div className='col-12 col-md-12 mb-3'>

                    <p className='input_desc_sm'>Document Type</p>
                    <div className="inputs input-groups">
                      <InputGroup className="">
                        <FormControl id="idtype" placeholder=""
                          aria-describedby="basic-addonceoname" type="text"
                          value={this.state.idtype}
                          readOnly

                        />
                      </InputGroup>


                    </div>
                  </div>

                  <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>ID Number</p>
                    <div className="inputs input-groups">
                      <InputGroup className="">
                        <FormControl id="idnumber" placeholder=""
                          aria-describedby="basic-addon2"
                          value={this?.state?.idnumber}
                          readOnly

                        />

                      </InputGroup>

                    </div>
                  </div>
                </>

                <div className='col-12 col-sm-6 mb-3 mt-1'>
                  <p className='input_desc_sm'>Kyc Document</p>
                  <div className="icon_align_border">

                    <img src={folderimage} alt="W3Schools" width="104" height="142"></img>

                  </div>
                  <div className='text-left'>
                    <button className="btn_black mt-3"
                      onClick={() => { this.fileDownloader(API_URL + "/" + this?.props?.detail?.proof[0]?.path + this?.props?.detail?.proof[0]?.front) }}
                    >Download</button>
                  </div>
                </div>

              </div>

              <div className='text-center mt-4 mb-4'>

                <button className='btn_black mr-2'
                  onClick={this.handleapprove}
                  disabled={this?.props?.detail?.proof[0].status != "Pending"}
                >
                  Approve</button>
                <button className='btn_black_outline'
                  onClick={this.handlereject}
                  disabled={this?.props?.detail?.proof[0].status != "Pending"}
                >
                  Reject</button>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>



    )
  }

}


export default Kycmodal