import React, { Component } from 'react';
import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Footer from "../Footer";
import Cookies from 'universal-cookie';
import { padToDigits } from "../../helper/timeFormat"
import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {Tab, Row, Col, ProgressBar} from 'react-bootstrap';
import { getlaunchpaddatalimit, getSaleCardsLimit, getTotalSalesInfo, searchCardsadmin } from '../../hooks/useProjects';
import { DeletePresale } from '../../hooks/useContract';
import { getAccount } from '../../hooks/useAccount';
import { admintokenfilterlaunchpad} from '../../hooks/filter';
import { getsettinghook } from '../../hooks/usebackend';
import { getallsalehook, getuserdatahook } from '../../hooks/usebackend';
import isEmpty from 'is-empty';

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    AUDIT
  </Tooltip>
);


const renderTooltip1 = (props) => (
  <Tooltip id="button-tooltip1" {...props}>
    DOXX
  </Tooltip>
);

const renderTooltip2 = (props) => (
  <Tooltip id="button-tooltip2" {...props}>
    SAFU
  </Tooltip>
);

const renderTooltip3 = (props) => (
  <Tooltip id="button-tooltip3" {...props}>
    KYC
  </Tooltip>
);
const cookies = new Cookies();

class Adminlaunchpad extends Component {

  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  closeAllMenusStatus() {
    this.setState({ closeKycmenu: true });
    this.setState({ closeHcmenu: true });
    this.setState({ closeCoinmenu: true });
  }

  closeAllMenusKyc() {
    this.setState({ closeStatusmenu: true });
    this.setState({ closeHcmenu: true });
    this.setState({ closeCoinmenu: true });
  }

  closeAllMenusCoin() {
    this.setState({ closeStatusmenu: true });
    this.setState({ closeHcmenu: true });
    this.setState({ closeKycmenu: true });
  }


  closeAllMenusHc() {
    this.setState({ closeStatusmenu: true });
    this.setState({ closeCoinmenu: true });
    this.setState({ closeKycmenu: true });
  }

  componentDidMount() {
    this.restrict()
    this.hideLoader();
    this.gettotalsalesinfo();
    this.getsetting()
    this.getuserdata();
  }

  restrict = async () => {
    var email = cookies.get('cryp-launch-admin-email');
    console.log("email", email);
    if (!email) {
      this.props.history.push("/login")
    }
  }

  gettotalsalesinfo = async () => {
    this.showLoader();
    const totalproxydata = await getTotalSalesInfo();
    console.log("Proxydata", totalproxydata);
    console.log("total proxy data", totalproxydata);
    this.setState({ proxy: totalproxydata })
    this.setState({ original: totalproxydata })
    const total = await getlaunchpaddatalimit(totalproxydata, 0, this.state.loadInterval);
    console.log(total, 'gettotalsalesinfo')
    console.log("limittotal", total);
    this.setState({ totalSales: total });
    this.setState({ interval: this.state.loadinterval });
    this.hideLoader();
  }
  viewmore = async () => {
    console.log("Lad moreee", this.state.totalSales?.length < this.state.proxy?.length)
    this.setState({ isLoading: true });
    console.log("getlaunchpad datalimit", this.state.interval, this.state.loadInterval);
    const newly = await getlaunchpaddatalimit(this.state.proxy, this.state.interval, this.state.interval + this.state.loadInterval);
    console.log("newly ", newly);
    const total = this.state.totalSales.concat(newly);
    this.setState({ totalSales: total, interval: this.state.interval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }
  constructor(props) {
    super(props);
    this.state = {
      original: [],
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: '',
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      closeHcmenu: false,
      closeCoinmenu: false,
      closeKycmenu: false,
      closeStatusmenu: false,
      isuser: false,
      button: true,
      loadinterval: 3,

      mycontribution: [],
      accountInfo: getAccount(),
      filterdata: "All Status",
      sortdata: "No Filter",
      coin: [],
      status: [],
      type: [],
      wishlist: [],
      trending: [],
      singletrending: {},
      auditkyc: [],
      singleaudit: {}

    };
  }
  async handleChange(e) {
    if ((e.target.value)?.length > 0) {
      if (e.key === 'Enter') {
        console.log("Search : ", e.target.value)
        this.showLoader();
        const searchS = await searchCardsadmin(this.state.proxy, e.target.value);
        this.setState({ searchProxy: searchS });
        const data = await getlaunchpaddatalimit(searchS, 0, this.state.searchInterval);
        await this.sleep(1050)
        console.log("Search sale : ", searchS)
        this.setState({ searchSale: data, isSearch: true })
        this.hideLoader();
      }
    } else {
      this.setState({ isSearch: false })
    }

  }

  async loadSearchMore() {
    console.log("Lad more", this.state.totalSales?.length < this.state.proxy?.length)
    this.setState({ isLoading: true });
    const newly = await getlaunchpaddatalimit(this.state.searchProxy, this.state.searchInterval, this.state.searchInterval + this.state.loadInterval);
    const total = this.state.searchSale.concat(newly);
    await this.sleep(1000)
    this.setState({ searchSale: total, searchInterval: this.state.searchInterval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }

  async handlecoin(val) {
    var coins = this.state.coin
    if (coins?.length == 0) {
      coins.push(val);
    }
    else {
      if (coins.includes(val)) {
        const index = coins.indexOf(val);
        if (index > -1) {
          coins.splice(index, 1);
        }
      }
      else {
        coins.push(val)
      }
    }
    console.log("coin", coins);
    this.setState({ coin: coins })
  }

  async handlestatus(val) {
    var statuss = this.state.status
    if (statuss?.length == 0) {
      statuss.push(val);
    }
    else {
      if (statuss.includes(val)) {
        const index = statuss.indexOf(val);
        if (index > -1) {
          statuss.splice(index, 1);
        }
      }
      else {
        statuss.push(val)
      }
    }
    console.log("status", statuss);
    this.setState({ status: statuss })
  }

  async handletype(val) {
    var statuss = this.state.type
    if (statuss?.length == 0) {
      statuss.push(val);
    }
    else {
      if (statuss.includes(val)) {
        const index = statuss.indexOf(val);
        if (index > -1) {
          statuss.splice(index, 1);
        }
      }
      else {
        statuss.push(val)
      }
    }
    console.log("status", statuss);
    this.setState({ type: statuss })
  }

  async handleadvancefilter() {
    this.showLoader();
    if (this.state.coin?.length == 0 && this.state.status?.length == 0 && this.state.type?.length == 0) {
      this.gettotalsalesinfo();
    }
    let filtered = await admintokenfilterlaunchpad(this.state.original, this.state.coin, this.state.status, this.state.type);
    console.log("filtered", filtered);
    if (filtered?.length > 0) {
      this.setState({ proxy: filtered })
      const salelist = await getSaleCardsLimit(filtered, 0, this.state.interval);

      console.log("salelist", salelist);
      this.setState({ "totalSales": salelist });
      this.hideLoader()
    }
    else {
      this.setState({ totalSales: filtered });
      this.setState({ "salesinfo": filtered });
      this.hideLoader();
    }
  }


  async getsetting() {
    let data = await getsettinghook();
    console.log("data setting", data, data?.data?.data);
    console.log("Array", typeof data?.data?.data == 'Array', typeof data?.data?.data);
    this.setState({ setting: data?.data?.data })
    typeof data?.data?.data == 'object' && data?.data?.data?.length > 0 && data?.data?.data?.map((val, i) => {
      console.log("val", val)
      if (val.settingname == "Docs" || val.settingname == "docs") {
        this.setState({ Docs: val.settingvalue });
      }
      if (val.settingname == "Twitter" || val.settingname == "twitter") {
        this.setState({ twitter: val.settingvalue });

      }
      if (val.settingname == "Telegram" || val.settingname == "telegram") {
        this.setState({ telegram: val.settingvalue });
      }
      if (val.settingname == "Support" || val.settingname == "support") {
        this.setState({ support: val.settingvalue });
      }
      if (val.settingname == "Dex" || val.settingname == "dex") {
        this.setState({ dex: val.settingvalue });
      }
      if (val.settingname == "Medium" || val.settingname == "medium") {
        this.setState({ medium: val.settingvalue });
      }


    })
  }

  getuserdata = async () => {
    try {
      console.log("userdata", getAccount());
      let userdata = await getuserdatahook(this.state.accountInfo.toLowerCase());
      console.log("userdata", userdata?.data?.data);
      this.setState({ wishlist: userdata?.data?.data?.wishlist })

      let auditkycinfo = await getallsalehook();
      console.log("auditkuycinfo", auditkycinfo?.data?.data);
      if (auditkycinfo?.data?.data?.length > 0) {
        this.setState({ auditkyc: auditkycinfo?.data?.data })
      }
      console.log("total_total", this.state.totalSales);

      let saleaddress
      let total = this.state.totalSales?.map((data) => {
        console.log("total_total", total, this.state.totalSales);
        saleaddress = data.saleAddress
      })
      let singleaudit = auditkycinfo?.data?.data?.find(e => e.saleaddress == saleaddress)
      if (singleaudit)
        this.setState({ singleaudit: singleaudit })
    }
    catch (err) {
      console.log("getuserdata", err)
    }
  }




  render() {


    const location = this.props.location.pathname.split('/')[1];



    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">


            <Adminheader />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Adminsidebar />
                <div className='right_side_sec'>

                  <div className="right_side_spacing">
                    <div className='ongoing_sec proj_bg pb-5'>
                      <div className="container px-1">
                        <div className='top_heqad_sec mt-5 mt-md-0'>

                          <div className="row">
                            <div className="col-12 col-md-12 flex_cen_col mt-md-5 mb-md-4 flex_row_cen">
                              <p className="overlay_card_head font_30">Launchpad And Privatesale List</p>
                              <p className="banner_subtitle form_subhead_1">Total Records: {this?.state?.proxy?.length}</p>

                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="inner_bg">
                        <div className="container px-1">
                          <Tab.Container defaultActiveKey="second">
                            <Row className="container-fluid w-100 mx-0 px-0">
                              <Col lg={12} className="img_center_lg px-0">
                                <div className='container container_custom px-0'>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="second">
                                      <div class="table-responsive mt-3 table_repso_with_dropdwon">

                                        {this.state.proxy?.length == 0 && <p className='no_data_text'>No Data Found</p>}

                                        {this.state.proxy?.length > 0 &&
                                          <table className="table table_admin_laun">

                                            <tr className='currenthead'>
                                              <th>Name</th>
                                              <th>Sales Type</th>
                                              <th>Hardcap</th>
                                              <th>Softcap</th>
                                              <th>Badge</th>
                                              <th>Status</th>
                                              <th>Links</th>
                                              <th>Countdown</th>
                                              <th>Ends In</th>
                                              <th>Edit</th>
                                            </tr>
                                            {console.log("searchSale", this?.state?.searchSale)}

                                            {this.state.isSearch ?

                                              (this.state.searchSale && this?.state?.searchSale?.map((data, index) =>
                                                <>
                                                  {console.log("searchSale", data)}
                                                  <tr>
                                                    <td><span className="d-flex align-items-center">

                                                      <div className="relative_token_div  mr-2">
                                                        <img className="token_image_sm " src={data?.logo} />
                                                      </div>
                                                      {data?.symbol}
                                                    </span></td>
                                                    <td>{data?.isWhitelisted ? "PrivateSale" : data?.LaunchpadType ? "Launchpad" : "Fairlaunch"}</td>
                                                    <td>
                                                      <p className='mb-1'>
                                                        {data?.hardCap / 10 ** 18}
                                                      </p>
                                                      <p className='mb-0'>
                                                        ZKETH
                                                      </p>
                                                    </td>

                                                    <td>
                                                      <p className='mb-1'>
                                                        {data?.softCap / 10 ** 18}
                                                      </p>
                                                      <p className='mb-0'>
                                                        ZKETH
                                                      </p>

                                                    </td>

                                                    <td>
                                                      <div className='grad_badge'>

                                                        <>
                                                          {console.log("this.state.singleaudit", this.state.singleaudit)}
                                                          {this.state.singleaudit && !isEmpty(this.state.singleaudit.audit) ?
                                                            <OverlayTrigger
                                                              placement="top"
                                                              delay={{ show: 250, hide: 400 }}
                                                              overlay={renderTooltip}
                                                            >
                                                              <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                                A
                                                              </span>
                                                            </OverlayTrigger> : ""}
                                                          {this.state.singleaudit && !isEmpty(this.state.singleaudit.DOXX) ? <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={renderTooltip1}
                                                          >
                                                            <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                              D
                                                            </span>
                                                          </OverlayTrigger> : ""}
                                                          {this.state.singleaudit && !isEmpty(this.state.singleaudit.SAFU) ?
                                                            <OverlayTrigger
                                                              placement="top"
                                                              delay={{ show: 250, hide: 400 }}
                                                              overlay={renderTooltip2}
                                                            >

                                                              <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                                S
                                                              </span>
                                                            </OverlayTrigger> : ""}
                                                          {this.state.singleaudit && !isEmpty(this.state.singleaudit.kyc) ?
                                                            <OverlayTrigger
                                                              placement="top"
                                                              delay={{ show: 250, hide: 400 }}
                                                              overlay={renderTooltip3}
                                                            >
                                                              <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                                K
                                                              </span>
                                                            </OverlayTrigger> : ""}
                                                        </>
                                                      </div>
                                                    </td>


                                                    <td>
                                                      {console.log("parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)", data?.earnedCap, data?.softCap, data?.symbol, parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100))}

                                                      {data?.LaunchpadType ? <div className='progress_div_pos'><ProgressBar now={data && parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)} className={this.state.iscoming ? 'grey_bar mt-0' : this.state.isended ? 'red_bar mt-0' : 'yellow_bar mt-0'} />

                                                        {this.state.iscoming ?
                                                          <p className='desc_grey_txt abs_per_div'>Coming</p>
                                                          :
                                                          (this.state.isended ?
                                                            <p className='desc_grey_txt abs_per_div'>Ended</p>
                                                            :

                                                            <div className="d-flex align-items-center justify-content-center">
                                                              <span className='desc_grey_txt abs_per_div'>{((data?.earnedCap) / 10 ** data?.decimals).toFixed(3)}/{((data?.hardCap) / 10 ** data?.decimals).toFixed(2)} </span>
                                                            </div>
                                                          )}

                                                      </div>
                                                        :
                                                        <div className='progress_div_pos'><ProgressBar now={data && parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)} className={this.state.iscoming ? 'grey_bar mt-0' : this.state.isended ? 'red_bar mt-0' : 'yellow_bar mt-0'} />
                                                          {this.state.iscoming ?
                                                            <p className='desc_grey_txt abs_per_div'>Coming</p>
                                                            :
                                                            (this.state.isended ?
                                                              <p className='desc_grey_txt abs_per_div'>Ended</p>
                                                              :

                                                              <div className="d-flex align-items-center justify-content-center">
                                                                <span className='desc_grey_txt abs_per_div'>{data && ((data.earnedCap) / 10 ** data?.decimals).toFixed(3)} / {(parseInt(data?.softCap) / 10 ** data?.decimals).toFixed(2)} {this.state.buyToken} </span>
                                                              </div>)
                                                          }

                                                        </div>

                                                      }
                                                    </td>

                                                    <td>
                                                      <div>
                                                        <a target="_blank" href={data && data?.social && data?.social[0]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                          <i class="fa fa-globe" aria-hidden="true"></i>
                                                        </a>

                                                        <a target="_blank" href={data && data?.social && data?.social[1]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                          <i class="fa fa-twitter" aria-hidden="true"></i>
                                                        </a>

                                                        <a target="_blank" href={data && data?.social && data?.social[2]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                        </a>
                                                      </div>
                                                    </td>

                                                    <td className="table_time">{padToDigits(new Date(data?.startTime * 1000)?.getDate()) + "/" + padToDigits(new Date(data?.startTime * 1000)?.getMonth() + 1) + "/" +
                                                      padToDigits(new Date(data?.startTime * 1000)?.getFullYear()) + " " + padToDigits(new Date(data?.startTime * 1000)?.getHours()) + ":" +
                                                      padToDigits(new Date(data?.startTime * 1000)?.getMinutes()) + ":" + padToDigits(new Date(data?.startTime * 1000)?.getSeconds())}</td>
                                                    <td className="table_time">{padToDigits(new Date(data?.endTime * 1000)?.getDate()) + "/" + padToDigits(new Date(data?.endTime * 1000)?.getMonth() + 1) + "/" +
                                                      padToDigits(new Date(data?.endTime * 1000)?.getFullYear()) + " " + padToDigits(new Date(data?.endTime * 1000)?.getHours()) + ":" +
                                                      padToDigits(new Date(data?.endTime * 1000)?.getMinutes()) + ":" + padToDigits(new Date(data?.endTime * 1000)?.getSeconds())}</td>
                                                    <td><a className="link_text_white"
                                                      onClick={() => DeletePresale(data?.saleAddress, getAccount())}
                                                    >Disable</a></td>
                                                  </tr>
                                                </>)) :


                                              (this.state.totalSales && this?.state?.totalSales?.map((data, index) => {
                                                console.log(data, 'this.state.totalSales', this?.state?.auditkyc)
                                                let auditkyc = this.state?.auditkyc?.find((val) => (val?.saleaddress == data?.saleAddress))
                                                console.log('auditkyc', auditkyc)
                                                return (
                                                  <>
                                                    {console.log("totalSales", data, auditkyc)}

                                                    <tr>
                                                      <td><span className="d-flex align-items-center">

                                                        <div className="relative_token_div  mr-2">
                                                          <img className="token_image_sm " src={data?.logo} />
                                                        </div>
                                                        {data?.symbol}
                                                      </span></td>
                                                      <td>{data?.isWhitelisted ? "PrivateSale" : data?.LaunchpadType ? "Launchpad" : "Fairlaunch"}</td>




                                                      <td>
                                                        <p className='mb-1'>
                                                          {data?.hardCap / 10 ** 18}
                                                        </p>
                                                        <p className='mb-0'>
                                                          ZKETH
                                                        </p>
                                                      </td>

                                                      <td>
                                                        <p className='mb-1'>
                                                          {data?.softCap / 10 ** 18}
                                                        </p>
                                                        <p className='mb-0'>
                                                          ZKETH
                                                        </p>

                                                      </td>

                                                      <td>
                                                        <div className='grad_badge'>
                                                          {auditkyc == undefined ?
                                                            <>
                                                              <p className='mb-0'> - </p>
                                                            </> :
                                                            <>
                                                              {console.log("this.state.singleaudit", this.state.singleaudit, this.state.auditkyc)}

                                                              {auditkyc && !isEmpty(auditkyc.audit) ? <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip}
                                                              >
                                                                <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                                  A
                                                                </span>
                                                              </OverlayTrigger> : ""}
                                                              {auditkyc && !isEmpty(auditkyc.DOXX) ? <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip1}
                                                              >
                                                                <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                                  D
                                                                </span>
                                                              </OverlayTrigger> : ""}
                                                              {auditkyc && !isEmpty(auditkyc.SAFU) ? <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip2}
                                                              >

                                                                <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                                  S
                                                                </span>
                                                              </OverlayTrigger> : ""}
                                                              {auditkyc && !isEmpty(auditkyc.kyc) ? <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip3}
                                                              >
                                                                <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                                  K
                                                                </span>
                                                              </OverlayTrigger> : ""}
                                                            </>
                                                          }
                                                        </div>
                                                      </td>


                                                      <td>
                                                        {console.log("parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)", data?.earnedCap, data?.softCap, data?.symbol, parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100))}

                                                        {data?.LaunchpadType ? <div className='progress_div_pos'><ProgressBar now={data && parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)} className={this.state.iscoming ? 'grey_bar mt-0' : this.state.isended ? 'red_bar mt-0' : 'yellow_bar mt-0'} />

                                                          {this.state.iscoming ?
                                                            <p className='desc_grey_txt abs_per_div'>Coming</p>
                                                            :
                                                            (this.state.isended ?
                                                              <p className='desc_grey_txt abs_per_div'>Ended</p>
                                                              :

                                                              <div className="d-flex align-items-center justify-content-center">
                                                                <span className='desc_grey_txt abs_per_div'>{((data?.earnedCap) / 10 ** data?.decimals).toFixed(3)}/{((data?.hardCap) / 10 ** data?.decimals).toFixed(2)} </span>
                                                              </div>
                                                            )}

                                                        </div>
                                                          :
                                                          <div className='progress_div_pos'><ProgressBar now={data && parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)} className={this.state.iscoming ? 'grey_bar mt-0' : this.state.isended ? 'red_bar mt-0' : 'yellow_bar mt-0'} />
                                                            {this.state.iscoming ?
                                                              <p className='desc_grey_txt abs_per_div'>Coming</p>
                                                              :
                                                              (this.state.isended ?
                                                                <p className='desc_grey_txt abs_per_div'>Ended</p>
                                                                :

                                                                <div className="d-flex align-items-center justify-content-center">
                                                                  <span className='desc_grey_txt abs_per_div'>{data && ((data.earnedCap) / 10 ** data?.decimals).toFixed(3)} / {(parseInt(data?.softCap) / 10 ** data?.decimals).toFixed(2)} {this.state.buyToken} </span>
                                                                </div>)
                                                            }

                                                          </div>

                                                        }
                                                      </td>

                                                      <td>
                                                        <div>
                                                          <a target="_blank" href={data && data?.social && data?.social[0]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                                          </a>

                                                          <a target="_blank" href={data && data?.social && data?.social[1]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                            <i class="fa fa-twitter" aria-hidden="true"></i>
                                                          </a>

                                                          <a target="_blank" href={data && data?.social && data?.social[2]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                          </a>
                                                        </div>
                                                      </td>

                                                      <td className="table_time">{padToDigits(new Date(data?.startTime * 1000)?.getDate()) + "/" + padToDigits(new Date(data?.startTime * 1000)?.getMonth() + 1) + "/" +
                                                        padToDigits(new Date(data?.startTime * 1000)?.getFullYear()) + " " + padToDigits(new Date(data?.startTime * 1000)?.getHours()) + ":" +
                                                        padToDigits(new Date(data?.startTime * 1000)?.getMinutes()) + ":" + padToDigits(new Date(data?.startTime * 1000)?.getSeconds())}</td>
                                                      <td className="table_time">{padToDigits(new Date(data?.endTime * 1000)?.getDate()) + "/" + padToDigits(new Date(data?.endTime * 1000)?.getMonth() + 1) + "/" +
                                                        padToDigits(new Date(data?.endTime * 1000)?.getFullYear()) + " " + padToDigits(new Date(data?.endTime * 1000)?.getHours()) + ":" +
                                                        padToDigits(new Date(data?.endTime * 1000)?.getMinutes()) + ":" + padToDigits(new Date(data?.endTime * 1000)?.getSeconds())}</td>
                                                      <td><a className="link_text_white"
                                                        onClick={() => DeletePresale(data?.saleAddress, getAccount())}
                                                      >Disable</a></td>
                                                    </tr>
                                                  </>)
                                              }))

                                            }

                                          </table>
                                        }
                                      </div>

                                      <div className='text-center mt-5'>
                                        {this.state.isSearch ?
                                          <>
                                            {this.state.searchSale && this.state.searchSale?.length < this.state.searchProxy?.length ?

                                              <button className="get-started-btn orbitron_font" onClick={this.loadSearchMore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button>
                                              : <></>
                                            }
                                          </> : <>
                                            {this.state.totalSales && this.state.totalSales?.length < this.state.proxy?.length ?
                                              <button className="get-started-btn orbitron_font" onClick={this.viewmore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button>
                                              : <></>
                                            }
                                          </>
                                        }
                                      </div>
                                    </Tab.Pane>


                                  </Tab.Content>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Container>


                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='pb-5'>
                    <div className="bg_layer mb-0 mt-4 mb-5">
                      <div className="right_side_spacing py-5">

                        <div className="row">

                          <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                            <h3 className="h3_res">Premium Launch Order</h3>
                            <h5 className="h5_res">All in one package </h5>
                            <div className='mt-4 pb-4 div_btn_ful_width'>
                              <button className='get-started-btn orbitron_font text-white'>
                                Contact Agent
                              </button>
                              <button className='get-started-btn-border orbitron_font ml-3'>
                                Learn Premium
                              </button>
                            </div>

                          </div>
                          <div className="col-12 col-md-5 col-xl-6">
                            <p className="title_span mt-4">Ultimate guideline for launching with 1 v 1 support during the process. Instructing project from technical to marketing/branding aspect for the best outcome.</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Adminlaunchpad