import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../../css/styles.css";

import logo_grad from "../../images/logoo.png";



import {
  Container,
  InputGroup,
  FormControl
} from "react-bootstrap";


import { validateemail } from "../../hooks/kycvalidation";
import { forgetPassword } from "../../hooks/usebackend";
import toast from "react-hot-toast";

class Forgotpassword extends Component {


  constructor(props) {
    super(props);
    this.state = {
      email:'',
      errors : {}
    }
  }


  handleSubmit = async()=>{
    let payload = {
      email : this.state.email,
    }
  
    let validate = await validateemail(payload);
    if(validate.isValid){
      var forPass = await forgetPassword(payload);
      console.log("forPass",forPass)
      if(forPass?.data?.data?.success == "false" || !forPass?.data?.data?.success){
        this.setState({"errors" :forPass?.data?.data?.errors})
      }else {
        toast.success(forPass?.data?.data?.message)
        console.log("forPass_forPass" , forPass);
        this.setState({email:''})
      }
     
    }
    else{
      this.setState({"errors" : validate?.errors})
    }
  }


  render() {


    return (
      <div id="loader_main">

        <div className="logo_overlay" id="logo_overlay" style={{ opacity: 1 }}>
          <div className="whole_bg">

          <div className="whole_sec whole_sec_height login_screen pb-5">
            <div>

              <Container className="container">
                <div className="coming_soon login_text">
                  <div className='row'>
                    <div className='col-11 col-sm-9 col-md-9 col-lg-10 mx-auto'>
                      <div className="text-center">
                        <img src={logo_grad} className="img-fluid logo_grad_width" />
                      </div>
                      <div className='bg_layer mt-5'>
                        <div className='card-body pos_z_body_car'>
                          <div className='row'>

                            <div className='col-12 col-md-12 mb-3'>


                              <p class="overlay_card_head">Forgot Password</p>
                              <hr class="hr_green mb-0"></hr>

                            </div>


                            <div className='col-12 col-md-12 mt-3 mb-0'>

                              <p className='input_desc_sm'>Email Address</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="email" placeholder="Enter your email address"
                                    aria-describedby="basic-addon2"
                                    value={this.state.email}
                                    onChange={(e) => {
                                      this.setState({ "email": e?.target?.value })
                                    }}
                                  />

                                </InputGroup>
                                <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.email}</span>
                              </div>
                            </div>


                            <div className='col-12 col-md-12 mt-3 mb-0'>
                              <div className="text-right">
                                <Link to="/login" className="link_ui_admin">Back to Login?</Link>
                              </div>
                            </div>

                            <center className='mt-4 mx-auto'>
                              <button className="get-started-btn orbitron_font btn_min_wid" onClick={()=>{this.handleSubmit()}}>Submit</button>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Forgotpassword;
