import React, { Component } from "react";
import {Link} from "react-router-dom";
import axios from 'axios';

import Cookies from 'universal-cookie';

import "../../css/styles.css";

import logo_grad from "../../images/logoo.png";


import {
  Container,
  InputGroup,
  FormControl
} from "react-bootstrap";
import { validatelogin } from "../../hooks/kycvalidation";
import { loginhook } from "../../hooks/usebackend";
import toast from "react-hot-toast";
const cookies = new Cookies();



class Login extends Component {



  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("logo_overlay").style.overflow = "hidden";
  }

  hideLoader() {

    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("logo_overlay").classList.add("overflow_show");
  }




  constructor(props) {
    super(props);
    this.state = {
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: "",
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      setUpdateCms: false,
      email: "",
      password: "",
      passwordeye: false,
      errors: {},
      ipAddress: ""
    };

  }

  componentDidMount() {
    this.restrict();
    this.getGeoInfo();
    this.hideLoader();
  }

  restrict = async () => {
    var email = cookies.get('cryp-launch-admin-email');
    console.log("email", email);
    if (email) {
      this.props.history.push("/adminlaunchpad")
    }
  }

  showPassword = (e) => {

    var e = document.getElementById("password");
    if (e.type === "password") {
      this.setState({ "passwordeye": true })
      e.type = "text";
    } else {
      this.setState({ "passwordeye": false })
      e.type = "password";
    }
  };

  handlelogin = async () => {
    let payload = {
      email: this.state.email,
      password: this.state.password
    }

    let validate = await validatelogin(payload);
    if (validate.isValid) {
      let data = {
        email: this.state.email,
        password: this.state.password,
        ipAddress: this.state.ipAddress
      }
      var loged = await loginhook(data);
      console.log("loged", loged)
      if (loged?.data?.data?.success == "false" || !loged?.data?.data?.success) {
        toast.error(loged?.data?.data?.data)
      } else {
        console.log("loginhook", loged);
        window.location.href = window.location.origin + "/adminlaunchpad"
      }

    }
    else {
      this.setState({ "errors": validate?.errors })
    }
  }



  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      console.log(data.ip, 'ipapiinfo');
      this.setState({
        ipAddress: data.ip,
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  render() {


    return (
      <div id="loader_main">

        <div className="logo_overlay" id="logo_overlay" style={{ opacity: 1 }}>
          <div className="whole_bg">
            <div className="whole_sec whole_sec_height login_screen pb-5 pt-5">
              <Container className="container">
                <div className="coming_soon login_text">
                  <div className='row'>
                    <div className='col-11 col-sm-9 col-md-9 col-lg-10 mx-auto'>
                      <div className="text-center">
                        <img src={logo_grad} className="img-fluid logo_grad_width" />
                      </div>
                      <div className='bg_layer mt-5'>
                        <div className='card-body pos_z_body_car'>
                          <div className='row'>

                            <div className='col-12 col-md-12 mb-3'>


                              <p class="overlay_card_head">Login</p>
                              <hr class="hr_green mb-0"></hr>

                            </div>


                            <div className='col-12 col-md-12 mt-3 mb-0'>

                              <p className='input_desc_sm'>Email Address</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="email" placeholder="Enter your email address"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                      this.setState({ "email": e?.target?.value })
                                    }}
                                  />

                                </InputGroup>
                                <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.email}</span>
                              </div>
                            </div>


                            <div className='col-12 col-md-12 mt-3 mb-0'>

                              <p className='input_desc_sm'>Password</p>
                              <div className="inputs input-groups btn_positoned">
                                <InputGroup className="">
                                  <FormControl id="password" placeholder="Enter your password" type="password"
                                    className="center_icon"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                      this.setState({ "password": e?.target?.value })
                                    }}
                                  />
                                  {!this.state.passwordeye ?
                                    <InputGroup.Text className="input-group-append"><button className='fa fa-eye-slash trans_cal_btn' onClick={this.showPassword}></button></InputGroup.Text>
                                    : <InputGroup.Text className="input-group-append"><button className='fa fa-eye trans_cal_btn' onClick={this.showPassword}></button></InputGroup.Text>
                                  }
                                </InputGroup>
                                <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.password}</span>
                              </div>

                            </div>

                            <div className='col-12 col-md-12 mt-3 mb-0'>
                              <div className="text-right">
                                <Link to="/forgotpassword" className="link_ui_admin">Forgot Password?</Link>
                              </div>
                            </div>

                            <center className='mt-4 mx-auto'>
                              <button className="get-started-btn orbitron_font btn_min_wid" onClick={this.handlelogin}>Login</button>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
              <div>
              </div>


            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
