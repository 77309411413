import React, { Component } from "react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import PlaceholdingLoader from "../PlaceholdingLoader";
import Trendingslider from "../trendingslider";
import { GetTokenInfo } from "../../hooks/useContract";

import videoIntro from "../../images/video_intro.png";
import playButton from "../../images/icon-container.png";

import videosquare from "../../images/square.png";
import videosquaretwo from "../../images/squaretwo.png";

import iconstop from "../../images/icon_stop.png";

import Videomodal from "./Videomodal";

import { weekcalculation } from "../../helper/weekCal";
import "../../css/styles.css";

import {
  getallsalehook,
  gethomecalculationhook,
  gettrendingdisplayhook,
} from "../../hooks/usebackend";
import {
  gethomelaunchpaddetail,
  getTotalSalesInfo,
  isSaleLive,
  isUpcoming,
  getlaunchpaddatalimit,
} from "../../hooks/useProjects";
import { Userdollar, getAccount } from "../../hooks/useAccount";
import {
  getSaleInfoCard,
  Totalvaluelocked,
  UseTokenInfo,
} from "../../hooks/useContract";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: "",
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      setUpdateCms: false,
      trending: [],
      projects: 0,
      bnbindollar: 0,
      users: 0,
      loadingText: false,
      totallockedvalue: 0,

      auditkyc: [],
      singleaudit: {},
      ProxyCount: 0,

      closeHcmenu: false,
      closeCoinmenu: false,
      closeKycmenu: false,
      closeStatusmenu: false,
      isuser: false,
      button: true,
      mycontribution: [],
      accountInfo: getAccount(),
      interval: 0,
      loadinterval: 3,
      original: [],
      filterdata: "All Status",
      sortdata: "No Filter",
      coin: [],
      status: [],
      type: [],
      trending: [],
      dummylaunchpad: [],
      trending: [],
      filterSales: [],
      viewMore: true,
      arrayofWeek: 20,
      filteredSales: [],
      sumofEarned: 0,
      successSale: 0,
      participants: 0,
      tokeninfo: [],
      trendingSale: [],
      videoModal: false,
      launchval: true,
    };
  }

  componentDidMount() {
    document.getElementById("home_grad_aprent").classList.add("active");
    this.handlegetkyc();
    this.gettrending();
    this.loadData();
    this.Proxydata();
    this.tokenInfo();
    // this.trending();
  }

  handlegetkyc = async () => {
    let auditkycinfo = await getallsalehook();
    if (auditkycinfo?.data?.data?.length > 0) {
      this.setState({ auditkyc: auditkycinfo?.data?.data });
    }

    let singleaudit = auditkycinfo?.data?.data?.find(
      (e) => e.saleaddress == window.location.pathname.split("/")[2]
    );
    console.log("auditkuycinfo", auditkycinfo?.data?.data, singleaudit);

    if (singleaudit) this.setState({ singleaudit: singleaudit });
  };

  async gettrending() {
    let data = await gettrendingdisplayhook();
    console.log("dataa trending", data?.data?.data);
    if (data?.data?.data?.length > 0) {
      let trenddata = await gethomelaunchpaddetail(data?.data?.data);
      console.log("trenddata", trenddata);
      this.setState({ trending: trenddata });
      const Proxydata = await getTotalSalesInfo();

      if (Proxydata?.length > 0) {
        let totallock = 0;
        this.setState({ projects: Proxydata?.length });
        Proxydata?.map(async (data) => {
          console.log("mapdata", data);
          let saledata = await getSaleInfoCard(data?._sale);

          let value = (saledata.presaleRate * saledata.hardCap) / 10 ** 18;

          console.log("calculated value", value, totallock);
          totallock = totallock + value;
          this.setState({
            totallockedvalue: Math.round(totallock * 100) / 100,
          });
        });
      }

      let calculation = await gethomecalculationhook();
      console.log("calculation", calculation);
      let dollar = await Userdollar(calculation?.data?.totalbnb);
      console.log("dollar in bnb", Math.round(dollar * 100) / 100);
      this.setState({ bnbindollar: Math.round(dollar * 100) / 100 });
      this.setState({ users: calculation?.data?.user });
      // let lockedvalue = await Totalvaluelocked();
      // this.setState({loadingText : false})
    }
  }

  async loadData() {
    // this.showLoader();
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata);
    // console.log("Proxydata", Proxydata.length);
    // this.setState({ProxyCount : Proxydata.length})
    // const totalproxydata = Proxydata.filter(data => data._isWhitelisted == false)
    let trenddata = await gettrendingdisplayhook();
    console.log("dataa trending", trenddata?.data?.data);
    if (trenddata?.data?.data?.length > 0)
      this.setState({ trending: trenddata?.data?.data });
    var trending = trenddata?.data?.data;
    console.log("trendingdataaaaaaaaaaaaaa", trending);
    let newtotalproxy = [];
    trending?.map((val, i) => {
      let singledata = Proxydata.find((e) => e?._sale == val.saleaddress);
      if (singledata) {
        newtotalproxy.push(singledata);
      }
    });
    //add on works
    // let dummylaunchpad = await getusecreatedlaunchhook();
    // var dummylaunch = [];
    // console.log("get dummy launchpad" , dummylaunchpad);
    // if(dummylaunchpad?.data?.type == "success"){
    //   dummylaunch = dummylaunchpad?.data?.data?.filter(data => data?.whitelist != "privatesale");
    //   this.setState({dummylaunchpad : dummylaunch})
    //   newtotalproxy = dummylaunch ? newtotalproxy.concat(dummylaunch) : []
    // }

    Proxydata?.map((datas, i) => {
      let singlesale = newtotalproxy.find((e) => e._sale == datas?._sale);
      if (!singlesale) {
        newtotalproxy.push(datas);
      }
    });
    this.setState({ proxy: newtotalproxy });
    console.log("trendingdataaaaaaaaaaaaaa", newtotalproxy);
    console.log("total proxy data", Proxydata);
    // this.setState({ proxy : totalproxydata })
    this.setState({ original: newtotalproxy });
    const total = await getlaunchpaddatalimit(
      newtotalproxy,
      0,
      newtotalproxy.length
    );
    console.log("total>>>", total);
    // const total = await getPrivatesaleCardLimit(Proxydata,0,this.state.interval , this.state.isuser);
    // console.log("button " ,Proxydata?.length , total.index );
    // if(Proxydata?.length == total.index || !total){
    //   this.setState({button : false})
    // }
    // await this.sleep(1000)
    //  this.setState({ totalSales: total.saleInfoCards });
    //  this.setState({ interval: total.index+1 });
    this.setState({ totalSales: total });
    var trending = [];
    this.state.trending?.map((val, i) => {
      console.log("trenddataval", val);
      let singledata = total.find((e) => {
        console.log(
          "e?._sale == val.saleaddress",
          e?.saleaddress == val.saleaddress
        );
        return e?.saleaddress === val.saleaddress;
      });
      console.log("singledata", singledata);
      if (singledata) {
        trending.push(singledata);
      }
    });
    this.setState({ trendingSale: trending });
    let sumofEarned = total?.reduce((total, currentValue) => {
      return (total = parseFloat(total) + parseFloat(currentValue.earnedCap));
    }, 0);
    this.setState({ sumofEarned: sumofEarned / 10 ** 18 });

    let participants = total?.reduce((total, currentValue) => {
      return (total =
        parseFloat(total) + parseFloat(currentValue.participants));
    }, 0);
    this.setState({ participants: participants });

    this.setState({ interval: this.state.loadinterval });
    // this.hideLoader();
    // this.filterdata(weekcalculation(0),0)
  }

  async loadMore() {
    console.log(
      "Lad moreee",
      this.state.totalSales?.length < this.state.proxy?.length
    );
    this.setState({ isLoading: true });
    const newly = await getlaunchpaddatalimit(
      this.state.proxy,
      this.state.interval,
      this.state.interval + this.state.loadInterval
    );
    console.log("newly ", newly);
    // if(newly.length === 0){
    //   this.setState({"viewMore":false})
    // }
    const total = this.state.totalSales?.concat(newly);
    this.setState({
      totalSales: total,
      interval: this.state.interval + this.state.loadInterval,
    });
    this.setState({ isLoading: false });
  }

  async Proxydata() {
    try {
      console.log("Proxydata");

      const Proxydata = await getTotalSalesInfo();
      console.log("Proxydata", Proxydata?.length);
      this.setState({ ProxyCount: Proxydata?.length });
    } catch (err) {
      console.log("err", err);
    }
    // const totalproxydata = Proxydata.filter(data => data._isWhitelisted == false)
  }

  // async filterdata(data,i) {
  //     console.log("data",data,i,this.state.totalSales)
  //     let filterdata = [];
  //     let Sales = this.state.totalSales.filter(values => {
  //       let currentDate = new Date(parseFloat(values.startTime) * 1000)
  //       console.log(currentDate, "currentDate")
  //       var year = new Date(currentDate.getFullYear(), 0, 1)
  //       var days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));
  //       var week = Math.ceil(days / 7);
  //       let val = week + "/" + currentDate.getFullYear()
  //       console.log("val == data",val == data,val,data)
  //       return val == data
  //     })
  //     Sales.sort(function(a,b){
  //        console.log(parseFloat(a.earnedCap) > parseFloat(b.earnedCap),"sort")
  //        return parseFloat(a.earnedCap) > parseFloat(b.earnedCap)
  //     });

  //     let sumofEarned = Sales.reduce((total, currentValue) => {
  //       return total = parseFloat(total) + parseFloat(currentValue.earnedCap);

  //     }, 0)
  //     this.setState({sumofEarned :(sumofEarned)/10**18})

  //     let successSale = Sales.filter(values =>{
  //       console.log("values.softCap > values.earnedCap",values.softCap > values.earnedCap)
  //      return values.earnedCap> values.softCap

  //     })
  //     this.setState({successSale :successSale.length})

  //     console.log("Sales",Sales,successSale,)
  //     // TradeHoursData.sort(function (a, b) {
  //     //   console.log(parseFloat(a.day) - parseFloat(b.day))
  //     //          return parseFloat(a.day) - parseFloat(b.day);
  //     //   });
  //     this.setState({filteredSales : Sales})
  //     console.log("Sales_filterdata", Sales,this.state.filteredSales)
  //   }

  async tokenInfo() {
    const token = await GetTokenInfo();
    console.log("tokentokentoken", token);
    this.setState({ tokeninfo: token });
  }

  // async trending(){
  //   try{
  //   console.log("trendingsale")
  //   // const trendingsale = await getTotalSalesInfo();
  //   // console.log("trendingsale", trendingsale);
  //   const trendingsale = await getlaunchpaddatalimit();
  //   console.log("trendingsale", trendingsale);
  //   let trenddata = await gettrendingdisplayhook();
  //   console.log("gettrendingdisplayhook", trenddata?.data?.data);
  //   let newtotalproxy = [];
  //   trenddata?.data?.data?.map((val, i) => {
  //     console.log("trenddataval",val)
  //     let singledata = trendingsale.find(e =>{
  //       console.log("e?._sale == val.saleaddress",e?._sale == val.saleaddress)
  //      return e?._sale == val.saleaddress
  //     });
  //     console.log("singledata",singledata)
  //     if (singledata) {
  //       newtotalproxy.push(singledata)
  //     }

  //   })
  //   this.setState({trendingSale:newtotalproxy})
  // }catch(err){
  //   console.log("err",err)
  // }
  // }

  render() {
    return (
      <div id="loader_main">
        <div className="logo_overlay" id="logo_overlay" style={{ opacity: 1 }}>
          <div className="whole_bg">
            <Header />

            <div className="whole_sec pb-5">
              <div className="flex_side_right">
                <Sidebar />
                {/* ongoing_sec */}
                <div className="right_side_sec">
                  {/* <div className="text-white topBar d-flex justify-content-between">
                  
                    <small>Trending</small>
                  <small>
                    #1&nbsp;<span>Husy</span>
                  </small>
                  <small>
                    #2&nbsp;<span>ORL</span>
                  </small>
                  <small>
                    #3&nbsp;<span>UWC</span>
                  </small>
                  <small>
                    #4&nbsp;<span>777</span>
                  </small>
                  <small>
                    #5&nbsp;<span>IDXS</span>
                  </small>
                  <small>
                    #6&nbsp;<span>COUGNU</span>
                  </small>
                  <small>
                    #7&nbsp;<span>CRICLE</span>
                  </small>
                  <small>
                    #8&nbsp;<span>KASA</span>
                  </small>
                  <small>
                    #9&nbsp;<span>FIFAPP</span>
                  </small>
                  <small>
                    #10&nbsp;<span>SOG</span>
                  </small>
                  <small>
                    #11&nbsp;<span>COOSHA</span>
                  </small>
                  <small>
                    #12&nbsp;<span>Honey</span>
                  </small>
                  
                  
                </div> */}
                  <Trendingslider />
                  <div className="right_side_spacing pb-0 pt-4">
                    <div className="text-white mt-0 mt-md-5 pb-4 take_section">
                      <div className="text-white mt-2 mt-md-5 pb-4">
                        <div className="row">
                          <div className="col-12 col-md-7 mt-5">
                            <h3 className="h3_res">
                              zkSync’s First Native Launchpad
                            </h3>
                            <span className="title_span">
                              Introducing the very first launchpad on the ZkSync
                              chain - Zklaunching. Zklaunching helps everyone to
                              create their own tokens and token sales in few
                              seconds. Tokens created on Zklaunching will be
                              verified and published on explore website.
                            </span>
                          </div>

                          <div className="col-12 col-md-5">
                            <div className="position-relative">
                              <div>
                                {/* <div className="titleCard mx-auto d-xl-none">
                      Video Intro
                    </div> */}
                                <div>
                                  <img
                                    src={videoIntro}
                                    alt="intro"
                                    className="img-fluid video_bg_img"
                                  />
                                </div>
                                <div className="videoPlay position-absolute d-flex flex-column align-items-center">
                                  {/* <div
                        className="titleCard mx-auto d-none d-xl-block mb-4"
                      >
                        Video Intro
                      </div> */}
                                  <button
                                    className="videoPlayButton"
                                    onClick={() =>
                                      this.setState({ videoModal: true })
                                    }
                                  >
                                    <img
                                      src={playButton}
                                      alt="play"
                                      className="img-fluid"
                                    />

                                    <img
                                      src={videosquare}
                                      className="squareone"
                                    />
                                    <img
                                      src={videosquaretwo}
                                      className="squaretwo"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <center>
                        <br />
                      </center>
                    </div>

                    {/* end ongoing_sec */}
                  </div>

                  <div className="mt-0 pt-4 pb-4 conta_toppols">
                    <div className="bg_layer">
                      <div className="right_side_spacing py-5">
                        <div className="row align-items-center">
                          <div className="col-12 col-md-6 col-lg-5">
                            <div className="tab_div">
                              <div className="card card_tab">
                                <div className="card_tab_header">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div
                                      className={
                                        this.state.launchval
                                          ? "px-3 card_tab_val active val_first"
                                          : "px-3 card_tab_val inactive val_second"
                                      }
                                      onClick={() =>
                                        this.setState({ launchval: true })
                                      }
                                    >
                                      <p className="mb-0 text-center">
                                        Premium Launch {this.state.launchval}{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center btn_stop">
                                      <img
                                        src={iconstop}
                                        className="icon_stop"
                                      />
                                    </div>
                                    <div
                                      className={
                                        this.state.launchval
                                          ? "px-3 card_tab_val inactive val_first"
                                          : "px-3 card_tab_val active val_second"
                                      }
                                      onClick={() =>
                                        this.setState({ launchval: false })
                                      }
                                    >
                                      <p className="mb-0 text-center">
                                        Token Launch
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="card_tab_content py-5 px-3">
                                  {this.state.launchval == 1 ? (
                                    <p className="mb-0 px-3 card_desc_content">
                                      Only for premium projects with manually
                                      approval from Zklaunch team. Contact our
                                      team for further consultants.
                                    </p>
                                  ) : (
                                    <p className="mb-0 px-3 card_desc_content">
                                      Only for token projects with manually
                                      approval from Zklaunch team. Contact our
                                      team for further consultants.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 offset-lg-1 mt-4 mt-md-0">
                            <p className="yellow_text_sml text-md-right">
                              Type of launch
                            </p>
                            <h3 className="h3_res text-uppercase text-md-right">
                              Launch Your Project Now
                            </h3>
                            <p className="title_span text-md-right">
                              A basic token launch or a full service premium
                              launch are Zklaunch’s available options for every
                              project out there
                            </p>
                            <div className="text-md-right mt-4">
                              <button className="notchedButton position-relative">
                                <span className="notchedBg"></span>
                                <div className="content">Consulting</div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right_side_spacing pb-5">
                      <div className="mt-5 text-white">
                        <center>
                          <h3 className="h3_res">Sale on Zklaunch</h3>
                        </center>

                        <div className="mt-5 row">
                          <div
                            xxl="4"
                            className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                          >
                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img
                                    className="card-img-top mx-auto"
                                    src={
                                      require("../../images/standard_icon.png")
                                        .default
                                    }
                                  />
                                </div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">
                                  Standard
                                </div>
                              </div>

                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">
                                  Mint standard tokens on Linea.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            xxl="4"
                            className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                          >
                            <div className="card_bg  card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img
                                    className="card-img-top mx-auto"
                                    src={
                                      require("../../images/premium_icon.png")
                                        .default
                                    }
                                  />
                                </div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">
                                  Premium Launch
                                </div>
                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">
                                  Direct support from Zklaunch team with a full
                                  batch of all services.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            xxl="4"
                            className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                          >
                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img
                                    className="card-img-top mx-auto"
                                    src={
                                      require("../../images/deflationary_icon.png")
                                        .default
                                    }
                                  />
                                </div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">
                                  Deflationary
                                </div>
                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">
                                  Generate deflationary tokens with tax and/or
                                  charity functions.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            xxl="4"
                            className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                          >
                            <div className="card_bg  card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img
                                    className="card-img-top mx-auto"
                                    src={
                                      require("../../images/customization_icon.png")
                                        .default
                                    }
                                  />
                                </div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">
                                  Customization
                                </div>
                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">
                                  Create a token sale for your own custom token
                                  easily.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            xxl="4"
                            className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                          >
                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img
                                    className="card-img-top mx-auto"
                                    src={
                                      require("../../images/launchpad_icon.png")
                                        .default
                                    }
                                  />
                                </div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">
                                  Launchpad
                                </div>
                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">
                                  Use the token you mint to create a launchpad
                                  with just a few clicks
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            xxl="4"
                            className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                          >
                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img
                                    className="card-img-top mx-auto"
                                    src={
                                      require("../../images/branding_icon.png")
                                        .default
                                    }
                                  />
                                </div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">
                                  Branding
                                </div>
                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">
                                  Adding logo, social links, description,
                                  listing on Zklaunch
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            xxl="4"
                            className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                          >
                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img
                                    className="card-img-top mx-auto"
                                    src={
                                      require("../../images/management_icon.png")
                                        .default
                                    }
                                  />
                                </div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">
                                  Management
                                </div>
                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">
                                  The portal to help you easily update content
                                  for your launchpad.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            xxl="4"
                            className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                          >
                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img
                                    className="card-img-top mx-auto"
                                    src={
                                      require("../../images/community_icon.png")
                                        .default
                                    }
                                  />
                                </div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">
                                  Community
                                </div>
                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">
                                  Promote your launchpad to thousands of buyers
                                  on Zklaunch.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            xxl="4"
                            className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                          >
                            <div className="card_bg card_bg_grad h-100 py-3 px-4 card">
                              <div className="d-flex align-items-center">
                                <div className="card_img_pare_div">
                                  <img
                                    className="card-img-top mx-auto"
                                    src={
                                      require("../../images/locking_icon.png")
                                        .default
                                    }
                                  />
                                </div>
                                <div className="card-title h5 mb-0 ml-4 orbitron_font">
                                  Locking
                                </div>
                              </div>
                              <div className="text-left  card-body pb-1 px-0 mt-3">
                                <p className="card-text">
                                  Lock your liquidity to Zklaunch Swap after
                                  presale.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {this.state.videoModal && (
          <Videomodal onDismiss={() => this.setState({ videoModal: false })} />
        )}
      </div>
    );
  }
}

export default Landing;
