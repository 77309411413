import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal, InputGroup, FormControl, ProgressBar } from 'react-bootstrap';
import { getAccount } from "../../hooks/useAccount";
import { addWhitelistMembers } from "../../hooks/useAdmin";
import toast, { Toaster } from 'react-hot-toast';
import { editDescription } from '../../hooks/useContract';
import { addwhitelistvalidation } from '../../hooks/kycvalidation';

import axios from 'axios';
import isEmpty from 'is-empty';


// const formValue = {
//     Description: ''
// }

class DescriptionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            show: true,
            saleInfo: props.SaleInfo,
            accountInfo: getAccount(),

        };
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps, "nextProps")
        this.setState({ saleInfo: nextProps.SaleInfo })
    }
    handleChange = (e) => {

        let { name, value } = e.target;
        let Data = { [name]: value };
        let formData = {...this.state.saleInfo,...Data}
        this.setState({ saleInfo: formData });
        console.log("formValue", this.state.saleInfo)
    }

    updateDescription = async() => {
        const account = getAccount();   

        if (isEmpty(this.state.saleInfo.description)) {
            console.log("if")
            const Des = { ...this.state.errors, ["description"]: "Invalid Description!" };
            this.setState({ errors: Des });
            return
        }
        else if(this.state.saleInfo.owner !== account){
            const Des = { ...this.state.errors, ["description"]: "Only sale owner can edit the description of the sale." };
            this.setState({ errors: Des });
            return
        }
        else {
            console.log("else")
            const Des = { ...this.state.errors, ["description"]: "" };
            this.setState({ errors: Des });
        }
        var data= []
        data.push(this.state.saleInfo.description)
        data.push(this.state.saleInfo.social[0])
        data.push(this.state.saleInfo.social[1])
        data.push(this.state.saleInfo.social[2])
        data.push(this.state.saleInfo.logo)
        console.log("data")
        var update = await editDescription(this.state.saleInfo?.saleAddress,data)
        console.log("update",update)
        this.props.Close()
        
    }


    render() {


        const { ClaimModal } = this.state

          console.log("saleInfo",this.state.saleInfo)
        return (

            <Modal className="wallet-modal" show={this.state.show} centered size="md">
                <Modal.Header className='pt-3 header_odal_head'>
                    <h3 className="sec-head ">Edit Description</h3>
                    <button type="button" class="close" onClick={() => { this.props.Close() }}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet modal_body pt-2">
                    <p class="input_desc_sm">Description</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl name="description" placeholder="Enter the Description" value={this.state.saleInfo?.description}

                                onChange={(e) => { this.handleChange(e) }}
                            />

                        </InputGroup>
                        <span className="text-danger f-12 d-block text-left mt-2">{this.state.errors?.description}</span>
                    </div>

                    <div className='d-flex justify-content-center mt-3 mb-3'>
                        <button className="btn_black"
                            onClick={async () => await this.updateDescription()}
                        >
                            Update
                        </button>
                    </div>


                </Modal.Body>
            </Modal>



        )
    }

}


export default DescriptionModal