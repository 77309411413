const BASE_URL = "/admin";

export const loginApi = BASE_URL + "/login"

export const getallkycApi = BASE_URL + "/getallkyc";

export const aprovekycApi = BASE_URL + "/approvekyc";

export const rejectkycApi = BASE_URL + "/rejectkyc";

export const getsettingApi = BASE_URL + "/getsetting";

export const addsettingApi = BASE_URL + "/addsetting";

export const TokenCreationApi = BASE_URL + "/createToken";

export const updatesettingApi = BASE_URL + "/updatesetting";

export const getcoinApi = BASE_URL + "/getcoin";

export const addcoinApi = BASE_URL + "/addcoin";

export const addtrending = BASE_URL + "/addtrending";

export const updatetrending = BASE_URL + "/updatetrending";

export const gettrending = BASE_URL + "/gettrending";

export const adddummylaunch = BASE_URL + "/create-dummy-launchpad";

export const editdummylaunch = BASE_URL + "/edit-dummy-launchpad";

export const getdummylaunch = BASE_URL + "/getdummylaunch";

export const addReferrer = "/admin/addReferrer";

export const GetReferrer = "/admin/user/referrerinfo";

export const forgetPassword = BASE_URL + "/forgetPassword";

export const resetPassword = BASE_URL + "/resetPassword";

export const profileSettings = BASE_URL + "/profileSettings";

export const sentVerifyLink = BASE_URL + "/sentVerifyLink";

export const verifyNewEmail = BASE_URL + "/verifyNewEmail";

export const addIP = BASE_URL + "/addIP";

export const deleteIP = BASE_URL + "/deleteIP";

export const getIP = BASE_URL + "/getIP";

export const addFee = BASE_URL + "/addFee";

export const deleteFee = BASE_URL + "/deleteFee";

export const getFee = BASE_URL + "/getFee";

export const editFee = BASE_URL + "/editFee";

