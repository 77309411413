import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

import Trendingslider from '../trendingslider';


import { getAccount } from '../../hooks/useAccount'
import { Card } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import isEmpty from "is-empty";
import toast, { Toaster } from 'react-hot-toast';
import Walletmodal from "../Walletmodal";
import moment from 'moment';

import {ThreeMonthsInSeconds, SixMonthsInSeconds, TwelveMonthsInSeconds} from "../../config/env"


import Web3 from "web3";

import { Container, FormControl, InputGroup } from 'react-bootstrap';
import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"
import { Link } from 'react-router-dom';
import { validtokenlock } from '../../hooks/kycvalidation';
import { Approvetoken, Checkaddress, Createlock, CreatelockWithout, Gettokenbalance, toFixedNumber } from '../../hooks/useContract';
import { iconTheme, position, style } from '../../hooks/useToast';
import bgstyle2 from "../../images/bg_style2.png";

import bgstyle from "../../images/bg_style.png";

import bgoutline1 from "../../images/bg_outline1.png";
import whiteoutline1 from "../../images/outline-white1.png";

import bgoutline from "../../images/bg_outline.png";
import whiteoutline from "../../images/outline-white.png";
import bgoutline2 from "../../images/bg_outline2.png";
import whiteoutline2 from "../../images/outline-white2.png";

class CreateLock extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");

  }



  showLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    // document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div_connect").classList.remove("d-none");
    document.getElementById("loader_div_connect").classList.add("d-block");


  }

  hideLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    // document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div_connect").classList.remove("d-block");
    document.getElementById("loader_div_connect").classList.add("d-none");
  }

  async fetchdata1() {

    if (!localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")) {
      this.showLoaderConnect()
    }
    else {
      this.hideLoaderConnect();
      // this.showLoaderConnect()
    }
  }


  onDismiss() {
    this.setState({ walletModal: false });
    if (sessionStorage.getItem("accountInfo")) {
      this.hideLoaderConnect();
    }
  }

  componentDidMount() {
    // this.showLoader();
    document.getElementById("lock_parent").classList.add("active");
    this.hideLoader();
    this.fetchdata1()

    //    this.setState({ accountInfo: getAccount() });
  }
  mindate() {
    let date = new Date();
    date.setDate(date.getDate() + 30)
    this.setState({ mindates: date })
  }

  // filterPassedTime = (time) => {
  //   var currentDate = this.state.startDate == '' ? new Date() : new Date(this.state.startDate);
  //   if(this.state.endDate != ""){
  //       currentDate = new Date();
  //   }
  //   const selectedDate =  new Date(time);

  //   return currentDate.getTime() < selectedDate.getTime();
  // };

  // filterPassedTime = (time) => {
  //   const currentDate = new Date(Date.now() + (86400000 *30));
  //   currentDate.setHours(0);
  //   currentDate.setMinutes(0);
  //   currentDate.setSeconds(0);
  //   // this.setState({locktime : currentDate});
  //   // var time 
  //   // currentDate.setTime("00:00:00")
  //   const selectedDate = new Date(Date.now() + (86400000 *30)).setHours(0 , 0 ,0);
  //   // selectedDate.setHours(0)
  //   // selectedDate.setMinutes(0);
  //   // selectedDate.setSeconds(0);

  //   // setSelecteddata()
  //   return selectedDate;
  // };

  constructor(props) {
    super(props);
    this.state = {
      accountInfo: '',
      startDate: '',
      anotherowner: false,
      tokenaddress: "",
      anotherowneraddress: "",
      amount: "",
      locktime: "",
      tgedate: "",
      tgepercent: "",
      cycle: 0,
      cyclepercent: 0,
      vesting: false,
      tokeninfo: {},
      errors: {},
      lockbutton: false,
      mindates: "",
      datestatus: false,
      walletModal: false,
      showcustomlock: false



    };
  }


  filterPassedTime = (time) => {
    var currentDate = new Date()
    // new Date(Date.now() * 86400 * 30 * 1000);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  }


  handlelock = async () => {
    let payload = {};
    payload.tokenaddress = this.state.tokenaddress;
    payload.amount = this.state.amount;
    payload.locktime = this.state.locktime.toString();
    payload.anotherowneraddress = this.state.anotherowneraddress;
    payload.anotherowner = this.state.anotherowner;
    payload.vesting = this.state.vesting;
    payload.cycle = isEmpty(this.state.cycle) ? "0" : toFixedNumber(this.state.cycle * 86400).toString();
    payload.cyclepercent = isEmpty(this.state.cyclepercent) ? "0" : toFixedNumber(this.state.cyclepercent * 10 ** 18).toString();

    console.log('payload', payload);
    const validate = await validtokenlock(payload);
    if (validate.isValid) {
      const lock = await Createlock(payload, this.state.tokeninfo.decimals)
      console.log("lock ", lock);
      this.props.history.push({
        pathname: "/lockinfo",
        state: lock
      });
    }
    else {
      this.setState({ errors: validate.errors })
    }
  }

  handleapprove = async () => {
    let payload = {};
    payload.tokenaddress = this.state.tokenaddress;
    payload.amount = this.state.amount;
    if (this.state.anotherowner) {
      payload.owner = this.state.anotherowneraddress;
    }
    if (this.state.vesting) {
      payload.tgedate = this.state.locktime.toString()//this.state.tgedate;
      // payload.tegpercent = this.state.tgepercent;
      payload.cycle = isEmpty(this.state.cycle) ? "0" : toFixedNumber(this.state.cycle * 86400).toString();;
      payload.cyclepercent = isEmpty(this.state.cyclepercent) ? "0" : toFixedNumber(this.state.cyclepercent * 10 ** 18).toString();
      payload.locktime = this.state.locktime.toString();

      payload.vesting = true
    }
    else {
      payload.locktime = this.state.locktime.toString();
    }
    console.log('payload', payload);
    const validate = await validtokenlock(payload);
    if (validate.isValid) {
      const approve = await Approvetoken(this.state.tokenaddress, this.state.amount);
      this.setState({ lockbutton: approve })
    }
    else {
      this.setState({ errors: validate.errors })
    }
  }

  copyText(a, b) {
    toast.success("Address Copied", {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
    }
    )

  }

  //     filterPassedTime = (time)
  //     => {
  //            const currentDate = new Date();
  //            const selectedDate = new Date(time)
  //    ;

  //            return currentDate.getTime() < selectedDate.getTime();
  //          };



  threeMonths(){
    const currentDate = Date.now();
    console.log("currentDate", currentDate*1000)
    let Month = (currentDate + ThreeMonthsInSeconds*1000)
    console.log("currentDate1",new Date(Month),parseFloat(new Date(Month)), ThreeMonthsInSeconds, ThreeMonthsInSeconds*1000)
    this.setState({locktime:Month})
   }

   sixMonths(){
    const currentDate = Date.now();
    console.log("currentDate", currentDate*1000)
    let Month = (currentDate + SixMonthsInSeconds*1000)
    console.log("currentDate1",new Date(Month), SixMonthsInSeconds, SixMonthsInSeconds*1000)
    this.setState({locktime:Month})

   }

   twelveMonths(){
    const currentDate = Date.now();
    console.log("currentDate", currentDate*1000)
    let Month = (currentDate + TwelveMonthsInSeconds*1000)
    console.log("currentDate1",new Date(Month), TwelveMonthsInSeconds, TwelveMonthsInSeconds*1000)
    this.setState({locktime:Month})

   }


  render() {

    const location = this.props.location.pathname.split('/')[1];

    const { startDate, walletModal } = this.state

    // {console.log("date" , new Date(this.state.locktime).getTime());}


    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">
            <Header />
            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Sidebar />
                <div className='right_side_sec pb-5'>

                  <div id="loader_div_connect">

                    <div className='load_connect_text'>
                      <button className="get-started-btn btn_connect_loader" onClick={() => this.setState({ walletModal: true })}>
                        Connect Wallet to Continue
                      </button>
                    </div>
                  </div>
                  {/* <div className="text-white topBar d-flex justify-content-between">
                  
                  <small>Trending</small>
                <small>
                  #1&nbsp;<span>Husy</span>
                </small>
                <small>
                  #2&nbsp;<span>ORL</span>
                </small>
                <small>
                  #3&nbsp;<span>UWC</span>
                </small>
                <small>
                  #4&nbsp;<span>777</span>
                </small>
                <small>
                  #5&nbsp;<span>IDXS</span>
                </small>
                <small>
                  #6&nbsp;<span>COUGNU</span>
                </small>
                <small>
                  #7&nbsp;<span>CRICLE</span>
                </small>
                <small>
                  #8&nbsp;<span>KASA</span>
                </small>
                <small>
                  #9&nbsp;<span>FIFAPP</span>
                </small>
                <small>
                  #10&nbsp;<span>SOG</span>
                </small>
                <small>
                  #11&nbsp;<span>COOSHA</span>
                </small>
                <small>
                  #12&nbsp;<span>Honey</span>
                </small>
                
                
              </div> */}
                  <Trendingslider />
                  <div className="right_side_spacing kyc_sec">
                    <div className="bg_outline1">
                      <img src={bgoutline1} className="out_dark" />
                      <img src={whiteoutline1} className="out_light" />
                    </div>
                    <div className='row my-5'>
                      <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                        <div className="tab_img">
                          <div className='bg_layer'>
                            <div className='card-body'>



                              <div>
                                <div className='row'>

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p class="overlay_card_head">Create Your Lock</p>
                                    {/* <hr class="hr_green mb-0"></hr> */}
                                  </div>
                                  {/* <div className='col-12 col-md-12 mb-3'>

                            <Card className="card_bg h-100">
                        
                        <Card.Body>
                        <Card.Title className='card_title_text'>PinkLock is audited by:</Card.Title>
                        <img style={{ height: "50px", width: "50px" }}
                          src={favicon}/>
                        </Card.Body>
                        
                      </Card>
                      </div> */}

                                  <div className='col-12 col-md-12 mt-3 mb-0'>

                                    <p className='input_desc_sm'>Token LP Address *</p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="file" onChange={async (e) => {
                                          this.setState({ tokenaddress: e.target.value })
                                          const tokendata = await Checkaddress(e.target.value);
                                          console.log("tokendata", tokendata);
                                          if (tokendata.isValid) {
                                            this.setState({ tokeninfo: tokendata.tokeninfo })
                                            this.setState({ errors: {} });
                                            this.setState({ lockbutton: tokendata.allowance })
                                          }
                                          else {
                                            this.setState({ errors: tokendata.errors });
                                            this.setState({ tokeninfo: {} })
                                          }
                                        }} placeholder=""
                                          aria-describedby="basic-addon2"
                                        />

                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.tokenaddress}</span>

                                    </div >
                                    <div className={isEmpty(this.state.tokeninfo) ? "d-none" : 'col-12 col-md-12 mb-3'}>
                                      <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                        <span className='desc_grey_txt'>Name :</span>
                                        <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.name}</span>
                                      </p>


                                      <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                        <span className='desc_grey_txt'>Symbol :</span>
                                        <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.symbol}</span>
                                      </p>

                                      <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                        <span className='desc_grey_txt'>Decimals :</span>
                                        <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.decimals}</span>
                                      </p>
                                    </div>
                                    <div className="custom-control custom-checkbox mt-3">
                                      <input type="checkbox" className="custom-control-input" id="customCheck1" onChange={(e) => { this.setState({ "anotherowner": e.target?.checked }) }} />
                                      <label className="custom-control-label" for="customCheck1">Use another owner?</label>
                                    </div>
                                  </div>


                                  <div className={this.state.anotherowner ? 'col-12 col-md-12 mt-3 mb-0' : "d-none"}>

                                    <p className='input_desc_sm'>Owner</p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="file" onChange={(e) => this.setState({ anotherowneraddress: e.target.value })} placeholder="Ex: My Lock"
                                          aria-describedby="basic-addon2"
                                        />

                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.anotherowneraddress}</span>
                                    </div>

                                  </div>



                                  <div className='col-12 col-md-12 mt-3 mb-0'>

                                    <p className='input_desc_sm'>Amount*</p>
                                    <div className="inputs input-groups date_inoput_grps btn_positoned">
                                      <InputGroup className="datepicker_input">
                                        <FormControl id="amountmax" placeholder="Enter Amount"
                                          aria-describedby="basic-addon2"
                                          value={this.state.amount}
                                          onChange={(e) => {
                                            var numbers = /^\d+((.)|(.\d{0,4})?)$/;
                                            if (!numbers.test(e.target.value) && e.target.value !== "") {
                                              return false
                                            }
                                            this.setState({ "amount": e?.target?.value })
                                          }}
                                        />

                                      </InputGroup>
                                      <InputGroup.Append className='cur_pointer'>
                                        <button variant="outline-secondary" className="trans_cal_btn"
                                          disabled={this?.state?.errors?.tokenaddress || !this.state.tokenaddress}
                                          onClick={async () => {
                                            const balance = await Gettokenbalance(this.state.tokenaddress);
                                            this.setState({ amount: balance/10**18 })

                                          }}>
                                          Max
                                        </button>
                                      </InputGroup.Append>
                                    </div>
                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.amount}</span>

                                    <div className="custom-control custom-checkbox mt-3">
                                      <input type="checkbox" className="custom-control-input" id="customCheck2" onChange={(e) => { this.setState({ "vesting": e.target?.checked }) }} />
                                      <label className="custom-control-label" for="customCheck2">Use Vesting?</label>
                                    </div>
                                  </div>


                                  <div className={!this.state.showcustomlock ? 'col-12 col-md-12 mt-3 mb-0' : "d-none"}>

                                    <p className='input_desc_sm'>{this.state.vesting ? "TGE Date*" : "Lock until*"}</p>

                                    <div className="inputs input-groups date_inoput_grps btn_positoned">
                                      <InputGroup className="datepicker_input">
                                        <FormControl id="lockval" placeholder=""
                                          aria-describedby="basic-addon2"
                                          value={isEmpty(this.state.locktime)?'':moment(new Date(this.state.locktime)).format('MMMM d, yyyy h:mm a')}

                                        />

                                      </InputGroup>
                                      <InputGroup.Append className='cur_pointer mob_btn_grp_new'>
                                        <button variant="outline-secondary" className="trans_cal_btn mr-1" onClick={()=>{this.threeMonths()}}
                                        >
                                          3M
                                        </button>
                                        <button variant="outline-secondary" className="trans_cal_btn mr-1"  onClick={()=>{this.sixMonths()}} 
                                        >
                                          6M
                                        </button>
                                        <button variant="outline-secondary" className="trans_cal_btn" onClick={()=>{this.twelveMonths()}}
                                        >
                                          12M
                                        </button>
                                      </InputGroup.Append>
                                    </div>
                                  </div>

                                  <div className="custom-control custom-checkbox mt-3">
                                      <input type="checkbox" className="custom-control-input" id="customCheck3" onChange={(e) => { this.setState({ "showcustomlock": e.target?.checked }) }} />
                                      <label className="custom-control-label" for="customCheck3">Customize Lock</label>
                                    </div>

                                  {/* <div className='col-12 mt-3'>
                                    <button className='get-started-btn-border orbitron_font' onClick={() => this.setState({ showcustomlock: true })}>
                                      Customize Lock
                                    </button>
                                  </div> */}

                                    <div className={this.state.showcustomlock ? 'col-12 col-md-12 mt-3 mb-0' : "d-none"}>
                                      <div className="inputs input-groups date_inoput_grps inut_grp_date_inpt_pad btn_positoned">
                                        <InputGroup className="datepicker_input" value={this.state.locktime} readonly  >
                                          <DatePicker
                                             minDate= {new Date(Date.now() + (86400000 * 30))}
                                             // {new Date(Date.now() + (600000))}
                                            //   filterTime={this.filterPassedTime.bind(this)}
                                            // disabledKeyboardNavigation
                                            showTimeSelect
                                            // selected={this.state.locktime}
                                            selected={isEmpty(this.state.locktime) ? '':new Date(this.state.locktime)}
                                            onChange={(date) => {
                                              console.log("locktime", date);
                                              let formData = { ...this.state.errors, ["locktime"]: "" };
                                              this.setState({ errors: formData });
                                              this.setState({ "locktime": date });
                                              this.setState({ datestatus: true })
                                              if(Date.parse(date) > Date.now() + (86400000 * 29)) {
                                                // (Date.parse(date) > Date.now() + (600000)) {
                                                let formData = { ...this.state.errors, ["locktime"]: "" };
                                                this.setState({ errors: formData });
                                              }
                                              else {
                                                let formData = { ...this.state.errors, ["locktime"]: "Unlock time needs to be after 30 days" };
                                                this.setState({ errors: formData, locktime: "" });
                                              }
                                            }}
                                            // onKeyDown={e => e.preventDefault()}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                          />

                                        </InputGroup>
                                        <InputGroup.Append className='cur_pointer create_lock_date'>
                                          <button variant="outline-secondary" className="trans_cal_btn">
                                            <i class="far fa-calendar-alt"></i>
                                          </button>
                                        </InputGroup.Append>
                                      </div>
                                      <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.locktime}</span>
                                    </div>
                                  


                                  {/* <div className={this.state.vesting ? 'col-12 col-md-12 mt-3 mb-0' : "d-none"}>
                        
                        <p className='input_desc_sm'>TGE Date (UTC time)</p>
                        <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl id="file"  onChange={(e)=>this.setState({ tgedate: e.target.value }) } placeholder="Ex: My Lock"
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                    
                            </div> */}
                                  {/* <div className={this.state.vesting ? 'col-12 col-md-12 mt-3 mb-0' : "d-none"}>
                        
                        <p className='input_desc_sm'>TGE Date (UTC time)*</p>
                        <div className="inputs input-groups date_inoput_grps">
                        <InputGroup className="datepicker_input" value = {this.state.tgedate}>
                        <DatePicker
                        //   filterTime={this.filterPassedTime.bind(this)}
                          showTimeSelect
                          selected={this.state.tgedate}
                          onChange={(date)=> {
                            this.setState({tgedate: date})
                            if(Date.parse(date) > Date.now()){
                              // this.setState({tgedate: date});
                            }
                            else{
                              let formData = { ...this.state.errors, ["tgedate"]: "TGE Date needs to be after now" };
                               this.setState({ errors: formData });
                            }
                        
                          }}
                          dateFormat="MMMM d, yyyy h:mm aa"
                                                 />
                          
                        </InputGroup>
                        <InputGroup.Append className='cur_pointer'>
                                <button variant="outline-secondary" className="trans_cal_btn">
                                <i class="far fa-calendar-alt"></i>
                                </button>
                            </InputGroup.Append>
                    </div>
                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.tgedate}</span>

                            </div> */}

                                  {/* <div className={this.state.vesting ? 'col-12 col-md-12 mt-3 mb-0' : "d-none"}>
                        
                        <p className='input_desc_sm'>TGE Percent</p>
                        <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl id="file"  onChange={(e)=>this.setState({ tegpercent: e.target.value }) } placeholder="Ex: My Lock"
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.tgepercent}</span>
                    </div>
                    
                            </div> */}

                                  <div className={this.state.vesting ? 'col-12 col-md-12 mt-3 mb-0' : "d-none"}>

                                    <p className='input_desc_sm'>Cycle (days)</p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="file" onChange={(e) => {
                                          var numbers = /^\d+((.)|(.\d{0,4})?)$/;
                                          if (!numbers.test(e.target.value) && e.target.value !== "") {
                                            return false
                                          }
                                          this.setState({ cycle: e.target.value })

                                        }}
                                          value={this?.state?.cycle}
                                          placeholder="Ex: 20"
                                          aria-describedby="basic-addon2"
                                        />

                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.cycle}</span>
                                    </div>

                                  </div>

                                  <div className={this.state.vesting ? 'col-12 col-md-12 mt-3 mb-0' : "d-none"}>

                                    <p className='input_desc_sm'>Cycle Release Percent</p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="file" onChange={(e) => {
                                          var numbers = /^\d+((.)|(.\d{0,4})?)$/;
                                          if (!numbers.test(e.target.value) && e.target.value !== "") {
                                            return false
                                          }
                                          this.setState({ cyclepercent: e.target.value })
                                        }}
                                          value={this.state?.cyclepercent}

                                          placeholder="Ex:50"
                                          aria-describedby="basic-addon2"
                                        />

                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.cyclepercent}</span>
                                    </div>

                                  </div>
                                  <div className='col-12 col-sm-11 col-md-10 col-lg-8 mx-auto'>


                                    <center className={this.state.lockbutton ? "d-none" : 'mt-4 mx-auto'}>

                                      <button className="get-started-btn btn_fullwidth_grad mb-3" 
                                      onClick={this.handleapprove}
                                      >
                                        <p className='btn_text_head mb-3'>Approve</p>
                                        <p className='mb-0 btn_text_desc'>
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 pl-3'>Please exclude SPAILock's lockup address <span className='wallet_address_text wallet_address_text_bk'><strong className='pl-1'>0x2e8bAe13157A414AcA1a50E24a08c2357B2d65C0</strong>
                                              <CopyToClipboard text={"0x2e8bAe13157A414AcA1a50E24a08c2357B2d65C0"} onCopy={() => this.copyText('invite link', "0x2e8bAe13157A414AcA1a50E24a08c2357B2d65C0")}>
                                                <button variant='link' className='fa fa-copy' style={{ backgroundColor: "transparent", border: 0 }}></button>
                                              </CopyToClipboard></span>
                                              {/* <span className='copy_icon'> <i class="fa fa-files-o" aria-hidden="true"></i> */}
                                              {/* </span>  */}
                                              from fees, rewards, max tx amount to start locking tokens. We don't support rebase tokens.</p>
                                          </div>
                                        </p>
                                      </button>
                                    </center>
                                    <center className={this.state.lockbutton ? 'mt-4 mx-auto' : "d-none"}>
                                      <button className="get-started-btn btn_fullwidth_grad mb-3" 
                                       onClick={this.handlelock}
                                      >
                                        <p className='btn_text_head mb-3'>Lock</p>

                                        <p className='mb-0 btn_text_desc'>
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 pl-3'>Please exclude SPAILock's lockup address <span className='wallet_address_text wallet_address_text_bk'><strong className='pl-1'>0x2e8bAe13157A414AcA1a50E24a08c2357B2d65C0</strong>
                                              <CopyToClipboard text={"0x2e8bAe13157A414AcA1a50E24a08c2357B2d65C0"} onCopy={() => this.copyText('invite link', "0x2e8bAe13157A414AcA1a50E24a08c2357B2d65C0")}>
                                                <button variant='link' className='fa fa-copy' style={{ backgroundColor: "transparent", border: 0 }}></button>
                                              </CopyToClipboard></span>
                                              {/* <span className='copy_icon'> <i class="fa fa-files-o" aria-hidden="true"></i> */}
                                              {/* </span>  */}
                                              from fees, rewards, max tx amount to start locking tokens. We don't support rebase tokens.</p>
                                          </div>

                                        </p>
                                      </button>
                                    </center>
                                  </div>

                                </div>
                              </div>



                            </div>
                          </div>
                        </div>
                      </div>




                    </div>


                  </div>
                  <div className="bg_layer mb-5">
                    <div className="right_side_spacing py-5">

                      <div className="row">

                        <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                          <h3 className="h3_res">Premium Launch Order</h3>
                          <h5 className="h5_res">All in one package </h5>
                          <div className='mt-4 pb-4 div_btn_ful_width'>
                            <button className='get-started-btn orbitron_font text-white'>
                              Contact Agent
                            </button>
                            <button className='get-started-btn-border orbitron_font ml-3'>
                              Learn Premium
                            </button>
                          </div>

                        </div>
                        <div className="col-12 col-md-5 col-xl-6">
                          <p className="title_span mt-4">Ultimate guideline for launching with 1 v 1 support during the process. Instructing project from technical to marketing/branding aspect for the best outcome.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {walletModal && <Walletmodal connect={"string"} onDismiss={() => this.onDismiss()} />}

      </div>
    )
  }
}

export default CreateLock