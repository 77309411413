import React, { Component } from 'react';

import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Footer from "../Footer";
import Cookies from 'universal-cookie';

import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"
import { InputGroup, FormControl } from 'react-bootstrap';
import { GetAdminfee, SetDeploymentfee, SetTokenfee, SetUserfee } from '../../hooks/useContract';
const cookies = new Cookies();


class Adminlaunchpadsettings extends Component {

  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    this.hideLoader();
    this.getfee();
    this.restrict();
  }

  restrict = async () => {
    var email = cookies.get('cryp-launch-admin-email');
    console.log("email", email);
    if (!email) {
      this.props.history.push("/login")
    }
  }

  async getfee() {
    var fee = await GetAdminfee();
    console.log("fee", fee);
    this.setState({ deploymentfee: fee.deploymentfee / 10 ** 18 });
    this.setState({ tokenfee: fee.tokenfee / 10 ** 18 });
    this.setState({ userfee: fee.liquidityTokenFee / 10 ** 18 });
  }

  async settokenFee(value) {
    await SetTokenfee(value)
  }


  constructor(props) {
    super(props);
    this.state = {
      deploymentfee: 0,
      tokenfee: 0,
      userfee: 0,
      airdropfee: 0
    }
  }

  render() {


    const location = this.props.location.pathname.split('/')[1];



    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">

            <Adminheader />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Adminsidebar />
                <div className='right_side_sec pb-5'>

                  <div className="right_side_spacing">

                    <div className='row my-5 row_ppos'>
                      <div className='col-12 col-md-12 col-lg-12 mx-auto'>
                        <div className="tab_img">
                          <div className='bg_layer'>
                            <div className='card-body'>
                              <p className='overlay_card_head mb-0 mt-2'>Launchpad Settings</p>

                              <div className="tab_par px-0 py-3 mt-4">

                                <div className='row'>
                                  <div className='col-12'>
                                    <p className='input_desc_sm'>Pool Creation Fee</p>




                                    <div className="inputs input-groups date_inoput_grps btn_positoned">
                                      <InputGroup className="datepicker_input">
                                        <FormControl id="price" placeholder="Enter Price"
                                          aria-describedby="basic-addon2"
                                          onChange={(e) => { this.setState({ deploymentfee: e?.target?.value }) }}
                                          value={this.state.deploymentfee}
                                        />
                                        <InputGroup.Append className='cur_pointer mob_btn_grp_new'>

                                          <button variant="outline-secondary" className="trans_cal_btn"
                                            // disabled
                                            onClick={() => { SetDeploymentfee(this.state.deploymentfee) }}
                                          >
                                            Update
                                          </button>
                                        </InputGroup.Append>

                                      </InputGroup>
                                    </div>
                                  </div>
                                  <div className='col-12 mt-3'>
                                    <p className='input_desc_sm'>Liquidity Token Creation Fee</p>
                                    <div className="inputs input-groups date_inoput_grps btn_positoned">
                                      <InputGroup className="datepicker_input">
                                        <FormControl id="tokenaddres" placeholder="Enter Token Address"
                                          aria-describedby="basic-addon2"
                                          onChange={(e) => { this.setState({ userfee: e?.target?.value }) }}
                                          value={this.state.userfee}
                                        />
                                        <InputGroup.Append className='cur_pointer mob_btn_grp_new'>

                                          <button variant="outline-secondary" className="trans_cal_btn"
                                            onClick={() => { SetUserfee(this.state.userfee) }}
                                          >
                                            Update
                                          </button>
                                        </InputGroup.Append>

                                      </InputGroup>
                                    </div>
                                  </div>
                                  <div className='col-12 mt-3'>
                                    <p className='input_desc_sm'>Token Creation Fee</p>
                                    <div className="inputs input-groups date_inoput_grps btn_positoned">
                                      <InputGroup className="datepicker_input">
                                        <FormControl id="mincontribution" placeholder="Enter Minimum Contribution"
                                          aria-describedby="basic-addon2"
                                          onChange={(e) => { this.setState({ tokenfee: e?.target?.value }) }}
                                          value={this.state.tokenfee}
                                        />
                                        <InputGroup.Append className='cur_pointer mob_btn_grp_new'>

                                          <button variant="outline-secondary" className="trans_cal_btn"
                                            onClick={() => { this.settokenFee(this.state.tokenfee) }}
                                          >
                                            Update
                                          </button>
                                        </InputGroup.Append>

                                      </InputGroup>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                  </div>

                  <div className="bg_layer mb-5">
                    <div className="right_side_spacing py-5">

                      <div className="row">

                        <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                          <h3 className="h3_res">Premium Launch Order</h3>
                          <h5 className="h5_res">All in one package </h5>
                          <div className='mt-4 pb-4 div_btn_ful_width'>
                            <button className='get-started-btn orbitron_font text-white'>
                              Contact Agent
                            </button>
                            <button className='get-started-btn-border orbitron_font ml-3'>
                              Learn Premium
                            </button>
                          </div>

                        </div>
                        <div className="col-12 col-md-5 col-xl-6">
                          <p className="title_span mt-4">Ultimate guideline for launching with 1 v 1 support during the process. Instructing project from technical to marketing/branding aspect for the best outcome.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <Footer />
        </div>
      </div>
    )
  }
}

export default Adminlaunchpadsettings