import React, { Component } from 'react';
import bar_img_white from "../../images/bar_img_white.png"
import linea from "../../images/linea.png";
import settingsicon from "../../images/settings_icon.png"
import kycicon from "../../images/kyc.png"
import launchpadicon from "../../images/launchpad_sidebar.png"
import adminlaunchpad from "../../images/adminlaunchpad.png"
import adminsettings from "../../images/admin_settings.png"
import feeSettings from "../../images/feeSettings.png"
import audit from "../../images/audit.png"

import { NavLink } from "react-router-dom";

class Adminsidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location: false,
            sidebarShrink: false,
            setActive: 0
        }
    }

    setSidebarWidth() {
        this.setState({ sidebarShrink: !this.state.sidebarShrink });
        if (this.state.sidebarShrink) {
            document.body.classList.remove('sidebar_shr');
        }
        else {
            document.body.classList.add('sidebar_shr');

        }
    }

    render() {
        const { sidebarShrink, setActive } = this.state



        return (
            <>
                <button className="get-started-btn rounded_btn_wal shrink_side_btn d-none d-lg-block" onClick={() => this.setSidebarWidth()}>
                    <img src={bar_img_white} className='img-fluid' />
                </button>

                <div id="sidebar" className={sidebarShrink ? "side_shrink lis_paren_side_admn" : "lis_paren_side_admn"}>


                    <ul className='sidebar_ul'>
                        <NavLink to="/adminlaunchpad" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less mb-4'>

                                <img src={launchpadicon} className="icon_sidebar" />
                                <span>
                                    Launchpad
                                </span>

                            </li>


                        </NavLink>

                        <NavLink to="/adminkyc" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less mb-4'>

                                <img src={kycicon} className="icon_sidebar" />
                                <span>
                                    KYC
                                </span>

                            </li>
                        </NavLink>

                        <NavLink to="/adminsettings" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less mb-4'>

                                <img src={settingsicon} className="icon_sidebar" />
                                <span>
                                    Settings
                                </span>

                            </li>
                        </NavLink>

                        <NavLink to="/launchpadsettings" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less mb-4'>

                                <img src={adminlaunchpad} className="icon_sidebar" />
                                <span>
                                    Launchpad Settings
                                </span>

                            </li>
                        </NavLink>

                        {/* <NavLink to="/admintrending" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less mb-4'>

                                <img src={trending} className="icon_sidebar" />
                                <span>
                                    OnTop
                                </span>

                            </li>
                        </NavLink> */}

                        <NavLink to="/adminaudit" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less mb-4'>

                                <img src={audit} className="icon_sidebar" />
                                <span>
                                    Audit/KYC
                                </span>

                            </li>
                        </NavLink>

                        <NavLink to="/settings" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less mb-4'>

                                <img src={adminsettings} className="icon_sidebar" />
                                <span>
                                    Profile Settings
                                </span>

                            </li>
                        </NavLink>

                        {/* <NavLink to="/restrictIp" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={restrictIP} className="icon_sidebar" />
                                <span>
                                    Restrict IP
                                </span>

                            </li>
                        </NavLink> */}



                        <NavLink to="/feeSettings" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less mb-4'>

                                <img src={feeSettings} className="icon_sidebar" />
                                <span>
                                    Fee Settings
                                </span>

                            </li>
                        </NavLink>

                        <NavLink to="/admincreatelaunchpad" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={launchpadicon} className="icon_sidebar" />
                                <span>
                                    Admin Launchpad
                                </span>

                            </li>
                        </NavLink>

                    </ul>


                </div>

                <div id="footer_side">
                    <div className='footer_menu_flex_side'>
                        <a className="mr-3 link_wallet_new"
                        >
                            <img src={linea} className='img-fluid img_linea_mob' />
                        </a>
                        <button className="btn_bar_mobl d-lg-none" onClick={() => this.setSidebarWidth()}>
                            <img src={bar_img_white} className='img-fluid img_bar' />
                        </button>
                    </div>
                </div>

            </>
        );
    }
}



export default Adminsidebar