import React, { Component } from "react";
import toast from "react-hot-toast";
import { InputGroup, FormControl, Form, Container } from "react-bootstrap";
import isEmpty from "is-empty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ONEDAYINSECONDS, CHAINS, ZEROTH_ADDRESS } from "../../config/env";
import {
  UseTokenInfo,
  IsValidAddress,
  createPresale,
  toFixedNumber,
  depositTokens,
  isFloat,
  getSalecreationFee,
  Checkaddress,
  Approvetoken,
  GetAdminfee,
} from "../../hooks/useContract";
import { getAccount } from "../../hooks/useAccount";
import { CURRENT_CHAIN_ID, CURRENT_RPC_URL } from "../../hooks/useWeb3";
import TokenModal from "../Launchpad/TokenModal";
import { urlvalidation } from "../../hooks/kycvalidation";

import { getChainId } from "../../hooks/useAccount";
import { getFee } from "../../hooks/usebackend";

import { ObjectIsempty } from "../../helper/ObjectIsempty";

class CreatetabFair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: "",
      name: "",
      symbol: "",
      address: "",
      decimal: "",
      presaleRate: "0",
      softCap: 0,
      hardCap: 0,
      min: 0.000001,
      max: 50,
      isVested: false,
      isPancake: true,
      pancakeRate: 0,
      liquidityPercent: 75,
      unlockOn: 30,
      startDate: "",
      endDate: "",
      vestPercent: 0,
      vestInterval: 0,
      isWithoutToken: false,
      description: "",
      website: "https://zklaunching.com/",
      twitter: "",
      telegram: "",
      facebook: "",
      githup: "",
      instagram: "",
      discord: "",
      reddit: "",
      youtube: "",
      deposit: 0,
      decimals: 0,
      createdSale: "",
      isDeposited: false,
      isWhitelisted: false,
      currentStep: 1,
      tokenModal: false,
      title: "",
      currency: "ZK",
      isToken: false,
      userFee: 0,
      WithTokenaddress: "",
      LaunchpadType: false, // fair
      Fee: 0,
      tokenaddress: "",
      tokeninfo: {},
      errors: {},
      button1: true,
      button2: true,
      button3: true,
      fee: 5,
      userFee: 0,
      approvebutton: false,
      poolfee: "",
      affliateFee: "",
      minAff: "",
      maxAff: "",
      isAffiliate: false,
      TokenDecimal: 0,
      getFee: [],
      CheckedInd: 0,
      tokenFee: 0,
      bnbFee: 0,
      banner: "",
    };
  }

  handledisable = () => {
    this.setState({ button2: false });
    var isvalid = true;
    var i = 1;
    console.log(
      "isvalid_isvalid",
      isvalid,
      ObjectIsempty(this.state.errors),
      this.state.errors,
      this.state.presaleRate,
      this.state.softCap,
      this.state.hardCap,
      this.state.min,
      this.state.max,
      this.state.startDate,
      this.state.endDate,
      this.state.unlockOn,
      this.state.liquidityPercent
    );

    console.log(
      "conditions",
      ObjectIsempty(this.state.errors) &&
        this.state.presaleRate &&
        this.state.softCap &&
        this.state.hardCap &&
        this.state.min &&
        this.state.max &&
        this.state.startDate &&
        this.state.endDate &&
        this.state.unlockOn &&
        this.state.liquidityPercent
    );

    if (
      ObjectIsempty(this.state.errors) &&
      this.state.presaleRate &&
      this.state.softCap &&
      this.state.hardCap &&
      this.state.min &&
      this.state.max &&
      this.state.startDate &&
      this.state.endDate &&
      this.state.unlockOn &&
      this.state.liquidityPercent
    ) {
      isvalid = false;
      if (this.state.isAffiliate) {
        isvalid = true;
        if (this.state.affliateFee) {
          isvalid = false;
        }
      }
      console.log("vest1", isvalid);

      console.log("this.state.isVested", this.state.isVested);
      if (this.state.isVested) {
        isvalid = true;
        console.log("vest4", isvalid);
        if (this.state.vestInterval && this.state.vestPercent) {
          isvalid = false;
          console.log("vest5", isvalid);
        }
      }
    }
    this.setState({ button2: isvalid });
    console.log("isvalid", isvalid);
    return isvalid;
  };

  handledisableurlbutton = ({
    description,
    logo,
    banner,
    website,
    twitter,
    facebook,
    telegram,
    githup,
    instagram,
    discord,
    reddit,
    youtube,
  }) => {
    var isvalid = true;
    if (
      logo &&
      banner &&
      website &&
      facebook &&
      twitter &&
      githup &&
      telegram &&
      instagram &&
      discord &&
      reddit &&
      youtube &&
      description
    ) {
      isvalid = false;
    }
    this.setState({ button3: isvalid });
  };

  socialMediaValidations = () => {
    var errors = {};

    if (isEmpty(this.state.description)) {
      errors.description = "Description field is required";
    }

    if (isEmpty(this.state.website)) {
      errors.website = "Website field is required";
    }

    if (isEmpty(this.state.logo)) {
      errors.logo = "Logo field is required";
    }

    if (isEmpty(this.state.twitter)) {
      errors.twitter = "Twitter field is required";
    }

    this.setState({ errors: errors });
    return errors;
  };

  validations = () => {
    console.log(this.state)
    var errors = {};
    var rd = new RegExp(/^\d+$/);
    var wholeNumbers = new RegExp(/^\d+$/);
    if (!rd.test(parseFloat(this.state.hardCap))) {
      errors.hardcap = "Invalid Total Selling Amount";
    } else if (isNaN(this.state.hardCap)) {
      errors.hardcap = "Invalid Total Selling Amount";
    } else if (!this.state.hardCap || this.state.hardCap == 0) {
      errors.hardcap = "Total Selling Amount field is required";
    } else {
      errors.hardcap = "";
    }

    console.log("thisstatesoft", isEmpty(this.state.softCap));
    var rd = new RegExp(/^\d+$/);
    var rx = new RegExp(/^[-+]?[0-9]+\.[0-9]+$/);
    if (
      !rd.test(parseFloat(this.state.softCap)) ||
      rx.test(parseFloat(this.state.softCap))
    ) {
      errors.softCap = "Invalid softcap !";
    } else if (isNaN(this.state.softCap)) {
      errors.softCap = "Invalid softcap";
    } else if (isEmpty(this.state.softCap) || this.state.softCap === 0) {
      console.log("thisstatesoftCap", isEmpty(this.state.softCap));
      errors.softCap = "Softcap field is required";
    } else {
      errors.softCap = "";
    }

    if (
      (this.state.isAffiliate === true && !this.state.affliateFee) ||
      isNaN(this.state.affliateFee)
    ) {
      errors.affliateFee = "Invalid Affiliate percentage!!";
    } else {
      errors.affliateFee = "";
    }

    if (isNaN(this.state.min) || this.state.min === "0") {
      errors.min = "Invalid Minimum Buy ";
    }
    if (!this.state.min) {
      errors.min = "Minimum Buy field is required";
    }
    if (this.state.max) {
      if (this.state.min > this.state.max) {
        errors.min = "Minimum Buy  must be < Maximum Buy";
      } else {
        errors.min = "";
      }
    }

    if (isNaN(this.state.max)) {
      errors.max = "Invalid Maximum Buy ";
    }
    if (!this.state.max) {
      errors.max = "Maximum Buy field is required";
    } else if (this.state.max > this.state.hardCap) {
      errors.max =
        "Maximum Buy should not be greater than Total Selling Amount";
    }
    if (this.state.min) {
      if (this.state.max < this.state.min) {
        errors.max = "Maximum Buy  must be > Minimum Buy";
      }
    }

    if (this.state.isPancake) {
      var rx = new RegExp(/^\d+$/);
      if (!rx.test(this.state.unlockOn)) {
        errors.lockingday = "Invalid Locking Days !";
      } else if (isEmpty(this.state.unlockOn)) {
        errors.lockingday = "Locking Days field is required!";
      } else if (this.state.unlockOn < 30) {
        errors.lockingday = "Locking Days should be greater than 30 days";
      } else {
        errors.lockingday = "";
      }

      if (
        isNaN(this.state.liquidityPercent) ||
        this.state.liquidityPercent < 51 ||
        this.state.liquidityPercent > 100
      ) {
        errors.liquidity = "Invalid Zklaunch Liquidity !";
      } else {
        errors.liquidity = "";
      }
    }

    if (new Date(this.state.startDate).getTime() >= new Date().getTime()) {
      errors.startDate = "";
    } else if (isEmpty(this.state.startDate)) {
      errors.startDate = "Start Date field is required!";
    } else {
      errors.startDate = "Start Time needs to be after now";
    }
    if (this.state.endDate) {
      if (Date.parse(this.state.startDate) > this.state.endDate) {
        let formData = {
          ...this.state.errors,
          startdate: "Start Time must be < End Time",
        };
      }
      if (this.state.endDate > Date.parse(this.state.startDate)) {
        errors.endDate = "";
      }
    }

    if (Date.parse(this.state.endDate) > Date.now()) {
      errors.endDate = "";
    } else if (isEmpty(this.state.endDate)) {
      errors.endDate = "End Date field is required!";
    } else {
      errors.endDate = "End Time needs to be after now";
    }
    if (this.state.startDate) {
      if (Date.parse(this.state.endDate) <= this.state.startDate) {
        console.log("invalideee");
        errors.endDate = "End Time must be > Start Time";

        this.forceUpdate();
      }
      if (this.state.startDate < Date.parse(this.state.endDate)) {
        errors.startDate = "";
        errors.endDate = "";
      }
    }
    if (this.state.isVested) {
      var rx = new RegExp(/^\d+$/);
      if (!rx.test(this.state.vestInterval)) {
        errors.vestInterval = "Invalid Vesting Period Days !";
      } else {
        errors.vestInterval = "";
      }

      if (
        isNaN(this.state.vestPercent) ||
        this.state.vestPercent < 0 ||
        this.state.vestPercent > 100
      ) {
        errors.vestPercent = "Invalid Rewards % per Vesting Period !";
      } else {
        errors.vestPercent = "";
      }
    }
    console.log("validation_errors", errors);
    this.setState({ errors: errors });
  };

  componentDidMount() {
    window.onbeforeunload = undefined;
    this.GetTokenAddress("BNB");
    this.getData();
    this.getfee();
    this.getFeeSettings();
  }

  getFeeSettings = async () => {
    let get = await getFee();
    console.log("getFee", get);
    if (get?.data?.data?.status == true) {
      let data = get?.data?.data?.data;
      console.log("DATA", data);
      this.setState({ bnbFee: data[0].BNBvalue, tokenFee: data[0].tokenvalue });
      this.setState({ getFee: get?.data?.data?.data });
      console.log("getFee_getFee", get?.data?.data?.data);
    }
  };

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  async getData() {
    var fee = await getSalecreationFee();
    this.setState({ Fee: Number(fee) });
  }
  getfee = async () => {
    let fee = await GetAdminfee();
    this.setState({ poolfee: fee.deploymentfee / 10 ** 18 });
  };

  async useWithtokenInfo() {
    console.log(
      "address",
      this.state.WithTokenaddress,
      this.state.address,
      this.state.WithTokenaddress != ZEROTH_ADDRESS
    );
    if (this.state.WithTokenaddress != ZEROTH_ADDRESS) {
      var usewithToken = await UseTokenInfo(this.state.WithTokenaddress);
      console.log("usewithToken", usewithToken);
      this.setState({ TokenDecimal: usewithToken?.decimals });
    } else {
      this.setState({ TokenDecimal: 18 });
    }
  }

  // Presale Creation
  async CreateSale() {
    await this.useWithtokenInfo();

    window.onbeforeunload = () =>
      window.confirm("You have'nt deposited tokens to start presale !");
    window.onpopstate = () =>
      window.confirm("You have'nt deposited tokens to start presale !");
    console.log("call");
    let Addresses = [];
    let Values = [];
    let Details = [];
    let isSetter = [];

    const account = getAccount();

    const startTime = this.state.startDate;
    const endTime = this.state.endDate;

    const CHAIN_ID = CURRENT_CHAIN_ID();
    const CURRENT_RPC = CURRENT_RPC_URL();
    let ROUTER;
    CHAINS.map((data) => {
      if (
        (data.CHAIN_ID == CHAIN_ID &&
          data.RPC_URL == CURRENT_RPC &&
          data.ROUTER) != false
      )
        ROUTER =
          data.CHAIN_ID == CHAIN_ID &&
          data.RPC_URL == CURRENT_RPC &&
          data.ROUTER;
    });

    const startDiff = parseInt(parseInt(new Date(startTime).getTime()) / 1000);
    const endDiff = parseInt(parseInt(new Date(endTime).getTime()) / 1000);

    // _token 0
    //_router 1
    //owner 2
    //UsewithToken 3
    Addresses.push(this.state.tokenaddress);
    Addresses.push(ROUTER);
    Addresses.push(account);
    Addresses.push(this.state.WithTokenaddress);

    //_min 0
    //_max 1
    //_rate 2
    // _soft  3
    // _hard 4
    //_pancakeRate  5
    //_unlockon  6
    // _percent 7
    // _start 8
    //_end 9
    //_vestPercent 10
    //_vestInterval 11
    //_useFee 12
    Values.push(toFixedNumber(this.state.min * 10 ** 18).toString());
    Values.push(toFixedNumber(this.state.max * 10 ** 18).toString());
    Values.push(toFixedNumber(this?.state?.presaleRate * 10 ** 18).toString());
    Values.push(toFixedNumber(this.state.softCap * 10 ** 18).toString());
    Values.push(toFixedNumber(this.state.hardCap * 10 ** 18).toString());
    Values.push(toFixedNumber(this.state.pancakeRate * 10 ** 18).toString());
    Values.push(this.state.unlockOn.toString());
    Values.push(this.state.liquidityPercent.toString());
    Values.push(startDiff.toString());
    Values.push(endDiff.toString());
    Values.push((this.state.vestPercent * 100).toString());
    Values.push((this.state.vestInterval * ONEDAYINSECONDS).toString());
    Values.push(this.state.userFee.toString());
    Values.push((this.state.affliateFee * 10 ** 18).toString());
    Values.push((0).toString());
    Values.push(
      "115792089237316195423570985008687907853269984665640564039457584007913129639935"
    );
    Values.push((this.state.tokenFee * 10 ** 18).toString());
    Values.push((this.state.bnbFee * 10 ** 18).toString());

    // isAuto 0
    //_isvested 1
    // isWithoutToken 2
    // isWhitelisted 3
    // buyType isBNB or not 4
    // isToken isToken or not 5
    // launchpadtype isnormal or fair
    isSetter.push(this.state.isPancake);
    isSetter.push(this.state.isVested);
    isSetter.push(this.state.isWithoutToken);
    isSetter.push(this.state.isWhitelisted);
    isSetter.push(this.state.currency === "BNB" ? true : false);
    isSetter.push(this.state.isToken);
    isSetter.push(this.state.LaunchpadType);
    // description 0
    // website,twitter,telegram 1,2,3
    //logo 4
    //name 5
    //symbol 6
    // githup 7
    // instagram 8
    // discord 9
    // reddit 10
    // youtube 11
    Details.push(this.state.description);
    Details.push(this.state.website);
    Details.push(this.state.twitter);
    Details.push(this.state.telegram);
    Details.push(this.state.logo);
    Details.push(this.state.name);
    Details.push(this.state.symbol);
    Details.push(this.state.githup);
    Details.push(this.state.instagram);
    Details.push(this.state.discord);
    Details.push(this.state.reddit);
    Details.push(this.state.youtube);
    Details.push(this.state.banner);

    console.log("DATA : ", Addresses, Values, isSetter, Details);
    const sale = await createPresale(
      Addresses,
      Values,
      isSetter,
      Details,
      account
    );
    this.setState({ createdSale: sale });
  }

  goToSale() {
    window.onbeforeunload = undefined;
    window.onpopstate = undefined;
    window.location.href = `${window.location.origin}/launchpaddetail/${this.state.createdSale}`;
  }

  GetTokenAddress(value) {
    let Withtokenaddress;
    if (value === "DAI") {
      Withtokenaddress = CHAINS[getChainId()].DAI;
    } else if (value === "USDT") {
      Withtokenaddress = CHAINS[getChainId()].USDT;
    } else if (value === "USDC") {
      Withtokenaddress = CHAINS[getChainId()].USDC;
    } else if (value === "BNB") {
      Withtokenaddress = CHAINS[getChainId()].BNB;
    }

    console.log("WithTokenaddress", Withtokenaddress);
    this.setState({ WithTokenaddress: Withtokenaddress });
  }

  async getTokenInfo(tokenAddress) {
    const isValid = await IsValidAddress(tokenAddress);
    if (tokenAddress.length == 42 && !isValid) {
    }

    console.log("IS valid ", isValid);
    if (tokenAddress.length == 42 && isValid) {
      const token = await UseTokenInfo(tokenAddress);
      this.setState({ name: token.name });
      this.setState({ symbol: token.symbol });
      this.setState({ decimals: token.decimals });
    }
  }

  calculateDepositTokens(hardCap, liquidityPercent) {
    this.forceUpdate();

    if (this.state.isPancake) {
      var DepositToken = (hardCap / liquidityPercent) * 100;
    }
    console.log(
      "DepositToken",
      this.state.hardCap,
      this.state.liquidityPercent,
      DepositToken
    );
    this.setState({ deposit: parseFloat(DepositToken.toFixed(3)) });
  }

  async DepositTokens() {
    const account = getAccount();
    const tokenAmount = toFixedNumber(
      this.state.deposit * 10 ** this.state.tokeninfo?.decimals
    ).toString();
    const isDeposit = await depositTokens(
      this.state.tokenaddress,
      this.state.createdSale,
      tokenAmount,
      account
    );
    this.setState({ isDeposited: isDeposit });
    console.log("isDeposit", isDeposit);

    window.onbeforeunload = undefined;
    window.onpopstate = undefined;
  }

  renderDeposit() {
    return this.state.deposit > 0 ? (
      <button
        onClick={this.DepositTokens.bind(this)}
        className="get-started-btn"
      >
        Deposit {parseFloat(this.state.deposit)?.toFixed(2)} {this.state.symbol}
      </button>
    ) : (
      <button
        onClick={() =>
          this.calculateDepositTokens(
            this.state.hardCap,
            this.state.liquidityPercent
          )
        }
        className="get-started-btn"
      >
        Calculate
      </button>
    );
  }

  validate() {
    console.log(
      "this.state.hardCap",
      typeof this.state.hardCap,
      "this.state.softCap",
      typeof this.state.softCap,
      this.state.hardCap < this.state.softCap,
      "10<8",
      10 < 8
    );
    if (parseFloat(this.state.hardCap) < parseFloat(this.state.softCap)) {
      toast.error("Hard Cap must be Higher than Soft Cap !", {
        style: {
          minWidth: "300px",
          minHeight: "55px",
        },
      });
    }
    if (parseFloat(this.state.presaleRate) < 0) {
      toast.error("Price must be greater than 0", {
        style: {
          minWidth: "300px",
          minHeight: "55px",
        },
      });
    }
    if (parseFloat(this.state.pancakeRate) < 0) {
      toast.error("Pancake price must be greater than 0", {
        style: {
          minWidth: "300px",
          minHeight: "55px",
        },
      });
    }

    return (
      parseFloat(this.state.hardCap) < parseFloat(this.state.softCap) ||
      parseFloat(this.state.presaleRate) < 0 ||
      parseFloat(this.state.pancakeRate) < 0
    );
  }

  filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  keypress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  async settokenaddress(value) {
    this.setState({ tokenaddress: value });
    const tokendata = await Checkaddress(value);
    console.log("tokendata", tokendata);
    if (tokendata.isValid) {
      this.setState({ tokeninfo: tokendata.tokeninfo });
      this.setState({ errors: {} });
      this.setState({ approvebutton: tokendata.allowance });
      this.setState({ button1: false });
    } else {
      let formData = {
        ...this.state.errors,
        tokenaddress: "Invalid token address !",
      };
      this.setState({ errors: formData });
      this.setState({ tokeninfo: {} });
    }
  }

  async approvetoken() {
    await Approvetoken(this.state.tokenaddress);
    this.setState({ approvebutton: true });
  }

  onDismiss() {
    this.setState({ tokenModal: false });
  }

  render() {
    const { tokenModal, errors } = this.state;
    console.log(errors);
    return (
      <div>
        <div className="row py-4 row_rever_md">
          <div className="col-12 col-md-8 col-lg-8">
            <div className="ongoing_sec form_sec h-100">
              <div className="inner_bg h-100">
                <div className="row h-100">
                  <div className="col-12 h-100">
                    <div className="tab_img h-100">
                      <div className="card_overlay h-100">
                        <div className="card-body pb-5">
                          <div className="row align-items-center pb-4">
                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                              <p className="overlay_card_head">
                                Create Fair Launch
                              </p>

                              <p className="text-white font_12 mb-0">
                                (*) is required field.
                              </p>
                            </div>
                            <div
                              className={
                                this.state.currentStep == 1
                                  ? "col-12 col-md-6 text-md-right d-block"
                                  : "col-12 col-md-6 text-md-right d-none"
                              }
                            >
                              <button
                                className="get-started-btn font_20 btn_icon_grad"
                                onClick={() =>
                                  this.setState({ tokenModal: true })
                                }
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div
                            id="firststep"
                            className={
                              this.state.currentStep == 1 ? "d-block" : "d-none"
                            }
                          >
                            <div className="row">
                              <div className="col-12 col-md-12">
                                <p className="input_desc_sm">Token Address*</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      value={this.state.tokenaddress}
                                      onChange={(e) =>
                                        this.settokenaddress(e.target.value)
                                      }
                                      id="tokenaddress"
                                      placeholder=""
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.tokenaddress}
                                  </span>
                                </div>
                                <div className="note_desc mt-1 mb-1">
                                  <p>
                                    Pool creation fee: {this.state.poolfee} BNB
                                  </p>
                                </div>
                              </div>

                              <div
                                className={
                                  isEmpty(this.state.tokeninfo)
                                    ? "d-none"
                                    : "col-12 col-md-12 mb-3"
                                }
                              >
                                <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                                  <span className="desc_grey_txt">Name :</span>
                                  <span className="desc_grey_txt font_12">
                                    {this.state.tokeninfo?.name}
                                  </span>
                                </p>

                                <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                                  <span className="desc_grey_txt">
                                    Symbol :
                                  </span>
                                  <span className="desc_grey_txt font_12">
                                    {this.state.tokeninfo?.symbol}
                                  </span>
                                </p>

                                <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                                  <span className="desc_grey_txt">
                                    Decimals :
                                  </span>
                                  <span className="desc_grey_txt font_12">
                                    {this.state.tokeninfo?.decimals}
                                  </span>
                                </p>
                              </div>

                              <div className="col-12 col-md-12 mb-1">
                                <p className="input_desc_sm">Currency</p>
                                <div className="d-flex">
                                  <div
                                    className={
                                      this.state.currency === "BNB"
                                        ? "currency_badge active mr-2"
                                        : "currency_badge mr-2"
                                    }
                                    value="BNB"
                                    onClick={(e) => {
                                      console.log(
                                        e.target.getAttribute("value")
                                      );
                                      this.setState({
                                        currency:
                                          e.target.getAttribute("value"),
                                      });
                                      this.GetTokenAddress(
                                        e.target.getAttribute("value")
                                      );
                                    }}
                                  >
                                    <span>ZKETH</span>
                                  </div>
                                  <div
                                    className={
                                      this.state.currency == "DAI"
                                        ? "currency_badge active mr-2"
                                        : "currency_badge mr-2"
                                    }
                                    value="DAI"
                                    onClick={(e) => {
                                      this.setState({
                                        currency:
                                          e.target.getAttribute("value"),
                                      });
                                      this.GetTokenAddress(
                                        e.target.getAttribute("value")
                                      );
                                    }}
                                  >
                                    <span>DAI</span>
                                  </div>
                                  <div
                                    className={
                                      this.state.currency == "USDT"
                                        ? "currency_badge active mr-2"
                                        : "currency_badge mr-2"
                                    }
                                    value="USDT"
                                    onClick={(e) => {
                                      this.setState({
                                        currency:
                                          e.target.getAttribute("value"),
                                      });
                                      this.GetTokenAddress(
                                        e.target.getAttribute("value")
                                      );
                                    }}
                                  >
                                    <span>USDT</span>
                                  </div>
                                  <div
                                    className={
                                      this.state.currency == "USDC"
                                        ? "currency_badge active mr-2"
                                        : "currency_badge mr-2"
                                    }
                                    value="USDC"
                                    onClick={(e) => {
                                      this.setState({
                                        currency:
                                          e.target.getAttribute("value"),
                                      });
                                      this.GetTokenAddress(
                                        e.target.getAttribute("value")
                                      );
                                    }}
                                  >
                                    <span>USDC</span>
                                  </div>
                                </div>
                                <div className="note_desc mt-1 mb-1">
                                  {this.state.currency === "BNB" ? (
                                    <p>
                                      Users will pay with ZKETH for your token
                                    </p>
                                  ) : (
                                    <p>
                                      Users will pay with {this.state.currency}{" "}
                                      for your token
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Fee Options</p>
                                {this.state.getFee.map((data, index) => {
                                  console.log("map", data, index);
                                  let isBNB = data.BNBvalue > 0 ? true : false;
                                  let isToken =
                                    data.tokenvalue > 0 ? true : false;
                                  let i = index;
                                  return (
                                    <>
                                      <div className="custom-control custom-radio mb-2">
                                        <div
                                          onClick={() => {
                                            console.log(
                                              "valsdgfsdsdgsd",
                                              data,
                                              5,
                                              i
                                            );
                                            this.setState({ CheckedInd: i });
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            id="customRadio"
                                            name={`customRadio${i}`}
                                            className="custom-control-input"
                                            // checked
                                            checked={
                                              this.state.CheckedInd == i
                                                ? true
                                                : false
                                            }
                                            // onChange={(e) => {
                                            //     console.log("val",data, 5,i);
                                            //     this.setState({CheckedInd : i})
                                            //     this.setState({ fee: e.target.value })
                                            //     this.setState({ isToken: true });
                                            //     this.setState({ bnbFee: data.BNBvalue })
                                            //     this.setState({ tokenFee:data.tokenvalue})

                                            // }}
                                          />
                                          {console.log(
                                            "tokenFee+bnbFee",
                                            this.state.tokenFee,
                                            this.state.bnbFee
                                          )}
                                          {/* <label className="custom-control-label" for={`customRadio${i}`}>{isBNB ? `${data.BNBvalue}% ${this.state.currency} raised` : ""} {isBNB && isToken ? "+" : ''} {isToken ? `${data.tokenvalue}% token sold` : ''} <span>{isBNB && !isToken ? "(recommended)" : ''}</span></label> */}
                                          <label
                                            className="custom-control-label"
                                            for={`customRadio${i}`}
                                          >
                                            {isBNB
                                              ? `${data.BNBvalue}% ZKETH raised`
                                              : ""}{" "}
                                            {isBNB && isToken ? "+" : ""}{" "}
                                            {isToken
                                              ? `${data.tokenvalue}% token sold`
                                              : ""}{" "}
                                            <span>
                                              {isBNB && !isToken
                                                ? "(recommended)"
                                                : ""}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                                {console.log(
                                  "kjcsfikjulksfss",
                                  this.state.CheckedInd
                                )}

                                {/* <div className="custom-control custom-radio">
                                        <input type="checkbox" id="customRadio2" name="customRadio" value={2} className="custom-control-input"
                                            checked={this.state.fee == 2 ? true : false}
                                            onChange={(e) => {
                                                console.log("val", 2);
                                                this.setState({ fee: e.target.value })
                                                this.setState({ isToken: false });
                                                this.setState({ userFee: userFeeWithtoken })
                                            }}
                                        />
                                        <label className="custom-control-label" for="customRadio2">{2}% {this.state.currency} raised + 2% token sold</label>
                                    </div> */}
                              </div>

                              {/* <div className='col-12 col-md-12 mb-3'>
    <p className='input_desc_sm'>Listing Options</p>
    <div className='d-flex'>
        <div className='currency_badge active mr-2'>Auto</div>
        <div className='currency_badge mr-2'>Manual</div>                           

    </div>

    </div> */}
                              {this.state.currency == "USDC" ? (
                                <div className="col-12 col-md-12 mb-4 mt-4">
                                  <div className="card_footer_form">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <i
                                        class="fa fa-exclamation-circle text-danger-war"
                                        aria-hidden="true"
                                      ></i>
                                      <p className="mb-0 pl-3">
                                        Do not use this currency for auto
                                        liquidity tokens, or tokens that depend
                                        on WETH pair. It will lead to error when
                                        finalizing the pool or transferring the
                                        tokens (for example Liquidity Generator
                                        Token, BabyToken, BuyBack Baby Token){" "}
                                        <br />
                                        Contact Space Launch for more
                                        information.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="col-12 col-md-12 text-center">
                                {this.state.approvebutton && (
                                  <button
                                    className="get-started-btn-border orbitron_font btn_min_wid"
                                    onClick={() => {
                                      this.setState({ currentStep: 2 });
                                    }}
                                    disabled={this.state.button1}
                                  >
                                    Next
                                  </button>
                                )}

                                {!this.state.approvebutton && (
                                  <button
                                    className="get-started-btn btn_fullwidth_grad"
                                    onClick={() => {
                                      this.approvetoken();
                                    }}
                                    disabled={this.state.button1}
                                  >
                                    <p className="btn_text_head mb-3">
                                      Approve
                                    </p>
                                    <p className="mb-0 btn_text_desc">
                                      (For auto listing, after you finalize the
                                      pool your token will be auto listed on
                                      DEX)
                                    </p>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            id="secondstep"
                            className={
                              this.state.currentStep == 2 ? "d-block" : "d-none"
                            }
                          >
                            <div className="row">
                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">
                                  Total Selling Amount
                                </p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="hardcap"
                                      value={this.state.hardCap}
                                      onChange={(e) => {
                                        const formvalue = {
                                          ...this.state.errors,
                                          hardcap: "",
                                        };
                                        let Errors = this.state.errors;
                                        this.setState({ errors: formvalue });
                                        this.setState({
                                          hardCap: e.target.value,
                                        });

                                        var rd = new RegExp(
                                          /^\d+((.)|(.\d{0,4})?)$/
                                        );
                                        console.log(
                                          "!rd.test(parseFloat(e.target.value))",
                                          !rd.test(parseFloat(e.target.value))
                                        );
                                        if (
                                          !rd.test(parseFloat(e.target.value))
                                        ) {
                                          Errors = {
                                            ...Errors,
                                            hardcap:
                                              "Invalid Total Selling Amount",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (isNaN(e.target.value)) {
                                          Errors = {
                                            ...Errors,
                                            hardcap:
                                              "Invalid Total Selling Amount",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (
                                          !e.target.value ||
                                          e.target.value == 0
                                        ) {
                                          Errors = {
                                            ...Errors,
                                            hardcap:
                                              "Total Selling Amount field is required",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (
                                          parseFloat(e.target.value) <
                                          parseFloat(this.state.softCap)
                                        ) {
                                          Errors = {
                                            ...Errors,
                                            hardcap:
                                              "Total Selling Amount must be greater than the softcap",
                                          };
                                          this.setState({ errors: Errors });
                                        } else {
                                          Errors = { ...Errors, hardcap: "" };
                                          this.setState({ errors: Errors });
                                        }

                                        if (
                                          parseFloat(this.state.softCap) >
                                          parseFloat(e.target.value)
                                        ) {
                                          Errors = {
                                            ...Errors,
                                            softCap:
                                              "Softcap must be less than total selling amount",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (
                                          parseFloat(this.state.softCap) <
                                          parseFloat(e.target.value)
                                        ) {
                                          Errors = { ...Errors, softCap: "" };
                                          this.setState({ errors: Errors });
                                        }

                                        this.handledisable();
                                        console.log(
                                          "condition",
                                          e.target.value < this.state.softcap
                                        );
                                      }}
                                      placeholder=""
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.hardcap}
                                  </span>
                                </div>
                              </div>
                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Whitelist</p>

                                <div className="custom-control custom-radio mb-2">
                                  <input
                                    type="radio"
                                    checked={
                                      !this.state.isWhitelisted ? true : false
                                    }
                                    onChange={(e) =>
                                      this.setState({ isWhitelisted: false })
                                    }
                                    id="customRadio3"
                                    name="customRadio"
                                    className="custom-control-input"
                                  />
                                  <label
                                    className="custom-control-label"
                                    for="customRadio3"
                                  >
                                    Disable
                                  </label>
                                </div>

                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    checked={
                                      this.state.isWhitelisted ? true : false
                                    }
                                    onChange={(e) =>
                                      this.setState({ isWhitelisted: true })
                                    }
                                    id="customRadio4"
                                    name="customRadio"
                                    className="custom-control-input"
                                  />
                                  <label
                                    className="custom-control-label"
                                    for="customRadio4"
                                  >
                                    Enable
                                  </label>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Soft Cap*</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      value={this.state.softCap}
                                      onChange={(e) => {
                                        this.setState({
                                          softCap: e.target.value,
                                        });
                                        const formvalue = {
                                          ...this.state.errors,
                                          softcap: "",
                                        };
                                        let Errors = this.state.errors;
                                        this.setState({ errors: formvalue });
                                        var rd = new RegExp(/^\d+$/);
                                        var rx = new RegExp(
                                          /^\d+((.)|(.\d{0,4})?)$/
                                        );

                                        if (
                                          !rx.test(parseFloat(e.target.value))
                                        ) {
                                          Errors = {
                                            ...Errors,
                                            softCap: "Invalid softcap !",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (isNaN(e.target.value)) {
                                          Errors = {
                                            ...Errors,
                                            softCap: "Invalid softcap",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (!e.target.value) {
                                          Errors = {
                                            ...Errors,
                                            softCap:
                                              "Softcap field is required",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (
                                          parseFloat(e.target.value) >
                                          parseFloat(this.state.hardCap)
                                        ) {
                                          Errors = {
                                            ...Errors,
                                            softCap:
                                              "Softcap must be less than total selling amount",
                                          };
                                          this.setState({ errors: Errors });
                                        } else {
                                          Errors = { ...Errors, softCap: "" };
                                          this.setState({ errors: Errors });
                                        }

                                        if (
                                          parseFloat(this.state.hardCap) <
                                          parseFloat(e.target.value)
                                        ) {
                                          Errors = {
                                            ...Errors,
                                            hardcap:
                                              "Total Selling Amount must be greater than the softcap",
                                          };
                                          console.log(
                                            "Errors",
                                            Errors,
                                            this.state.hardCap,
                                            e.target.value
                                          );
                                          this.setState({ errors: Errors });
                                        } else if (
                                          parseFloat(this.state.hardCap) >
                                          parseFloat(e.target.value)
                                        ) {
                                          Errors = { ...Errors, hardcap: "" };
                                          console.log(
                                            "Errors",
                                            Errors,
                                            this.state.hardCap,
                                            e.target.value
                                          );
                                          this.setState({ errors: Errors });
                                        }
                                      }}
                                      id="softcap"
                                      placeholder="0"
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.softCap}
                                  </span>
                                </div>
                                <div className="note_desc mt-1 mb-0"></div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Minimum Buy *</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="mincontribution"
                                      value={this.state.min}
                                      onChange={(e) => {
                                        const formvalue = {
                                          ...this.state.errors,
                                          min: "",
                                        };
                                        let Errors = this.state.errors;

                                        this.setState({ errors: formvalue });
                                        this.setState({ min: e.target.value });
                                        if (
                                          isNaN(e.target.value) ||
                                          e.target.value === "0"
                                        ) {
                                          Errors = {
                                            ...Errors,
                                            min: "Invalid Minimum Buy ",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (!e.target.value) {
                                          Errors = {
                                            ...Errors,
                                            min: "Minimum Buy field is required",
                                          };
                                          this.setState({ errors: Errors });
                                        } else {
                                          Errors = { ...Errors, min: "" };
                                          this.setState({ errors: Errors });
                                        }

                                        if (this.state.max) {
                                          if (
                                            e.target.value >= this.state.max
                                          ) {
                                            Errors = {
                                              ...Errors,
                                              min: "Minimum Buy must be < Maximum Buy",
                                            };
                                            this.setState({ errors: Errors });
                                          }
                                          if (e.target.value < this.state.max) {
                                            Errors = { ...Errors, max: "" };
                                            this.setState({ errors: Errors });
                                          }
                                        } else {
                                          Errors = { ...Errors, min: "" };
                                          this.setState({ errors: Errors });
                                        }

                                        this.handledisable();
                                      }}
                                      placeholder=""
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.min}
                                  </span>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">
                                  Maximum Buy ({this.state.currency})*
                                </p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="maxcontribution"
                                      value={this.state.max}
                                      onChange={(e) => {
                                        const formvalue = {
                                          ...this.state.errors,
                                          max: "",
                                        };
                                        let Errors = this.state.errors;
                                        this.setState({ errors: formvalue });
                                        this.setState({ max: e.target.value });
                                        {
                                          console.log(
                                            "minmax",
                                            e.target.value,
                                            this.state.hardCap
                                          );
                                        }
                                        if (isNaN(e.target.value)) {
                                          Errors = {
                                            ...Errors,
                                            max: "Invalid Maximum Buy ",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (!e.target.value) {
                                          Errors = {
                                            ...Errors,
                                            max: "Maximum Buy field is required",
                                          };
                                          this.setState({ errors: Errors });
                                        } else if (
                                          e.target.value >
                                          parseFloat(this.state.hardCap)
                                        ) {
                                          {
                                            console.log("minmax");
                                          }
                                          Errors = {
                                            ...Errors,
                                            max: "Maximum Buy should not be greater than Total Selling Amount",
                                          };
                                          this.setState({ errors: Errors });
                                        } else {
                                          Errors = { ...Errors, max: "" };
                                          this.setState({ errors: Errors });
                                        }
                                        if (this.state.min) {
                                          if (e.target.value < this.state.min) {
                                            Errors = {
                                              ...Errors,
                                              max: "Maximum Buy must be > Minimum Buy",
                                            };
                                            this.setState({ errors: Errors });
                                          }
                                          if (e.target.value > this.state.min) {
                                            Errors = { ...Errors, min: "" };
                                            this.setState({ errors: Errors });
                                          }
                                        } else {
                                          Errors = { ...Errors, max: "" };
                                          this.setState({ errors: Errors });
                                        }

                                        this.handledisable();
                                      }}
                                      placeholder=""
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.max}
                                  </span>
                                </div>
                              </div>

                              {this.state.isPancake ? (
                                <>
                                  <div className="col-12 col-md-12 mb-3">
                                    <p className="input_desc_sm">
                                      Locking Days
                                    </p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl
                                          id="liquidity"
                                          value={this.state.unlockOn}
                                          onChange={(e) => {
                                            this.setState({
                                              unlockOn: e.target.value,
                                            });
                                            var rx = new RegExp(/^\d+$/);
                                            if (!rx.test(e.target.value)) {
                                              const formvalue = {
                                                ...this.state.errors,
                                                lockingday:
                                                  "Invalid Locking Days !",
                                              };
                                              this.setState({
                                                errors: formvalue,
                                              });
                                            } else if (e.target.value < 30) {
                                              const formvalue = {
                                                ...this.state.errors,
                                                lockingday:
                                                  "Locking Days should be greater than 30 days",
                                              };
                                              this.setState({
                                                errors: formvalue,
                                              });
                                            } else {
                                              const formvalue = {
                                                ...this.state.errors,
                                                lockingday: "",
                                              };
                                              this.setState({
                                                errors: formvalue,
                                              });
                                            }
                                            this.handledisable();
                                          }}
                                          placeholder="0"
                                          aria-describedby="basic-addon2"
                                        />
                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">
                                        {this?.state?.errors?.lockingday}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-12 mb-3">
                                    <p className="input_desc_sm">
                                      Zklaunch Liquidity (51% minimum)
                                    </p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl
                                          id="liquidity"
                                          value={this.state.liquidityPercent}
                                          onChange={(e) => {
                                            if (!isFloat(e.target.value)) {
                                              this.setState({
                                                liquidityPercent:
                                                  e.target.value,
                                              });
                                              if (
                                                isNaN(e.target.value) ||
                                                e.target.value < 51 ||
                                                e.target.value > 100
                                              ) {
                                                const formvalue = {
                                                  ...this.state.errors,
                                                  liquidity:
                                                    "Invalid Zklaunch Liquidity !",
                                                };
                                                this.setState({
                                                  errors: formvalue,
                                                });
                                              } else {
                                                const formvalue = {
                                                  ...this.state.errors,
                                                  liquidity: "",
                                                };
                                                this.setState({
                                                  errors: formvalue,
                                                });
                                              }
                                            }
                                            this.handledisable();
                                          }}
                                          placeholder="0"
                                          aria-describedby="basic-addon2"
                                        />
                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">
                                        {this?.state?.errors?.liquidity}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {this.state.isPancake ? (
                                <div className="col-12 col-md-12 mb-2">
                                  <div className="note_desc mt-1 mb-0">
                                    <p>
                                      Enter the percentage of raised funds that
                                      sholud be allocated to Liquidity on (Min
                                      51%, Max 100%)
                                    </p>
                                    <p>
                                      If I spend 1 BNB on how many tokens will I
                                      receive? Usually the amount is lower than
                                      presale rate to allow for a higher listing
                                      price on
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="col-12 col-md-12 mb-0">
                                <p className="input_desc_sm">
                                  Select Start time & End time (UTC)*
                                </p>
                              </div>
                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">
                                  Start Time (UTC)*
                                </p>
                                <div className="inputs input-groups date_inoput_grps btn_positoned">
                                  <InputGroup className="datepicker_input">
                                    <DatePicker
                                      minDate={new Date()}
                                      filterTime={this.filterPassedTime.bind(
                                        this
                                      )}
                                      showTimeSelect
                                      selected={this.state.startDate}
                                      onChange={(date) => {
                                        this.setState({ startDate: date });
                                        if (
                                          new Date(date).getTime() >=
                                          new Date().getTime()
                                        ) {
                                          let formData = {
                                            ...this.state.errors,
                                            startDate: "",
                                          };
                                          this.setState({ errors: formData });
                                        } else {
                                          let formData = {
                                            ...this.state.errors,
                                            startDate:
                                              "Start Time needs to be after now",
                                          };
                                          this.setState({ errors: formData });
                                        }
                                        if (this.state.endDate) {
                                          if (
                                            Date.parse(date) >
                                            this.state.endDate
                                          ) {
                                            let formData = {
                                              ...this.state.errors,
                                              startDate:
                                                "Start Time must be < End Time",
                                            };
                                            this.setState({ errors: formData });
                                          }
                                          if (
                                            this.state.endDate >
                                            Date.parse(date)
                                          ) {
                                            let formData = {
                                              ...this.state.errors,
                                              endDate: "",
                                            };
                                            this.setState({ errors: formData });
                                          }
                                        }
                                        this.handledisable();
                                      }}
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                    />
                                  </InputGroup>
                                  <InputGroup.Append className="cur_pointer">
                                    <button
                                      variant="outline-secondary"
                                      className="trans_cal_btn"
                                    >
                                      <i class="far fa-calendar-alt"></i>
                                    </button>
                                  </InputGroup.Append>
                                </div>
                                <span className="text-danger f-12 d-block text-left">
                                  {this?.state?.errors?.startDate}
                                </span>
                              </div>
                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">End Time (UTC)*</p>
                                <div className="inputs input-groups date_inoput_grps btn_positoned">
                                  <InputGroup className="datepicker_input">
                                    <DatePicker
                                      minDate={new Date()}
                                      filterTime={this.filterPassedTime.bind(
                                        this
                                      )}
                                      showTimeSelect
                                      selected={this.state.endDate}
                                      onChange={(date) => {
                                        this.setState({ endDate: date });

                                        if (Date.parse(date) > Date.now()) {
                                          let formData = {
                                            ...this.state.errors,
                                            endDate: "",
                                          };
                                          this.setState({ errors: formData });
                                        } else {
                                          let formData = {
                                            ...this.state.errors,
                                            endDate:
                                              "End Time needs to be after now",
                                          };
                                          this.setState({ errors: formData });
                                        }
                                        if (this.state.startDate) {
                                          if (
                                            Date.parse(date) <=
                                            this.state.startDate
                                          ) {
                                            console.log("invalideee");
                                            let formData = {
                                              ...this.state.errors,
                                              endDate:
                                                "End Time must be > Start Time",
                                            };
                                            this.setState({ errors: formData });
                                            console.log("invalideee", formData);
                                            this.forceUpdate();
                                          }
                                          if (
                                            this.state.startDate <
                                            Date.parse(date)
                                          ) {
                                            let formData = {
                                              ...this.state.errors,
                                              startDate: "",
                                            };
                                            this.setState({ errors: formData });
                                            let formdata = {
                                              ...this.state.errors,
                                              endDate: "",
                                            };
                                            this.setState({ errors: formdata });
                                          }
                                        }
                                        this.handledisable();
                                      }}
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                    />
                                  </InputGroup>
                                  <InputGroup.Append className="cur_pointer">
                                    <button
                                      variant="outline-secondary"
                                      className="trans_cal_btn"
                                    >
                                      <i class="far fa-calendar-alt"></i>
                                    </button>
                                  </InputGroup.Append>
                                </div>
                                <span className="text-danger f-12 d-block text-left">
                                  {this?.state?.errors?.endDate}
                                </span>
                              </div>

                              <div className="col-12 col-md-12">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={this.state.isVested}
                                    onChange={(e) =>
                                      this.setState({
                                        isVested: e.target.checked,
                                      })
                                    }
                                    className="custom-control-input"
                                    id="customCheck1"
                                  />
                                  <label
                                    className="custom-control-label"
                                    for="customCheck1"
                                  >
                                    Using Vesting Contributor?
                                  </label>
                                </div>
                              </div>

                              {this.state.isVested ? (
                                <>
                                  <div className="col-12 col-md-12 mb-3 mt-4">
                                    <p className="input_desc_sm">
                                      Vesting Period Days
                                    </p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl
                                          id="liquiditylockingdays"
                                          value={this.state.vestInterval}
                                          onChange={(e) => {
                                            this.setState({
                                              vestInterval: e.target.value,
                                            });
                                            var rx = new RegExp(/^\d+$/);
                                            if (!rx.test(e.target.value)) {
                                              const formvalue = {
                                                ...this.state.errors,
                                                vestinterval:
                                                  "Invalid Vesting Period Days !",
                                              };
                                              this.setState({
                                                errors: formvalue,
                                              });
                                            } else {
                                              const formvalue = {
                                                ...this.state.errors,
                                                vestinterval: "",
                                              };
                                              this.setState({
                                                errors: formvalue,
                                              });
                                            }
                                            this.handledisable();
                                          }}
                                          placeholder=""
                                          aria-describedby="basic-addon2"
                                        />
                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">
                                        {this?.state?.errors?.vestinterval}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-12 mb-3 mt-4">
                                    <p className="input_desc_sm">
                                      Rewards % per Vesting Period
                                    </p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl
                                          id="liquiditylockingdays"
                                          value={this.state.vestPercent}
                                          onChange={(e) => {
                                            if (!isFloat(e.target.value)) {
                                              this.setState({
                                                vestPercent: e.target.value,
                                              });
                                              if (
                                                isNaN(e.target.value) ||
                                                e.target.value < 0 ||
                                                e.target.value > 100
                                              ) {
                                                const formvalue = {
                                                  ...this.state.errors,
                                                  vestpercent:
                                                    "Invalid Rewards % per Vesting Period !",
                                                };
                                                this.setState({
                                                  errors: formvalue,
                                                });
                                              } else {
                                                const formvalue = {
                                                  ...this.state.errors,
                                                  vestpercent: "",
                                                };
                                                this.setState({
                                                  errors: formvalue,
                                                });
                                              }
                                              this.handledisable();
                                            }
                                          }}
                                          placeholder=""
                                          aria-describedby="basic-addon2"
                                        />
                                      </InputGroup>
                                      <span className="text-danger f-12 d-block text-left">
                                        {this?.state?.errors?.vestpercent}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <div className="col-12 col-md-12 text-center mt-3 pb-3">
                                <button
                                  className="get-started-btn mr-2 orbitron_font text-white btn_min_wid"
                                  onClick={() => {
                                    this.setState({ currentStep: 1 });
                                  }}
                                >
                                  Back
                                </button>
                                <button
                                  className="get-started-btn-border orbitron_font btn_min_wid"
                                  onClick={() => {
                                    console.log(
                                      "disable",
                                      this.handledisable()
                                    );
                                    this.validations();
                                    if (this.handledisable()) {
                                    } else {
                                      this.setState({ currentStep: 3 });
                                    }
                                  }}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            id="thirdstep"
                            className={
                              this.state.currentStep == 3 ? "d-block" : "d-none"
                            }
                          >
                            <div className="row">
                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Description</p>
                                <div className="inputs input-groups text_are_grp">
                                  <InputGroup className="">
                                    <textarea
                                      value={this.state.description}
                                      onChange={(e) => {
                                        this.setState({
                                          description: e.target.value,
                                        });
                                        if (!e.target.value) {
                                          const formvalue = {
                                            ...this.state.errors,
                                            description: "Invalid Description!",
                                          };
                                          this.setState({ errors: formvalue });
                                        } else {
                                          const formvalue = {
                                            ...this.state.errors,
                                            description: "",
                                          };
                                          this.setState({ errors: formvalue });
                                        }
                                        // this.handledisableurlbutton({
                                        //     description: e.target.value,
                                        //     logo: this.state.logo,
                                        //     twitter: this.state.twitter,
                                        //     telegram: this.state.telegram
                                        // });
                                      }}
                                      id="description"
                                      rows="3"
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.description}
                                  </span>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-0">
                                <p className="input_desc_sm">Logo URL*</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="file"
                                      value={this.state.logo}
                                      onChange={(e) => {
                                        this.setState({ logo: e.target.value });
                                        if (urlvalidation(e.target.value)) {
                                          const formvalue = {
                                            ...this.state.errors,
                                            logo: "",
                                          };
                                          this.setState({ errors: formvalue });
                                        } else {
                                          const formvalue = {
                                            ...this.state.errors,
                                            logo: "Invalid Logo Url!",
                                          };
                                          this.setState({ errors: formvalue });
                                        }
                                        // this.handledisableurlbutton({
                                        //     description: this.state.description,
                                        //     logo: e.target.value,
                                        //     twitter: this.state.twitter,
                                        //     telegram: this.state.telegram
                                        // });
                                      }}
                                      placeholder="https://zklaunching.com/logo.png"
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.logo}
                                  </span>
                                </div>
                                <div className="note_desc mt-1 mb-0">
                                  <p>
                                    URL must be end with a supported image
                                    extension .png, .jpg,
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-0">
                                <p className="input_desc_sm">Banner URL*</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="file1"
                                      placeholder="https://zklaunching.com/banner.png"
                                      aria-describedby="basic-addon2"
                                      value={this.state.banner}
                                      onChange={(e) => {
                                        this.setState({
                                          banner: e.target.value,
                                        });
                                        if (urlvalidation(e.target.value)) {
                                          const formvalue = {
                                            ...this.state.errors,
                                            banner: "",
                                          };
                                          this.setState({ errors: formvalue });
                                        } else {
                                          const formvalue = {
                                            ...this.state.errors,
                                            banner: "Invalid Banner Url!",
                                          };
                                          this.setState({ errors: formvalue });
                                        }
                                      }}
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.banner}
                                  </span>
                                </div>
                                <div className="note_desc mt-1 mb-0">
                                  <p>
                                    URL must be end with a supported image
                                    extension .png, .jpg,
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Website*</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      value={this.state.website}
                                      onChange={(e) => {
                                        this.setState({
                                          website: e.target.value,
                                        });
                                        if (urlvalidation(e.target.value)) {
                                          const formvalue = {
                                            ...this.state.errors,
                                            website: "",
                                          };
                                          this.setState({ errors: formvalue });
                                        } else {
                                          const formvalue = {
                                            ...this.state.errors,
                                            website: "Invalid Website Url!",
                                          };
                                          this.setState({ errors: formvalue });
                                        }
                                        // this.handledisableurlbutton();
                                      }}
                                      id="logolink1"
                                      placeholder=""
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.website}
                                  </span>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-0">
                                <p className="input_desc_sm">Youtube Video</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="logolinkyoutube"
                                      placeholder="https://youtube.com"
                                      aria-describedby="basic-addon2"
                                      onChange={(e) => {
                                        this.setState({
                                          youtube: e.target.value,
                                        });
                                        if (urlvalidation(e.target.value)) {
                                          const formvalue = {
                                            ...this.state.errors,
                                            youtube: "",
                                          };
                                          this.setState({ errors: formvalue });
                                        } else {
                                          const formvalue = {
                                            ...this.state.errors,
                                            youtube:
                                              "Invalid Youtube Video Url!",
                                          };
                                          this.setState({ errors: formvalue });
                                        }
                                        // this.handledisableurlbutton();
                                      }}
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.youtube}
                                  </span>
                                </div>

                                <div className="note_desc mt-1 mb-0">
                                  <p>Input your youtube URL</p>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Twitter</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      value={this.state.twitter}
                                      onChange={(e) => {
                                        this.setState({
                                          twitter: e.target.value,
                                        });
                                        if (urlvalidation(e.target.value)) {
                                          const formvalue = {
                                            ...this.state.errors,
                                            twitter: "",
                                          };
                                          this.setState({ errors: formvalue });
                                        } else {
                                          const formvalue = {
                                            ...this.state.errors,
                                            twitter: "Invalid Twitter Url!",
                                          };
                                          this.setState({ errors: formvalue });
                                        }
                                        // this.handledisableurlbutton({
                                        //     description: this.state.description,
                                        //     logo: this.state.logo,
                                        //     twitter: e.target.value,
                                        //     telegram: this.state.telegram
                                        // });
                                      }}
                                      id="logolink2"
                                      placeholder="https://twitter.com"
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.twitter}
                                  </span>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Telegram</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      value={this.state.telegram}
                                      onChange={(e) => {
                                        this.setState({
                                          telegram: e.target.value,
                                        });
                                        if (urlvalidation(e.target.value)) {
                                          const formvalue = {
                                            ...this.state.errors,
                                            telegram: "",
                                          };
                                          this.setState({ errors: formvalue });
                                        } else {
                                          const formvalue = {
                                            ...this.state.errors,
                                            telegram: "Invalid Telegram Url!",
                                          };
                                          this.setState({ errors: formvalue });
                                        }
                                        // this.handledisableurlbutton({
                                        //     description: this.state.description,
                                        //     logo: this.state.logo,
                                        //     twitter: this.state.twitter,
                                        //     telegram: e.target.value
                                        // });
                                      }}
                                      id="logolink3"
                                      placeholder="https://telegram.com"
                                      aria-describedby="basic-addon2"
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.telegram}
                                  </span>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Discord</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="logolinkdiscord"
                                      placeholder="https://discord.com"
                                      aria-describedby="basic-addon2"
                                      onChange={(e) => {
                                        this.setState({
                                          discord: e.target.value,
                                        });
                                        // if (urlvalidation(e.target.value)) {
                                        //     const formvalue = { ...this.state.errors, ["discord"]: "" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // else {
                                        //     const formvalue = { ...this.state.errors, ["discord"]: "Invalid Discord Url!" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // this.handledisableurlbutton();
                                      }}
                                    />
                                  </InputGroup>
                                  {/* <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.discord}</span> */}
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Github</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="logolinkgit"
                                      placeholder="https://github.com"
                                      aria-describedby="basic-addon2"
                                      onChange={(e) => {
                                        this.setState({
                                          githup: e.target.value,
                                        });
                                        // if (urlvalidation(e.target.value)) {
                                        //     const formvalue = { ...this.state.errors, ["githup"]: "" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // else {
                                        //     const formvalue = { ...this.state.errors, ["githup"]: "Invalid Github Url!" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // this.handledisableurlbutton();
                                      }}
                                    />
                                  </InputGroup>
                                  <span className="text-danger f-12 d-block text-left">
                                    {this?.state?.errors?.githup}
                                  </span>
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Instagram</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="logolinkinsta"
                                      placeholder="https://instagram.com"
                                      aria-describedby="basic-addon2"
                                      onChange={(e) => {
                                        this.setState({
                                          instagram: e.target.value,
                                        });
                                        // if (urlvalidation(e.target.value)) {
                                        //     const formvalue = { ...this.state.errors, ["instagram"]: "" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // else {
                                        //     const formvalue = { ...this.state.errors, ["instagram"]: "Invalid Instagram Url!" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // this.handledisableurlbutton();
                                      }}
                                    />
                                  </InputGroup>
                                  {/* <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.instagram}</span> */}
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Facebook</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="logolinkfb"
                                      placeholder="https://facebook.com"
                                      aria-describedby="basic-addon2"
                                      onChange={(e) => {
                                        this.setState({
                                          facebook: e.target.value,
                                        });
                                        // if (urlvalidation(e.target.value)) {
                                        //     const formvalue = { ...this.state.errors, ["facebook"]: "" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // else {
                                        //     const formvalue = { ...this.state.errors, ["facebook"]: "Invalid Facebook Url!" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // this.handledisableurlbutton();
                                      }}
                                    />
                                  </InputGroup>
                                  {/* <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.facebook}</span> */}
                                </div>
                              </div>

                              <div className="col-12 col-md-12 mb-3">
                                <p className="input_desc_sm">Reddit</p>
                                <div className="inputs input-groups">
                                  <InputGroup className="">
                                    <FormControl
                                      id="logolinkreddit"
                                      placeholder="https://reddit.com"
                                      aria-describedby="basic-addon2"
                                      onChange={(e) => {
                                        this.setState({
                                          reddit: e.target.value,
                                        });
                                        // if (urlvalidation(e.target.value)) {
                                        //     const formvalue = { ...this.state.errors, ["reddit"]: "" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // else {
                                        //     const formvalue = { ...this.state.errors, ["reddit"]: "Invalid Reddit Url!" };
                                        //     this.setState({ errors: formvalue });
                                        // }
                                        // this.handledisableurlbutton();
                                      }}
                                    />
                                  </InputGroup>
                                  {/* <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.reddit}</span> */}
                                </div>
                              </div>

                              <div className="col-12 col-md-12 text-center mt-3 pb-3">
                                <button
                                  className="get-started-btn mr-2 orbitron_font text-white btn_min_wid"
                                  onClick={() => {
                                    this.setState({ currentStep: 2 });
                                  }}
                                >
                                  Back
                                </button>
                                <button
                                  className="get-started-btn-border orbitron_font btn_min_wid"
                                  onClick={() => {
                                    let isvalid = this.socialMediaValidations();
                                    console.log(
                                      "socialMediaValidations",
                                      isvalid
                                    );
                                    if (isEmpty(isvalid)) {
                                      this.setState({ currentStep: 4 });
                                      this.calculateDepositTokens();
                                    }
                                  }}
                                  // disabled={this.state.button3}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            id="fourthstep"
                            className={
                              this.state.currentStep == 4 ? "d-block" : "d-none"
                            }
                          >
                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"></p>

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">
                                Token Name :
                              </span>
                              <span className="desc_grey_txt font_12">
                                {this.state.tokeninfo?.name
                                  ? this.state.tokeninfo?.name
                                  : ""}
                              </span>
                            </p>

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">
                                Token Symbol :
                              </span>
                              <span className="desc_grey_txt font_12">
                                {this.state.tokeninfo?.symbol
                                  ? this.state.tokeninfo?.symbol
                                  : ""}
                              </span>
                            </p>

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">
                                Token Decimals :
                              </span>
                              <span className="desc_grey_txt font_12">
                                {this.state.tokeninfo?.decimals
                                  ? this.state.tokeninfo?.decimals
                                  : ""}
                              </span>
                            </p>

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">
                                Sale Method :
                              </span>
                              <span className="desc_grey_txt font_12">
                                {this.state.isWhitelisted === false
                                  ? "Public"
                                  : "Private"}
                              </span>
                            </p>

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">Softcap :</span>
                              <span className="desc_grey_txt font_12">
                                {this.state.softCap ? this.state.softCap : " "}{" "}
                                {this.state.currency ? this.state.currency : ""}
                              </span>
                            </p>

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">
                                Minimum Buy :
                              </span>
                              <span className="desc_grey_txt font_12">
                                {this.state.min} {this.state.currency}
                              </span>
                            </p>

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">
                                Maximum Buy :
                              </span>
                              <span className="desc_grey_txt font_12">
                                {this.state.max} {this.state.currency}
                              </span>
                            </p>
                            {this.state.isPancake ? (
                              <>
                                <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                                  <span className="desc_grey_txt">
                                    Liquidity Percentage:
                                  </span>
                                  <span className="desc_grey_txt font_12">
                                    {this.state.liquidityPercent}%
                                  </span>
                                </p>

                                <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                                  <span className="desc_grey_txt">
                                    Liquidity Lockup Time :
                                  </span>
                                  <span className="desc_grey_txt font_12">
                                    {this.state.unlockOn}
                                  </span>
                                </p>
                              </>
                            ) : (
                              <></>
                            )}

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">
                                Start Time :
                              </span>
                              <span className="desc_grey_txt font_12">
                                {this.state.startDate
                                  ? new Date(
                                      this.state.startDate
                                    ).toLocaleDateString() +
                                    "  " +
                                    new Date(this.state.startDate).getHours() +
                                    ":" +
                                    new Date(
                                      this.state.startDate
                                    ).getMinutes() +
                                    ":" +
                                    new Date(
                                      this.state.startDate
                                    ).getSeconds() +
                                    "(GMT)"
                                  : ""}
                              </span>
                            </p>

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">End Time :</span>
                              <span className="desc_grey_txt font_12">
                                {this.state.endDate
                                  ? new Date(
                                      this.state.endDate
                                    ).toLocaleDateString() +
                                    "  " +
                                    new Date(this.state.endDate).getHours() +
                                    ":" +
                                    new Date(this.state.endDate).getMinutes() +
                                    ":" +
                                    new Date(this.state.endDate).getSeconds() +
                                    "(GMT)"
                                  : ""}
                              </span>
                            </p>

                            <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                              <span className="desc_grey_txt">Website :</span>
                              <span className="desc_grey_txt font_12">
                                <a
                                  href={this.state.website}
                                  target="_blank"
                                  className="link_grn_new link_brk_word"
                                  rel="noreferrer"
                                >
                                  {this.state.website ? this.state.website : ""}
                                </a>
                              </span>
                            </p>

                            <div className="mb-4 mt-4">
                              <div className="card_footer_form">
                                <div className="d-flex align-items-center justify-content-center">
                                  <i
                                    class="fa fa-exclamation-circle text-danger-war"
                                    aria-hidden="true"
                                  ></i>
                                  <p className="mb-0 pl-3">
                                    For tokens with burns, rebase or other
                                    special transfers please ensure that you
                                    have a way to whitelist multiple addresses
                                    or turn off the special transfer events (By
                                    setting fees to 0 for example for the
                                    duration of the presale)
                                  </p>
                                </div>
                              </div>
                            </div>

                            {this.state.deposit > 0 ? (
                              <div className="col-12">
                                <div className="note_desc mt-1 mb-1 text-center">
                                  <p>
                                    Need{" "}
                                    {parseFloat(this.state.deposit)?.toFixed(2)}{" "}
                                    {this.state.name} to create launchpad.
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="col-12 col-md-12 text-center mt-3 pb-3">
                              <button
                                className="get-started-btn mr-2 orbitron_font text-white btn_min_wid"
                                onClick={() => {
                                  this.setState({ currentStep: 3 });
                                }}
                              >
                                Back
                              </button>

                              {!this.state.isDeposited ? (
                                this.state.createdSale == "" ? (
                                  <a
                                    onClick={() => this.CreateSale()}
                                    className="get-started-btn-border orbitron_font text-white btn_min_wid"
                                  >
                                    Create
                                  </a>
                                ) : (
                                  this.renderDeposit()
                                )
                              ) : (
                                <a
                                  onClick={this.goToSale.bind(this)}
                                  className="get-started-btn-border orbitron_font text-white btn_min_wid"
                                >
                                  Proceed
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <div className="card_bg_steps h-100">
              <div>
                <div className="card_step">
                  <div
                    className={
                      this.state.currentStep == 1
                        ? "card_bg_wizard active"
                        : "card_bg_wizard"
                    }
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-start h-100">
                        <div className="wizard_badge_num"></div>
                        <div className="pl-3 create_wiz_desc">
                          <h5>Verify Token</h5>
                          <p className="mb-0">
                            Enter the token address and verify
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card_step">
                  <div
                    className={
                      this.state.currentStep == 2
                        ? "card_bg_wizard active"
                        : "card_bg_wizard"
                    }
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-start h-100">
                        <div className="wizard_badge_num"></div>
                        <div className="pl-3 create_wiz_desc">
                          <h5>Defi Launchpad Info</h5>
                          <p className="mb-0">
                            Enter the launchpad information that you want to
                            raise, that should be enter all details about your
                            presale
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card_step">
                  <div
                    className={
                      this.state.currentStep == 3
                        ? "card_bg_wizard active"
                        : "card_bg_wizard"
                    }
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-start h-100">
                        <div className="wizard_badge_num"></div>
                        <div className="pl-3 create_wiz_desc">
                          <h5>Add Additional Info</h5>
                          <p className="mb-0">Let people know who you are</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card_step">
                  <div
                    className={
                      this.state.currentStep == 4
                        ? "card_bg_wizard active"
                        : "card_bg_wizard"
                    }
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-start h-100">
                        <div className="wizard_badge_num"></div>
                        <div className="pl-3 create_wiz_desc">
                          <h5>Finish</h5>
                          <p className="mb-0">Review your information</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-3 bottom_text px-4 pb-4">
                Disclaimer: The information provided shall not in any way
                constitute a recomendation as to whether you should invest in
                any product discussed. We accept no liability for any loss
                occasioned to any person acting or refraining from action as a
                result of any material provided or published.
              </p>
            </div>
          </div>
        </div>

        {tokenModal && (
          <TokenModal
            connect={"string"}
            address={(val) => this.settokenaddress(val)}
            onDismiss={() => this.onDismiss()}
          />
        )}
      </div>
    );
  }
}

export default CreatetabFair;
