import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Trendingslider from '../trendingslider';

import Buycard from "./Buycard";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Web3 from "web3";
import '../../css/styles.css';

import coin from "../../images/coin.png"
import favicon from "../../images/Bitdeal/logo_curve.png"

import img1 from "../../images/img1.jpg";
import img2 from "../../images/img2.jpg";
import banner from "../../images/img2.jpg";




import loader from "../../images/loader.gif"
import { getlaunchpaddatalimit, getLaunchpadsaleCardLimit, getmycontributiondatalimit, getPrivatesaleCardLimit, getSaleCards, getSaleCardsLimit, getsaledatalimit, getTotalSalesInfo, searchCards, searchSale } from '../../hooks/useProjects';
import { Dropdown, Nav, Tab, Row, Col, Container, ProgressBar, InputGroup, Form, Alert } from 'react-bootstrap';
import { CHAINS } from '../../config/env'
import { getAccount, getChainId, setChainId } from '../../hooks/useAccount';
import { gettrendingdisplayhook, getusecreatedlaunchhook, getuserdatahook } from '../../hooks/usebackend';
import { privatesalefilterby, privatesalesortby, tokenfilter, tokenfilterlaunchpad } from '../../hooks/filter';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import bgstyle2 from "../../images/bg_style2.png";

import bgstyle from "../../images/bg_style.png";

import bgoutline1 from "../../images/bg_outline1.png";
import whiteoutline1 from "../../images/outline-white1.png";

import bgoutline from "../../images/bg_outline.png";
import whiteoutline from "../../images/outline-white.png";
import bgoutline2 from "../../images/bg_outline2.png";
import whiteoutline2 from "../../images/outline-white2.png";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    AUDIT
  </Tooltip>
);


const renderTooltip1 = (props) => (
  <Tooltip id="button-tooltip1" {...props}>
    DOXX
  </Tooltip>
);

const renderTooltip2 = (props) => (
  <Tooltip id="button-tooltip2" {...props}>
    SAFU
  </Tooltip>
);

const renderTooltip3 = (props) => (
  <Tooltip id="button-tooltip3" {...props}>
    KYC
  </Tooltip>
);
class LaunchpadList extends Component {



  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  closeAllMenusStatus() {
    this.setState({ closeKycmenu: true });
    this.setState({ closeHcmenu: true });
    this.setState({ closeCoinmenu: true });
  }

  closeAllMenusKyc() {
    this.setState({ closeStatusmenu: true });
    this.setState({ closeHcmenu: true });
    this.setState({ closeCoinmenu: true });
  }

  closeAllMenusCoin() {
    this.setState({ closeStatusmenu: true });
    this.setState({ closeHcmenu: true });
    this.setState({ closeKycmenu: true });
  }


  closeAllMenusHc() {
    this.setState({ closeStatusmenu: true });
    this.setState({ closeCoinmenu: true });
    this.setState({ closeKycmenu: true });
  }





  componentDidMount() {
    this.hideLoader();
    document.getElementById("launchpad_parent").classList.add("active");
    this.setState({ currentChain: getChainId() });
    this.gettrending();
    this.getuserdata();
    this.loadData();
  }

  async gettrending() {
    let data = await gettrendingdisplayhook();
    console.log("dataa trending", data?.data?.data);
    if (data?.data?.data?.length > 0)
      this.setState({ trending: data?.data?.data })
  }

  getuserdata = async () => {
    const totalsale = await getTotalSalesInfo();
    let userdata = await getuserdatahook(this.state.accountInfo && this.state.accountInfo?.toLowerCase());
    console.log("userdata", userdata?.data?.data);
    let totalproxydata = userdata?.data?.data?.launchpad;
    const newly = totalproxydata?.filter(data => data?.iswhitelist == false || data?.iswhitelist == "false")
    let newarray = [];
    newly?.map((val, i) => {
      let newvalue = totalsale?.find((e) => e._sale == val?.saleaddress);
      if (newvalue !== undefined) {
        newarray.push(newvalue)
      }
    })
    console.log("userdatanewly", newly, newarray);
    this.setState({ mycontribution: newarray.reverse() })
  }



  constructor(props) {
    super(props);
    this.state = {
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: '',
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      closeHcmenu: false,
      closeCoinmenu: false,
      closeKycmenu: false,
      closeStatusmenu: false,
      isuser: false,
      button: true,
      mycontribution: [],
      accountInfo: getAccount(),
      interval: 0,
      loadinterval: 3,
      original: [],
      filterdata: "All Status",
      sortdata: "No Filter",
      coin: [],
      status: [],
      type: [],
      trending: [],
      dummylaunchpad: [],
      iscoming: false,
      isended: false,
      options: {
        items: 1,
        loop: true,
        nav: true,
      }


    };
  }



  sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

  

  async loadData() {
    this.showLoader();
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata);
    const totalproxydata = Proxydata?.filter(data => data?._isWhitelisted == false)
    let trenddata = await gettrendingdisplayhook();
    console.log("dataa trending", trenddata?.data?.data);
    if (trenddata?.data?.data?.length > 0)
      this.setState({ trending: trenddata?.data?.data })
    var trending = trenddata?.data?.data
    console.log("trendingdataaaaaaaaaaaaaa", trending);
    let newtotalproxy = [];
    trending?.map((val, i) => {
      let singledata = totalproxydata.find(e => e?._sale == val.saleaddress);
      if (singledata) {
        newtotalproxy.push(singledata)
      }
    })



    totalproxydata?.map((datas, i) => {
      let singlesale = newtotalproxy?.find(e => e._sale == datas?._sale);
      if (!singlesale) {
        newtotalproxy.push(datas)
      }
    })
    this.setState({ proxy: newtotalproxy })
    console.log("trendingdataaaaaaaaaaaaaa", newtotalproxy);
    console.log("total proxy data", totalproxydata);
    this.setState({ original: newtotalproxy })
    const total = await getlaunchpaddatalimit(newtotalproxy, 0, this.state.loadinterval);
    console.log("total>>>", total);
    
    this.setState({ 'totalSales': total });
    this.setState({ interval: this.state.loadinterval });
    this.hideLoader();
  }

  async loadMore() {
    console.log("Lad moreee", this.state.totalSales.length < this.state.proxy.length)
    this.setState({ isLoading: true });
    const newly = await getlaunchpaddatalimit(this.state.proxy, this.state.interval, this.state.interval + this.state.loadInterval);
    console.log("newly ", newly);
    const total = this.state.totalSales.concat(newly);
    this.setState({ totalSales: total, interval: this.state.interval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }

  async loadmycontribution() {
    this.showLoader()
    this.setState({ proxy: this.state.mycontribution })
    const newly = await getmycontributiondatalimit(this.state.mycontribution, 0, this.state.loadInterval);
    console.log("newly ", newly);
    this.setState({ totalSales: newly });
    this.hideLoader();
  }

 

  async loadMoremycontribution() {
    console.log("Lad more", this.state.totalSales.length < this.state.proxy.length)
    this.setState({ isLoading: true });
    const newly = await getmycontributiondatalimit(this.state.mycontribution, this.state.interval, this.state.interval + this.state.loadInterval);
    console.log("newly ", newly);
    const total = this.state.totalSales.concat(newly);
   
    this.setState({ totalSales: total, interval: this.state.interval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }

  async loadSearchMore() {
    console.log("Lad more", this.state.totalSales.length < this.state.proxy.length)
    this.setState({ isLoading: true });
    const newly = await getlaunchpaddatalimit(this.state.searchProxy, this.state.searchInterval, this.state.searchInterval + this.state.loadInterval);
    const total = this.state.searchSale.concat(newly);
    await this.sleep(1000)
    this.setState({ searchSale: total, searchInterval: this.state.searchInterval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }

  async handleChange(e) {
    if ((e.target.value).length > 0) {
      if (e.key === 'Enter') {
        console.log("Search : ", e.target.value)
        this.showLoader();
        const searchS = await searchCards(this.state.proxy, e.target.value);
        this.setState({ searchProxy: searchS });
        const data = await getlaunchpaddatalimit(searchS, 0, this.state.searchInterval);
        await this.sleep(1050)
        console.log("Search sale : ", searchS)
        this.setState({ searchSale: data, isSearch: true })
        this.hideLoader();
      }
    } else {
      this.setState({ isSearch: false })
    }

  }

  async nouser() {
    this.setState({ isuser: false });
    this.setState({ interval: 0 });
    this.setState({ searchInterval: 3 });
    this.setState({
      filterdata: "All Status",
      sortdata: "No Filter",
    })
    await this.loadData()
  }

  async yesuser() {
    this.setState({ isuser: true });
    this.setState({ interval: 3 });
    this.setState({ searchInterval: 3 });
    this.setState({
      filterdata: "All Status",
      sortdata: "No Filter",
    })
    await this.loadmycontribution()

  }

  async handlefilter(val) {
    if (val == "All Status") {
      this.setState({ filterdata: val });
      this.setState({ button: true })
      this.loadData();
    }
    else {
      this.showLoader();
      this.setState({ button: true })
      this.setState({ filterdata: val });
      var filtered = await privatesalefilterby(this.state.original, val, this.state.isuser);
      console.log("filtered", filtered);
      this.setState({ proxy: filtered })
      if (filtered.length > 0) {
        const total = await getlaunchpaddatalimit(filtered, 0, this.state.loadInterval);
        console.log("button ", filtered?.length, total);

        if (filtered?.length == 0) {
          this.setState({ totalSales: filtered })
        }
        await this.sleep(1000)
        this.setState({ totalSales: total });
        this.setState({ interval: 3 });
        this.hideLoader();
      }
      else {
        this.setState({ totalSales: filtered });
        this.hideLoader();
      }
    }
  }

  async handlesort(val) {
    if (val == "No Filter") {
      this.setState({ button: true })
      this.loadData();
      this.setState({ sortdata: val })
    }
    else {
      this.showLoader();
      this.setState({ sortdata: val })
      this.setState({ button: true });
      var sorted = await privatesalesortby(this.state.original, val, this.state.isuser);
      console.log("sorted", sorted);
      this.setState({ proxy: sorted });
      if (sorted.length > 0) {
        const total = await getLaunchpadsaleCardLimit(sorted, 0, this.state.interval, this.state.isuser);
        console.log("button ", sorted?.length, total);

        if (sorted?.length == total.index || !total) {
          this.setState({ button: false })
        }
        await this.sleep(1000)
        this.setState({ totalSales: total.saleInfoCards });
        this.setState({ interval: total.index + 1 });
        this.hideLoader();
      }
      else {
        this.setState({ button: false })
        this.setState({ totalSales: sorted });
        this.hideLoader();
      }
    }
  }

  async handlecoin(val) {
    var coins = this.state.coin
    if (coins.length == 0) {
      coins.push(val);
    }
    else {
      if (coins.includes(val)) {
        const index = coins.indexOf(val);
        if (index > -1) {
          coins.splice(index, 1);
        }
      }
      else {
        coins.push(val)
      }
    }
    console.log("coin", coins);
    this.setState({ coin: coins })
  }

  async handlestatus(val) {
    var statuss = this.state.status
    if (statuss.length == 0) {
      statuss.push(val);
    }
    else {
      if (statuss.includes(val)) {
        const index = statuss.indexOf(val);
        if (index > -1) {
          statuss.splice(index, 1);
        }
      }
      else {
        statuss.push(val)
      }
    }
    console.log("status", statuss);
    this.setState({ status: statuss })
  }

  async handletype(val) {
    var statuss = this.state.type
    if (statuss.length == 0) {
      statuss.push(val);
    }
    else {
      if (statuss.includes(val)) {
        const index = statuss.indexOf(val);
        if (index > -1) {
          statuss.splice(index, 1);
        }
      }
      else {
        statuss.push(val)
      }
    }
    console.log("status", statuss);
    this.setState({ type: statuss })
  }

  async handleadvancefilter() {
    this.showLoader();
    if (this.state.coin.length == 0 && this.state.status.length == 0 && this.state.type.length == 0) {
      this.loadData();
    }
    else {
      let filtered = await tokenfilterlaunchpad(this.state.proxy, this.state.coin, this.state.status, this.state.type);
      this.setState({ proxy: filtered })
      console.log("filtered", filtered);
      if (filtered.length > 0) {
        const total = await getlaunchpaddatalimit(filtered, 0, this.state.interval);
        console.log("button ", filtered?.length, total);
     
        await this.sleep(1000)
        this.setState({ totalSales: total });
        this.setState({ interval: 3 });
        this.hideLoader();
      }
      else {
        this.setState({ button: false })
        this.setState({ totalSales: filtered });
        this.hideLoader();
      }
    }
    this.hideLoader();
  }


  render() {
    const location = this.props.location.pathname.split('/')[1];
    const { totalSales, onGoingSales, upComingSales, search, isSearch, searchSale, accountInfo } = this.state


    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
          <div className="whole_bg">

            <Header onUpdate={this.loadData.bind(this)} />

            <div className="whole_sec pb-5">
              <div className='flex_side_right'>
                <Sidebar />
                <div className='right_side_sec'>
                 
                  <Trendingslider />
                  <div className="right_side_spacing pt-5">

                    <div className='carousel_div'>
                      <OwlCarousel className='owl-theme' loop margin={10} nav items={1}>
                        <div class='item'>
                          <div className='row align-items-center'>
                            <div className='col-12 col-md-6'>

                              <div className='carousel_banner'>
                                <img src={banner} className='img-fluid' />
                              </div>

                            </div>
                            <div className='col-12 col-md-6 mt-4 mt-md-0'>

                              <span className="badge_live">

                                <span className='stats_txt'>Live</span>
                              </span>

                              <h3 className="h3_res mt-3">Zklaunch Project</h3>

                              <p className="title_span mt-4">
                                Introducing the very first launchpad on the Linea chain - Zklaunch. Zklaunch helps everyone to create their own tokens and token sales in new seconds. Tokens created on Zklaunch will be verified and published on explore website.
                              </p>
                              <div className='mb-4'>
                                <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                  <i class="fa fa-globe" aria-hidden="true"></i>
                                </a>

                                <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                  <i class="fa fa-twitter" aria-hidden="true"></i>
                                </a>

                                <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                </a>
                              </div>
                              <Link to="/"
                                className="notchedButton position-relative"
                              >
                                <span className="notchedBg"></span>
                                <div className="content">View Detail</div>
                              </Link>


                            </div>
                          </div>
                        </div>

                        <div class='item'>
                          <div className='row align-items-center'>
                            <div className='col-12 col-md-6'>

                              <div className='carousel_banner'>
                                <img src={img1} className='img-fluid' />
                              </div>

                            </div>
                            <div className='col-12 col-md-6 mt-4 mt-md-0'>

                              <span className="badge_finish">

                                <span className='stats_txt'>End</span>
                              </span>

                              <h3 className="h3_res mt-3">Zklaunch Project</h3>

                              <p className="title_span mt-4">
                                Introducing the very first launchpad on the Linea chain - Zklaunch. Zklaunch helps everyone to create their own tokens and token sales in new seconds. Tokens created on Zklaunch will be verified and published on explore website.
                              </p>
                              <div className='mb-4'>
                                <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                  <i class="fa fa-globe" aria-hidden="true"></i>
                                </a>

                                <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                  <i class="fa fa-twitter" aria-hidden="true"></i>
                                </a>

                                <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                </a>
                              </div>
                              <Link to="/"
                                className="notchedButton position-relative"
                              >
                                <span className="notchedBg"></span>
                                <div className="content">View Detail</div>
                              </Link>


                            </div>
                          </div>
                        </div>

                        <div class='item'>
                          <div className='row align-items-center'>
                            <div className='col-12 col-md-6'>

                              <div className='carousel_banner'>
                                <img src={img2} className='img-fluid' />
                              </div>

                            </div>
                            <div className='col-12 col-md-6 mt-4 mt-md-0'>

                              <span className="badge_upcoming">

                                <span className='stats_txt'>Coming</span>
                              </span>

                              <h3 className="h3_res mt-3">Zklaunch Project</h3>

                              <p className="title_span mt-4">
                                Introducing the very first launchpad on the Linea chain - Zklaunch. Zklaunch helps everyone to create their own tokens and token sales in new seconds. Tokens created on Zklaunch will be verified and published on explore website.
                              </p>
                              <div className='mb-4'>
                                <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                  <i class="fa fa-globe" aria-hidden="true"></i>
                                </a>

                                <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                  <i class="fa fa-twitter" aria-hidden="true"></i>
                                </a>

                                <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                </a>
                              </div>
                              <Link to="/"
                                className="notchedButton position-relative"
                              >
                                <span className="notchedBg"></span>
                                <div className="content">View Detail</div>
                              </Link>


                            </div>
                          </div>
                        </div>

                      </OwlCarousel>

                    </div>
                  </div>
                  <div className="bg_layer mb-0 mt-4">
                    <div className="right_side_spacing py-5">

                      <div className="row">

                        <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                          <h3 className="h3_res">Premium Launch Order</h3>
                          <h5 className="h5_res">All in one package </h5>
                          <div className='mt-4 pb-4 div_btn_ful_width'>
                            <button className='get-started-btn orbitron_font text-white'>
                              Contact Agent
                            </button>
                            <button className='get-started-btn-border orbitron_font ml-3'>
                              Learn Premium
                            </button>
                          </div>

                        </div>
                        <div className="col-12 col-md-5 col-xl-6">
                          <p className="title_span mt-4">Ultimate guideline for launching with 1 v 1 support during the process. Instructing project from technical to marketing/branding aspect for the best outcome.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right_side_spacing pb-5">
                    <div className='ongoing_sec proj_bg pb-5'>
                      <div className="container px-1">
                        <div className='top_heqad_sec private_sec_bg mt-5 mt-md-0'>
                          <div className="bg_outline1">
                      
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-12 flex_cen_col mt-md-5 mb-md-0">
                              <p className="overlay_card_head font_30">Launchpad List</p>


                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="inner_bg mt-0">
                        <div className="container px-1">
                          <Tab.Container defaultActiveKey="first">
                            <Row className="container-fluid w-100 mx-0 px-0">
                              <Col md={6} lg={5} className="px-0">
                                <Nav variant="pills" className="nav_green">
                                  <Nav.Item>
                                    <Nav.Link eventKey="first" id="first">
                                      <p className='mb-0'
                                        onClick={() => { this.nouser() }}
                                      >All</p>

                                    </Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item>
                                    <Nav.Link eventKey="second" id="second">
                                      <p className='mb-0'
                                        onClick={() => { this.nouser() }}
                                      >Advance</p>

                                    </Nav.Link>

                                  </Nav.Item>

                                  <Nav.Item>
                                    <Nav.Link eventKey="third" id="third">
                                      <p className='mb-0'
                                        onClick={() => {
                                          this.yesuser();

                                        }}
                                      >My Contributions</p>

                                    </Nav.Link>

                                  </Nav.Item>

                                </Nav>
                              </Col>
                              <Col md={6} lg={7} className="px-0 flex_end_input mt-3 mt-md-0">
                                <div className='d-flex'>
                                  <div className="contract position-relative">
                                    <span className="contractbg position-absolute">
                                    </span>
                                    <div className="name position-relative d-flex align-items-center justify-content-center text-center">Search Token</div>
                                  </div>
                                  <div className="borderGradient d-flex align-items-center">


                                    <input className='form-control' id='myInput' value={search} type="text" onChange={(e) => { this.setState({ search: e.target.value }); this.handleChange(e); }} onKeyDown={(e) => this.handleChange(e)} placeholder="" aria-label="Search" />


                                  </div>
                                </div>
                              </Col>

                              <Col lg={12} className="img_center_lg px-0">
                                <div className='container container_custom px-0'>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="first">

                                      {totalSales.length > 0 ?
                                        <>
                                          <div className='row mt-5'>

                                            {!isSearch ?
                                              (totalSales && totalSales.map((index) =>
                                                <Buycard saleData={index} user={true} connect={"string"} />
                                              )) :
                                              (searchSale && searchSale.map((index) =>
                                                <Buycard saleData={index} user={true} connect={"string"} />
                                              ))
                                            }
                                          </div>
                                          <div className='text-center mt-3'>
                                            

                                            {isSearch ?
                                              <>
                                                {searchSale && searchSale.length < this.state.searchProxy.length ?

                                                  <button className="get-started-btn orbitron_font" onClick={this.loadSearchMore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button>
                                                  : <></>
                                                }
                                              </> : <>
                                                {totalSales && totalSales.length < this.state.proxy.length ?
                                                  <button className="get-started-btn orbitron_font" onClick={this.loadMore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button>
                                                  : <></>
                                                }
                                              </>
                                            }

                                         

                                          </div>
                                        </> :
                                        <div className='row mt-5'>
                                          <div className='col-12'>
                                            <p className='no_data_txt'>No Data</p>
                                          </div>
                                        </div>
                                      }
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">


                                      <div class="table-responsive adv_tab mt-4">
                                        <table className="table table_poricat_list">
                                          <tr>
                                            <th>
                                              Name</th>

                                            <th>Hardcap</th>
                                            <th>Initialcap</th>


                                            <th>Badge</th>
                                            <th>Status</th>
                                            <th>Links</th>
                                            <th>TGOnline</th>
                                            <th>
                                              Countdown
                                            </th>
                                            <th>Ends In</th>
                                            <th></th>
                                          </tr>


                                          {!isSearch ?
                                            (totalSales && totalSales.map((data) => <>
                                              {console.log("index", data)}
                                              <tr>
                                                <td><span className="d-flex align-items-center">

                                                  <div className="relative_token_div  mr-2">
                                                    <img className="token_image_sm " src={data?.logo} />
                                                  </div>
                                                  {data?.name}
                                                </span></td>
                                                <td>

                                                  <p className='mb-1'>
                                                    {data?.softCap / 10 ** 18}
                                                  </p>
                                                  <p className='mb-0'>
                                                    ZKETH
                                                  </p>
                                                </td>
                                                <td>
                                                  <p className='mb-1'>{data?.hardCap / 10 ** 18}</p>
                                                  <p className='mb-0'>
                                                    ZKETH
                                                  </p>
                                                </td>
                                                <td>
                                                  <div className='grad_badge'>
                                                    <OverlayTrigger
                                                      placement="top"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip}
                                                    >
                                                      <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                        A
                                                      </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                      placement="top"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip1}
                                                    >
                                                      <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                        D
                                                      </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                      placement="top"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip2}
                                                    >

                                                      <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                        S
                                                      </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                      placement="top"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip3}
                                                    >
                                                      <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                        K
                                                      </span>
                                                    </OverlayTrigger>
                                                  </div>
                                                </td>

                                                <td>
                                                  {console.log("parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)", data?.earnedCap, data?.softCap, data?.symbol, parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100))}

                                                  {data?.LaunchpadType ? <div className='progress_div_pos'><ProgressBar now={data && parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)} className={this.state.iscoming ? 'grey_bar mt-0' : this.state.isended ? 'red_bar mt-0' : 'yellow_bar mt-0'} />

                                                    {this.state.iscoming ?
                                                      <p className='desc_grey_txt abs_per_div'>Coming</p>
                                                      :
                                                      (this.state.isended ?
                                                        <p className='desc_grey_txt abs_per_div'>Ended</p>
                                                        :

                                                        <div className="d-flex align-items-center justify-content-center">
                                                          <span className='desc_grey_txt abs_per_div'>{((data?.earnedCap) / 10 ** data?.decimals).toFixed(3)}/{((data?.softCap) / 10 ** data?.decimals).toFixed(2)} </span>
                                                        </div>
                                                      )}

                                                  </div>
                                                    :
                                                    <div className='progress_div_pos'><ProgressBar now={data && parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)} className={this.state.iscoming ? 'grey_bar mt-0' : this.state.isended ? 'red_bar mt-0' : 'yellow_bar mt-0'} />
                                                      {this.state.iscoming ?
                                                        <p className='desc_grey_txt abs_per_div'>Coming</p>
                                                        :
                                                        (this.state.isended ?
                                                          <p className='desc_grey_txt abs_per_div'>Ended</p>
                                                          :

                                                          <div className="d-flex align-items-center justify-content-center">
                                                            <span className='desc_grey_txt abs_per_div'>{data && ((data.earnedCap) / 10 ** data?.decimals).toFixed(3)} / {(parseInt(data?.softCap) / 10 ** data?.decimals).toFixed(2)} {this.state.buyToken} </span>
                                                          </div>)
                                                      }

                                                    </div>

                                                  }
                                                </td>
                                                <td>
                                                  <div>
                                                    <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                                      <i class="fa fa-globe" aria-hidden="true"></i>
                                                    </a>

                                                    <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                                      <i class="fa fa-twitter" aria-hidden="true"></i>
                                                    </a>

                                                    <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                                      <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                    </a>
                                                  </div>
                                                </td>
                                                <td>
                                                  12/376
                                                </td>
                                                <td className="table_time">
                                                  {
                                                    
                                                    new Date(data?.startTime * 1000)?.getHours() + ":" +
                                                    new Date(data?.startTime * 1000)?.getMinutes() + ":" + new Date(data?.startTime * 1000)?.getSeconds()}</td>
                                                <td className="table_time">{
                                              
                                                  new Date(data?.endTime * 1000)?.getHours() + ":" +
                                                  new Date(data?.endTime * 1000)?.getMinutes() + ":" + new Date(data?.endTime * 1000)?.getSeconds()}</td>

                                          
                                                <td><a className="link_text_white" href={`/launchpaddetail/${data.saleAddress}`}>Detail</a></td>
                                              </tr>
                                            </>

                                            )) :
                                            (searchSale && searchSale.map((data) => <>
                                              <tr>
                                                <td><span className="d-flex align-items-center">

                                                  <div className="relative_token_div  mr-2">
                                                    <img className="token_image_sm " src={data?.logo} />
                                                  </div>
                                                  {data?.name}
                                                </span></td>
                                                <td>

                                                  <p className='mb-1'>
                                                    {data?.softCap / 10 ** 18}
                                                  </p>
                                                  <p className='mb-0'>
                                                    ZKETH
                                                  </p>
                                                </td>
                                                <td>
                                                  <p className='mb-1'>{data?.hardCap / 10 ** 18}</p>
                                                  <p className='mb-0'>
                                                    ZKETH
                                                  </p>
                                                </td>
                                                <td>
                                                  <div className='grad_badge'>
                                                    <OverlayTrigger
                                                      placement="right"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip}
                                                    >
                                                      <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                        A
                                                      </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                      placement="right"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip1}
                                                    >
                                                      <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                        D
                                                      </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                      placement="right"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip2}
                                                    >

                                                      <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                        S
                                                      </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                      placement="right"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip3}
                                                    >
                                                      <span className='btn_social_new btn_social_new_a_new ml-1'>
                                                        K
                                                      </span>
                                                    </OverlayTrigger>
                                                  </div>
                                                </td>

                                                <td>
                                                  {console.log("parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)", data?.earnedCap, data?.softCap, data?.symbol, parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100))}

                                                  {data?.LaunchpadType ? <div className='progress_div_pos'><ProgressBar now={data && parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)} className={this.state.iscoming ? 'grey_bar mt-0' : this.state.iscoming ? 'red_bar mt-0' : 'yellow_bar mt-0'} />

                                                    {this.state.iscoming ?
                                                      <p className='desc_grey_txt abs_per_div'>Coming</p>
                                                      :
                                                      (this.state.isended ?
                                                        <p className='desc_grey_txt abs_per_div'>Ended</p>
                                                        :

                                                        <div className="d-flex align-items-center justify-content-center">
                                                          <span className='desc_grey_txt abs_per_div'>{((data?.earnedCap) / 10 ** data?.decimals).toFixed(3)}/{((data?.softCap) / 10 ** data?.decimals).toFixed(2)} </span>
                                                        </div>
                                                      )}

                                                  </div>
                                                    :
                                                    <div className='progress_div_pos'><ProgressBar now={data && parseInt(parseInt(data?.earnedCap) / parseInt(data?.softCap) * 100)} className={this.state.iscoming ? 'grey_bar mt-0' : this.state.iscoming ? 'red_bar mt-0' : 'yellow_bar mt-0'} />
                                                      {this.state.iscoming ?
                                                        <p className='desc_grey_txt abs_per_div'>Coming</p>
                                                        :
                                                        (this.state.isended ?
                                                          <p className='desc_grey_txt abs_per_div'>Ended</p>
                                                          :

                                                          <div className="d-flex align-items-center justify-content-center">
                                                            <span className='desc_grey_txt abs_per_div'>{data && ((data.earnedCap) / 10 ** data?.decimals).toFixed(3)} / {(parseInt(data?.softCap) / 10 ** data?.decimals).toFixed(2)} {this.state.buyToken} </span>
                                                          </div>)
                                                      }

                                                    </div>

                                                  }
                                                </td>
                                                <td>
                                                  <div>
                                                    <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                                      <i class="fa fa-globe" aria-hidden="true"></i>
                                                    </a>

                                                    <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                                      <i class="fa fa-twitter" aria-hidden="true"></i>
                                                    </a>

                                                    <a target="_blank" href="#" className='btn_social_new btn_social_new_a_new ml-1'>
                                                      <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                    </a>
                                                  </div>
                                                </td>
                                                <td>
                                                  12/376
                                                </td>
                                                <td className="table_time">
                                                  {
                                                    
                                                    new Date(data?.startTime * 1000)?.getHours() + ":" +
                                                    new Date(data?.startTime * 1000)?.getMinutes() + ":" + new Date(data?.startTime * 1000)?.getSeconds()}</td>
                                                <td className="table_time">{
                                                 
                                                  new Date(data?.endTime * 1000)?.getHours() + ":" +
                                                  new Date(data?.endTime * 1000)?.getMinutes() + ":" + new Date(data?.endTime * 1000)?.getSeconds()}</td>

                                             

                                                <td><a className="link_text_white" href={`/launchpaddetail/${data.saleAddress}`}>Detail</a></td>
                                              </tr>
                                            </>

                                            ))
                                          }
                                        </table>
                                      </div>
                                      {totalSales.length > 0 ?

                                        <>
                                          <div className='text-center mt-5'>
                                            {isSearch ?
                                              <>
                                                {searchSale && searchSale.length < this.state.searchProxy.length ?

                                                  <button className="get-started-btn orbitron_font" onClick={this.loadSearchMore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button>
                                                  : <></>
                                                }
                                              </> : <>
                                                {totalSales && totalSales.length < this.state.proxy.length ?
                                                  <button className="get-started-btn orbitron_font" onClick={this.loadMore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button>
                                                  : <></>
                                                }
                                              </>
                                            }

                                          </div>
                                        </> : <div className='row mt-5'>
                                          <div className='col-12'>
                                            <p className='no_data_txt'>No Data</p>
                                          </div>
                                        </div>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                      <>
                                        {totalSales.length > 0 ?
                                          <>
                                            <div className='row mt-5'>

                                              {!isSearch ?
                                                (totalSales && totalSales.map((index) =>
                                                  <Buycard saleData={index} user={false} connect={"string"} />
                                                )) :
                                                (searchSale && searchSale.map((index) =>
                                                  <Buycard saleData={index} user={false} connect={"string"} />
                                                ))
                                              }
                                            </div>
                                            <div className='text-center mt-3'>


                                              {isSearch ?
                                                <>
                                                  {searchSale && searchSale.length < this.state.searchProxy.length ?

                                                    <button className="get-started-btn orbitron_font" onClick={this.loadSearchMore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button>
                                                    : <></>
                                                  }
                                                </> : <>
                                                  {totalSales && totalSales.length < this.state.proxy.length ?
                                                    <button className="get-started-btn orbitron_font" onClick={this.loadMoremycontribution.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button>
                                                    : <></>
                                                  }
                                                </>
                                              }

                                            </div>
                                          </> :

                                          <div className='row mt-5'>
                                            <div className='col-12'>
                                              <p className='no_data_txt'>No Data</p>
                                            </div>
                                          </div>
                                        }


                                      </>
                                    </Tab.Pane>

                                  </Tab.Content>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Container>


                        </div>
                      </div>
                    </div>
                 
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default LaunchpadList