import { ERC20_ABI, DECIMAL } from "../config/erc20";
import { PROXY_SALE_ABI, PROXY } from "../config/proxy";
import { CHAINS, WBNB_Address, ZEROTH_ADDRESS } from "../config/env";
import { SALE_ABI } from "../config/presale";
import toast from "react-hot-toast";
import { CURRENT_CHAIN_ID, useWeb3 } from "./useWeb3";
import { STANDARD_TOKEN_ABI } from "../config/standard";
import { getAccount } from "./useAccount";
import { LIQUIDITY_TOKEN_ABI } from "../config/liquiditytoken";
import { iconTheme, position, style } from "./useToast";
import { LOCK_ABI } from "../config/lock";
import { userlaunchpadhook } from "./usebackend";
import { getChainId } from "./useAccount";

export const UseERC20 = async (VALUE) => {
  try {
    const web3 = await useWeb3();
    const contract = new web3.eth.Contract(ERC20_ABI, VALUE);
    return contract;
  } catch (err) {
    console.log("UseERC20", err);
  }
};

export const UseSale = async (VALUE) => {
  try {
    const web3 = await useWeb3();
    const contract = new web3.eth.Contract(SALE_ABI, VALUE);
    console.log("contract", contract);
    return contract;
  } catch (err) {
    console.log("UseSale", err);
  }
};

export const getProxyAddress = () => {
  try {
    const CHAIN_ID = CURRENT_CHAIN_ID();
    return PROXY[CHAIN_ID];
  } catch (err) {
    console.log("getProxyAddress", err);
  }
};
var CheckToast = true;
export const checkChainId = (chainId) => {
  try {
    const CHAIN_ID = CURRENT_CHAIN_ID();
    console.log(CheckToast, typeof CheckToast, "CheckToast");
    if (CheckToast) {
      if (parseInt(chainId) != parseInt(CHAIN_ID)) {
        toast.error(`Connected to Wrong Network !`, {
          position: position.position,
          style: style,
          iconTheme: iconTheme,
        });
      }
    }
    CheckToast = false;
    return true;
  } catch (err) {
    console.log("checkChainId", err);
  }
};

export const UseProxySale = async () => {
  try {
    const web3 = await useWeb3();
    const chainId = await web3?.eth?.getChainId();
    checkChainId(chainId);
    const proxysale = getProxyAddress();

    const contract = new web3.eth.Contract(PROXY_SALE_ABI, proxysale);
    console.log("proxysale", contract);
    return contract;
  } catch (err) {
    console.log("UseProxySale", err);
  }
};

export const UsegetChildrenOf = async (saleAddress, useraddress) => {
  try {
    const web3 = await useWeb3();
    const proxysale = getProxyAddress();

    const proxyContract = new web3.eth.Contract(PROXY_SALE_ABI, proxysale);
    const data = await proxyContract.methods
      .getChildrenOf(saleAddress, useraddress)
      .call();
    console.log("getAffParentOf", data);

    return data;
  } catch (err) {
    console.log("UsegetChildrenOf", err);
  }
};

export const getProxyOwner = async () => {
  try {
    const proxy = await UseProxySale();
    const owner = await proxy?.methods?.owner().call();
    return owner;
  } catch (err) {
    console.log("getProxyOwner", err);
  }
};

export const getSalecreationFee = async () => {
  try {
    const proxy = await UseProxySale();

    const Fee = await proxy?.methods?.depolymentFee().call();
    return Fee;
  } catch (err) {
    console.log("getSalecreationFee", err);
  }
};

export const UseTokenInfo = async (TOKEN) => {
  try {
    console.log("token", TOKEN);
    const token = await UseERC20(TOKEN);
    const name = await token?.methods?.name().call();
    console.log("name", name);
    const symbol = await token?.methods?.symbol().call();
    const decimals = await token?.methods?.decimals().call();
    const data = {
      name: name,
      symbol: symbol,
      decimals: decimals,
    };
    console.log("data", data);
    return data;
  } catch (err) {
    console.log("UseTokenInfo", err);
  }
};

export const IsValidAddress = async (ADDRESS) => {
  try {
    const web3 = await useWeb3();
    const value = await web3?.utils?.isAddress(ADDRESS);
    return value;
  } catch (err) {
    console.log("IsValidAddress", err);
  }
};

export const getFullBalance = (value) => {
  try {
    const balance = parseFloat(value) / 10 ** parseFloat(DECIMAL);
    return balance.toFixed(2);
  } catch (err) {
    console.log("getFullBalance", err);
  }
};

export const getWholeNumber = (value) => {
  try {
    const balance = parseFloat(value) * 10 ** parseFloat(DECIMAL);
    return toFixedNumber(balance).toString();
  } catch (err) {
    console.log("getWholeNumber", err);
  }
};

export const isFloat = (x) => {
  try {
    if (!!(x % 1)) {
      toast.error(`Decimal Values are not accepted !`, {
        style: {
          minWidth: "300px",
          minHeight: "55px",
        },
      });
    }
    return !!(x % 1);
  } catch (err) {
    console.log("isFloat", err);
  }
};

export const createPresale = async (
  token,
  values,
  setters,
  details,
  account
) => {
  try {
    console.log("createsale");
    await InitiatePresale(token, values, setters, details, account);
    const sale = await getSaleAddress(token[0]);
    if (sale == "0x0000000000000000000000000000000000000000") {
      sale = await getSaleAddress(token[0]);
      let wallet = getAccount();
      wallet = wallet.toString();
      wallet = wallet.toLowerCase();
      console.log("wallet", wallet);
      let payload = {
        chain: CHAINS[getChainId()].CHAIN_ID,
        walletaddress: wallet,
        saleaddress: sale,
        whitelist: setters[3],
      };
      let createlaunchpad = await userlaunchpadhook(payload);
      console.log("create", createlaunchpad);
      return sale;
    } else {
      let wallet = getAccount();
      wallet = wallet.toString();
      wallet = wallet.toLowerCase();
      console.log("wallet", wallet);
      let payload = {
        walletaddress: wallet,
        saleaddress: sale,
        whitelist: setters[3],
      };
      let createlaunchpad = await userlaunchpadhook(payload);
      console.log("create", createlaunchpad);
      return sale;
    }
  } catch (err) {
    console.log("createPresale", err);
  }
};

export const InitiatePresale = async (
  token,
  values,
  setters,
  details,
  account
) => {
  try {
    const proxyContract = await UseProxySale();
    const deploymentFee = await proxyContract.methods.getDeploymentFee().call();
    console.log(
      token,
      values,
      setters,
      details,
      deploymentFee,
      "token_values_setters_details"
    );
    const data = proxyContract.methods
      .createPresale(token, values, setters, details)
      .send({ from: account, value: deploymentFee });
    console.log("data in initiate presale", data);
    await toast.promise(
      data,
      {
        loading: "Creating New Presale ...",
        success: "Presale Created Successfully",
        error: (err) => `Error : ${err.toString()}`,
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
  } catch (err) {
    console.log("InitiatePresale", err);
  }
};

export const DeletePresale = async (saleAddress, account) => {
  try {
    const proxyContract = await UseProxySale();
    const data = proxyContract.methods
      .deleteSalePresale(saleAddress)
      .send({ from: account });
    await toast.promise(
      data,
      {
        loading: "Deleting the Sale...",
        success: "Sale Deleted Successfully",
        error: "Error ! When Delete sale",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    await sleep(1000);
    window.location.reload();
  } catch (err) {
    console.log("DeletePresale", err);
  }
};

export const getSaleAddress = async (tokenAddress) => {
  try {
    const proxyContract = await UseProxySale();
    const sale = await proxyContract.methods.getSale(tokenAddress).call();
    return sale;
  } catch (err) {
    console.log("getSaleAddress", err);
  }
};

export const depositTokens = async (
  tokenAddress,
  saleAddress,
  value,
  account
) => {
  try {
    console.log("value", value);
    const erc20 = await UseERC20(tokenAddress);
    const data = erc20.methods
      .transfer(saleAddress, value)
      .send({ from: account });
    await toast.promise(
      data,
      {
        loading: "Depositing Tokens ...",
        success: "Tokens Deposited Successfully",
        error: "Error ! When Depositing Tokens",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    const balance = await erc20.methods.balanceOf(saleAddress).call();
    if (balance >= parseInt(value)) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log("depositTokens", err);
  }
};

export const checkbalnceTokens = async (tokenAddress, saleAddress) => {
  try {
    const erc20 = await UseERC20(tokenAddress);
    const balance = await erc20.methods.balanceOf(saleAddress).call();
    return balance;
  } catch (err) {
    console.log("checkbalnceTokens", err);
  }
};

export const getAffliateInfo = async (ADDRESS) => {
  try {
    const saleContract = await UseSale(ADDRESS);
    const AffliateInfo = await saleContract?.methods?.getAffliateInfo().call();
    return AffliateInfo;
  } catch (err) {
    console.log("getAffliateInfo", err);
  }
};

export const getSaleInfo = async (ADDRESS) => {
  try {
    const saleContract = await UseSale(ADDRESS);
    const tokenAddress = await saleContract.methods.tokenAddress().call();
    const isWithoutToken = await saleContract.methods.isWithoutToken().call();
    let name,
      symbol,
      decimals = "";
    if (isWithoutToken) {
      name = await saleContract.methods.tokenName().call();
      symbol = await saleContract.methods.tokenSymbol().call();
      decimals = await saleContract.methods.tokenDecimals().call();
    } else {
      const tokenContract = await UseERC20(tokenAddress);
      name = await tokenContract.methods.name().call();
      symbol = await tokenContract.methods.symbol().call();
      decimals = await tokenContract.methods.decimals().call();
    }
    const description = await saleContract.methods.description().call();
    const logo = await saleContract.methods.logo().call();
    const start = await saleContract.methods.startTime().call();
    const end = await saleContract.methods.endTime().call();
    const islive = Date.now() >= start && Date.now <= end;
    const earnedCap = await saleContract.methods.earnedCap().call();
    const participants = await saleContract.methods.participants().call();
    const website = await saleContract.methods.social(0).call();
    const twitter = await saleContract.methods.social(1).call();
    const telegram = await saleContract.methods.social(2).call();
    const githup = await saleContract.methods.social(5).call();
    const instagram = await saleContract.methods.social(6).call();
    const discord = await saleContract.methods.social(7).call();
    const reddit = await saleContract.methods.social(8).call();
    const youtube = await saleContract.methods.social(9).call();
    const isPancake = await saleContract.methods.isautoAdd().call();
    const isVested = await saleContract.methods.isVested().call();
    const vestingInterval = await saleContract.methods.vestingInterval().call();
    const vestingPercent = await saleContract.methods.vestingPercent().call();
    const hardCap = await saleContract.methods.hardCap().call();
    const softCap = await saleContract.methods.softCap().call();
    const tokenRatePerEth = await saleContract.methods.tokenRatePerEth().call();
    const minEthLimit = await saleContract.methods.minEthLimit().call();
    const maxEthLimit = await saleContract.methods.maxEthLimit().call();
    const ownerAddress = await saleContract.methods.owner().call();
    const data = {
      name: name,
      symbol: symbol,
      decimals: decimals,
      tokenAddress: tokenAddress,
      logo: logo,
      isWithoutToken: isWithoutToken,
      description: description,
      start: start,
      end: end,
      islive: islive,
      earnedCap: earnedCap,
      participants: participants,
      website: website,
      twitter: twitter,
      telegram: telegram,
      isPancake: isPancake,
      isVested: isVested,
      vestingInterval: vestingInterval,
      vestingPercent: vestingPercent,
      hardCap: hardCap,
      softCap: softCap,
      tokenRatePerEth: tokenRatePerEth,
      minEthLimit: minEthLimit,
      maxEthLimit: maxEthLimit,
      owner: ownerAddress,
      githup: githup,
      instagram: instagram,
      discord: discord,
      reddit: reddit,
      youtube: youtube,
    };
    console.log("owner>>>", data.owner);
    return data;
  } catch (err) {
    console.log("getSaleInfo", err);
  }
};

export const GetSalePerAccount = async (account, saleAddress) => {
  try {
    const saleContract = await UseSale(saleAddress);
    const userData = await saleContract.methods.getUserInfo(account).call();
    console.log("userData", userData);

    return userData;
  } catch (err) {
    console.log("GetSalePerAccount", err);
  }
};

export const getSaleInfoCard = async (saleAddress) => {
  try {
    const saleContract = await UseSale(saleAddress);
    console.log("saleContract", saleContract, saleAddress);
    const data = await saleContract.methods?.getSaleInfo().call();
    console.log("new", data);

    return data;
  } catch (err) {
    console.log("getSaleInfoCard_err", err);
  }
};

export const approveContract = async (contract, account, CONTRACT_ADDRESS) => {
  try {
    await contract.methods
      .approve(
        CONTRACT_ADDRESS,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      )
      .send({ from: account });
  } catch (err) {
    console.log("approveContract", err);
  }
};

export const sleep = (ms) => {
  new Promise((resolve) => setTimeout(resolve, ms));
};

export const Usestandardtoken = async (VALUE) => {
  try {
    const web3 = await useWeb3();
    const contract = new web3.eth.Contract(STANDARD_TOKEN_ABI, VALUE);
    return contract;
  } catch (err) {
    console.log("Usestandardtoken", err);
  }
};

export const CreateStandardToken = async (argument) => {
  try {
    const web3 = await useWeb3();
    const account = getAccount();
    const contract = await Usestandardtoken(
      CHAINS[getChainId()].STANDARD_TOKEN_ADDRESS
    );
    console.log("contract", contract.methods, account, web3);

    const balance = await web3.eth.getBalance(account);
    console.log("argument", argument, balance > 0.01);
    var fee = await contract.methods._depolymentFee().call();
    var hash = "";
    let createpresale = contract.methods
      .createPresaleToken(argument[0], argument[1], argument[2], argument[3])
      .send({ from: account, value: fee.toString() })
      .on("transactionHash", async (tx) => {});

    await toast.promise(
      createpresale,
      {
        loading: "Creating New Token ...",
        success: "Token Created Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    await sleep(1000);
    console.log("createpresale", createpresale);
    let length = await contract.methods.tokenLength().call();
    let presaledata = await contract.methods._tokeninfo(length - 1).call();
    console.log("presaledata", presaledata, length);
    sessionStorage.setItem("TKNDATA", JSON.stringify(presaledata));
    return createpresale;
  } catch (err) {
    console.log("CreateStandardToken", err);
  }
};

export const gettokendata = async (address) => {
  try {
    const contract = await Usestandardtoken(
      CHAINS[getChainId()].STANDARD_TOKEN_ADDRESS
    );
    let tokendata = contract.methods._presale(address).call();
    return tokendata;
  } catch (err) {
    console.log("gettokendata", err);
  }
};

export const toFixedNumber = (x) => {
  try {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  } catch (err) {
    console.log("toFixedNumber", err);
  }
};

export const Useliquiditytoken = async (VALUE) => {
  try {
    const web3 = await useWeb3();
    const contract = new web3.eth.Contract(LIQUIDITY_TOKEN_ABI, VALUE);
    return contract;
  } catch (err) {
    console.log("Useliquiditytoken", err);
  }
};

const getRouter = async () => {
  try {
    let chain = getChainId();
    let router = CHAINS[chain].ROUTER;
    console.log("router", router);
    return router;
  } catch (err) {
    console.log("getRouter", err);
  }
};

export const Getbalance = async () => {
  try {
    const web3 = await useWeb3();
    const account = getAccount();
    console.log("amount", account);

    if (account) {
      const balance = await web3.eth.getBalance(account);
      console.log({ balance });
      return balance / 10 ** 18;
    } else {
      return null;
    }
  } catch (err) {
    console.log("Getbalance", err);
  }
};

export const CreateLiquidityToken = async (argument) => {
  try {
    const web3 = await useWeb3();
    const account = getAccount();
    const contract = await Useliquiditytoken(
      CHAINS[getChainId()].LIQUIDITY_TOKEN_ADDRESS
    );
    console.log("contract", contract.methods, account, web3);
    console.log("argument", argument);
    var fee = await contract.methods.fee().call();
    var deployfee = (fee / 10 ** 18).toString();
    const router = await getRouter();
    console.log("deploy fee", fee);

    let createpresale = contract.methods
      .createPresaleToken(
        argument[0],
        argument[1],
        argument[2],
        argument[3],
        argument[4],
        argument[5],
        argument[6],
        argument[7],
        router
      )
      .send({ from: account, value: fee })
      .on("transactionHash", async (tx) => {});

    await toast.promise(
      createpresale,
      {
        loading: "Creating Liquidity Token...",
        success: "Liquidity Token Created Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    await sleep(1000);
    console.log("createpresale", createpresale);
    let length = await contract.methods.tokenLength().call();
    let presaledata = await contract.methods._tokeninfo(length - 1).call();
    console.log("presaledata", presaledata, length);
    sessionStorage.setItem("TKNDATA", JSON.stringify(presaledata));
    return createpresale;
  } catch (e) {
    console.log("CreateLiquidityToken", e);
  }
};

export const Checkaddress = async (address) => {
  try {
    const web3 = await useWeb3();
    const account = getAccount();

    let errors = {};
    const data = web3.utils.isAddress(address);
    if (data) {
      console.log("checkaddress");
      let tokeninfo = await UseTokenInfo(address);
      console.log("tokeninfo", tokeninfo);
      const newad = web3.utils.toChecksumAddress(address);
      const contract = new web3.eth.Contract(ERC20_ABI, newad);
      const allowance = await contract.methods
        .allowance(account, CHAINS[getChainId()].LOCK_ADDRESS)
        .call();
      const approvebutton = allowance > 0 ? true : false;
      console.log("checkaddress", tokeninfo);
      return {
        isValid: true,
        tokeninfo: tokeninfo,
        allowance: approvebutton,
      };
    } else {
      errors.tokenaddress = "Invalid address !";
      return {
        isValid: false,
        errors: errors,
      };
    }
  } catch (err) {
    console.log("Checkaddress", err);
  }
};

export const Checklaunchpadaddress = async (address) => {
  try {
    const web3 = await useWeb3();
    const account = getAccount();

    let errors = {};
    const data = web3.utils.isAddress(address);
    if (data) {
      console.log("checkaddress");
      let tokeninfo = await UseTokenInfo(address);
      console.log("tokeninfo", tokeninfo);
      const contract = await UseERC20(address);
      const allowance = await contract.methods
        .allowance(account, CHAINS[getChainId()].LOCK_ADDRESS)
        .call();
      const approvebutton = allowance > 0 ? true : false;
      console.log("checkaddress", tokeninfo);
      return {
        isValid: true,
        tokeninfo: tokeninfo,
        allowance: approvebutton,
      };
    } else {
      errors.tokenaddress = "Invalid address !";
      return {
        isValid: false,
        errors: errors,
      };
    }
  } catch (err) {
    console.log("Checklaunchpadaddress", err);
  }
};

export const Gettokenbalance = async (address) => {
  try {
    const web3 = await useWeb3();
    const account = getAccount();
    console.log("account", account);
    const contract = new web3.eth.Contract(ERC20_ABI, address);
    const tokenbalance = await contract.methods.balanceOf(account).call();
    console.log("account", tokenbalance);
    return tokenbalance;
  } catch (err) {
    console.log("Gettokenbalance", err);
  }
};

export const Approvetoken = async (address, amount) => {
  const web3 = await useWeb3();

  try {
    console.log("address", address, amount);
    const account = getAccount();
    const contract = new web3.eth.Contract(ERC20_ABI, address);
    const approve = contract.methods
      .approve(
        CHAINS[getChainId()].LOCK_ADDRESS,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      )
      .send({ from: account });
    await toast.promise(
      approve,
      {
        loading: "Approving...",
        success: "Approved Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    await sleep(1000);
    return true;
  } catch (err) {
    console.log("Uselockcontract", err);
  }
};

export const Uselockcontract = async (VALUE) => {
  try {
    const web3 = await useWeb3();
    console.log(CHAINS[getChainId()].LOCK_ADDRESS, "lock");
    const contract = new web3.eth.Contract(
      LOCK_ABI,
      web3.utils.toChecksumAddress(CHAINS[getChainId()].LOCK_ADDRESS)
    );
    console.log(contract, "Uselockcontract");
    return contract;
  } catch (err) {
    console.log("Uselockcontract", err);
  }
};

export const Createlock = async (data, decimal) => {
  console.log("contractetail", data, data.locktime);
  const account = getAccount();
  const contract = await Uselockcontract();
  const amount = toFixedNumber(data.amount * 10 ** decimal).toString();
  console.log(
    "contractetail1",
    data.locktime,
    Date.now(),
    new Date(data.locktime).toString(),
    new Date(data.locktime).getTime(),
    new Date(data.locktime).getTime() - Date.now(),
    (new Date(data.locktime).getTime() - Date.now()) / 1000,
    Math.round((new Date(data.locktime).getTime() - Date.now()) / 1000)
  );
  const locktime = Math.round(
    (new Date(data.locktime).getTime() - Date.now()) / 1000
  );
  const toaddress = data.anotherowneraddress
    ? data.anotherowneraddress
    : account;
  const vestinginfo = data.vesting ? [data.cycle, data.cyclepercent] : [];
  console.log("contractetail", data.tokenaddress, amount, locktime.toString());
  console.log(
    "datas",
    data.tokenaddress,
    amount,
    locktime,
    toaddress,
    data.vesting,
    vestinginfo
  );

  const web3 = await useWeb3();
  const lpcontract = new web3.eth.Contract(ERC20_ABI, data.tokenaddress);
  console.log("lpcontract", lpcontract);
  let islptoken = false;
  try {
    let istoken = await lpcontract.methods.token0().call();
    console.log("istoken", istoken);
    islptoken =
      istoken.toUpperCase() == ZEROTH_ADDRESS.toUpperCase() ? true : false;
  } catch (e) {
    islptoken = true;
  }

  const tokenlock = contract.methods
    .deposit(
      data.tokenaddress,
      amount,
      locktime.toString(),
      toaddress,
      data.vesting,
      vestinginfo,
      islptoken
    )
    .send({ from: account });
  await toast.promise(
    tokenlock,
    {
      loading: "Creating Lock...",
      success: "Lock Created Successfully",
      error: "Try Again",
    },
    {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
    }
  );
  await sleep(1000);
  let tokeninfo = await UseTokenInfo(data.tokenaddress);
  let lockerid = await contract.methods.LpLocker(data.tokenaddress).call();
  let lockerinfo = await contract.methods.lockers(lockerid).call();
  return {
    tokeninfo: tokeninfo,
    lockerinfo: lockerinfo,
    tokenaddress: data.tokenaddress,
    owner: toaddress,
  };
};

export const Checkowneraddress = async (data) => {
  try {
    const web3 = await useWeb3();
    const isaddress = web3.utils.isAddress(data);
    return isaddress;
  } catch (err) {
    console.log("Checkowneraddress", err);
  }
};

export const Getmytokenlock = async (cards, start, end, user) => {
  try {
    const account = getAccount();
    const contract = await Uselockcontract();
    if (account) {
      console.log("contract", contract, account);
      const userlocker = await contract.methods
        .getuserperlocker(account)
        .call();
      return userlocker;
    }
  } catch (err) {
    console.log("Getmytokenlock", err);
  }
};

export const ViewMylock = async (data) => {
  try {
    console.log("ViewMylockcontract", data);
    const account = getAccount();
    const contract = await Uselockcontract();
    console.log("ViewMylockcontract", contract);
    const view = await contract.methods?.users(account, data).call();
    console.log("view", view);
    const transferowner = await contract.methods
      .getTransferedLockers(data, account)
      .call();
    console.log("transferowner", transferowner);

    if (transferowner.length > 0) {
      return transferowner[0];
    } else {
      return view;
    }
  } catch (err) {
    console.log(err, "ViewMylock__err");
  }
};

export const Transferlockownership = async (data) => {
  try {
    const account = getAccount();
    const contract = await Uselockcontract();
    const transferowner = contract.methods
      .transferLockerOwner(data.lpaddress, account, data.newowneraddress)
      .send({ from: account });
    await toast.promise(
      transferowner,
      {
        loading: "Changing Lock Ownership...",
        success: "Ownership Changed Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    await sleep(1000);
  } catch (err) {
    console.log("Transferlockownership", err);
  }
};

export const Unlocktoken = async (data, type, index) => {
  console.log("data", data, type, index);
  const account = getAccount();
  const contract = await Uselockcontract();
  console.log("contract-Unlocktoken", contract);
  if (type == "createduser") {
    console.log("contract-Unlocktoken2", contract);
    const unlock = contract.methods
      .withdrawFunds(data, account)
      .send({ from: account });
    console.log("contract-Unlocktoken3", unlock);
    await toast.promise(
      unlock,
      {
        loading: "Unlock Token...",
        success: "Token Unlocked Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );

    await sleep(1000);
    return true;
  } else {
    console.log("contract-Unlocktoken4", contract);
    const unlock = contract.methods
      ?.withdrawTransferLockFunds(data, account, index)
      .send({ from: account });
    console.log("contract-Unlocktoken5", unlock);
    await toast.promise(
      unlock,
      {
        loading: "Unlock Token...",
        success: "Token Unlocked Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    await sleep(1000);
    return true;
  }
};

export const Getalltokenlock = async (cards, start) => {
  try {
    const contract = await Uselockcontract();
    const lock = [];
    for (var i = start; i < cards; i++) {
      console.log("condition ", i, cards);

      if (i < cards) {
        const lockaddressinfo = await contract.methods.lockers(i).call();
        var tokeninf = await UseTokenInfo(lockaddressinfo.LPAddress);
        lockaddressinfo.name = tokeninf.name;
        lockaddressinfo.decimal = tokeninf.decimals;
        lockaddressinfo.symbol = tokeninf.symbol;
        lock.push(lockaddressinfo);
        console.log("copy", lock);
        if (lock.length == 3) {
          console.log("lock arrayyyyy", lock);
          return {
            index: i + 1,
            lock: lock,
          };
        }
      } else {
        console.log("elselock arrayyyyy", lock);
        return {
          index: i,
          lock: lock,
        };
      }
    }
    return {
      index: i,
      lock: lock,
    };
  } catch (err) {
    console.log("Getalltokenlock", err);
  }
};

export const Getlockercount = async () => {
  try {
    const contract = await Uselockcontract();
    const count = await contract.methods.lockerCount().call();
    return count;
  } catch (err) {
    console.log("Getlockercount", err);
  }
};

export const Getsinglelockerinfo = async (address) => {
  try {
    const contract = await Uselockcontract();
    const index = await contract.methods.getLockerId(address).call();
    const lockaddressinfo = await contract.methods.lockers(index).call();
    var tokeninf = await UseTokenInfo(lockaddressinfo.LPAddress);
    lockaddressinfo.name = tokeninf.name;
    lockaddressinfo.decimal = tokeninf.decimals;
    lockaddressinfo.symbol = tokeninf.symbol;
    return lockaddressinfo;
  } catch (err) {
    console.log("Getsinglelockerinfo", err);
  }
};

export const Getsinglelockerdetail = async (address) => {
  try {
    const contract = await Uselockcontract();
    const lockdetail = await contract.methods
      .getLockerUsersInfo(address)
      .call();
    return lockdetail;
  } catch (err) {
    console.log("Getsinglelockerdetail", err);
  }
};

export const GetAdminfee = async () => {
  try {
    console.log("calll");
    let contract = await UseProxySale();
    const tokencontract = await Usestandardtoken(
      CHAINS[getChainId()].STANDARD_TOKEN_ADDRESS
    );
    const liquidityToken = await Useliquiditytoken(
      CHAINS[getChainId()].LIQUIDITY_TOKEN_ADDRESS
    );
    var deploymentfee = await contract?.methods?.getDeploymentFee().call();
    var tokenfee = await tokencontract?.methods?._depolymentFee().call();
    var liquidityTokenFee = await liquidityToken?.methods?.fee().call();
    return {
      deploymentfee: deploymentfee,
      tokenfee: tokenfee,
      liquidityTokenFee: liquidityTokenFee,
    };
  } catch (err) {
    console.log("GetAdminfee", err);
  }
};

export const SetDeploymentfee = async (value) => {
  try {
    let contract = await UseProxySale();
    let wallet = getAccount();
    let fee = contract.methods
      .setDeploymentFee((value * 10 ** 18).toString())
      .send({ from: wallet });
    await toast.promise(
      fee,
      {
        loading: "Updating fee...",
        success: "Fee Updated Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    await sleep(1000);
    window.location.reload();
  } catch (err) {
    console.log("SetDeploymentfee", err);
  }
};

export const SetTokenfee = async (value) => {
  try {
    const contract = await Usestandardtoken(
      CHAINS[getChainId()].STANDARD_TOKEN_ADDRESS
    );
    let wallet = getAccount();
    const data = value * 10 ** 18;

    console.log("value", data);
    let fee = contract.methods
      .setDeploymentFee(data.toString())
      .send({ from: wallet });
    await toast.promise(
      fee,
      {
        loading: "Updating fee...",
        success: "Fee Updated Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    await sleep(1000);
    window.location.reload();
  } catch (err) {
    console.log("SetTokenfee", err);
  }
};

export const SetUserfee = async (value) => {
  try {
    const liquidityToken = await Useliquiditytoken(
      CHAINS[getChainId()].LIQUIDITY_TOKEN_ADDRESS
    );
    let wallet = getAccount();
    let fee = liquidityToken.methods
      .setFee((value * 10 ** 18).toString())
      .send({ from: wallet });
    await toast.promise(
      fee,
      {
        loading: "Updating fee...",
        success: "Fee Updated Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
    await sleep(1000);
    window.location.reload();
  } catch (err) {
    console.log("SetUserfee", err);
  }
};

export const Withdrawbnb = async () => {
  try {
    const proxyContract = await UseProxySale();
    const account = getAccount();
    const data = proxyContract.methods.withdrawBNB().send({ from: account });
    console.log("data in withdraw", data);
    await toast.promise(
      data,
      {
        loading: "Creating Withdraw ...",
        success: "Withdrawn Successfully",
        error: (err) => `Error : ${err.toString()}`,
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
  } catch (err) {
    console.log("Withdrawbnb", err);
  }
};

export const Getliquiditytokenamount = async (address) => {
  try {
    console.log("addressaddress", address);
    const proxyContract = await UseSale(address);
    const data = await proxyContract.methods.getLiquidityTokenAmount().call();
    console.log("data in withdraw", data);
    return data;
  } catch (err) {
    console.log("Getliquiditytokenamount", err);
  }
};

export const Getunsoldtoken = async (address) => {
  try {
    const proxyContract = await UseSale(address);
    const data = await proxyContract.methods.getUnsoldTokensBalance().call();
    console.log("data in withdraw", data);
    return data;
  } catch (err) {
    console.log("Getunsoldtoken", err);
  }
};

export const Totalvaluelocked = async (address) => {
  let contract = await UseERC20(WBNB_Address);
  console.log("contract", contract);
  let balance = await contract.methods
    .balanceOf("0x2aB5E5AdD110336D47333ed98893fa11659a35B0")
    .call();
  console.log("balance", balance);
};

export const checkIsApproved = async (account, token, saleaddress) => {
  try {
    const erc20contract = await UseERC20(token);
    const allow = await erc20contract.methods
      .allowance(account, saleaddress)
      .call();

    return parseInt(allow) > 0 ? true : false;
  } catch (err) {
    console.log("checkIsApproved", err);
  }
};

export const approveContractbuy = async (account, token, presaleaddress) => {
  try {
    const erc20Contract = await UseERC20(token);
    const symbol = await erc20Contract.methods.symbol().call();
    const data = erc20Contract.methods
      .approve(
        presaleaddress,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      )
      .send({ from: account });
    await toast.promise(
      data,
      {
        loading: `Approving ${symbol} token...`,
        success: "Approved Successfully",
        error: "Try Again",
      },
      {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      }
    );
  } catch (err) {
    console.log("approveContractbuy", err);
  }
};

export const Getsalebalance = async (address, istoken) => {
  try {
    const web3 = await useWeb3();
    if (istoken) {
      const token = await UseERC20(address);
      const balance = await token.methods.getBalance().call();
      return balance / 10 ** 18;
    } else {
      const balance = await web3.eth.getBalance(address);
      return balance / 10 ** 18;
    }
  } catch (err) {
    console.log("Getsalebalance", err);
  }
};

export const Checksum = async (address) => {
  try {
    const web3 = await useWeb3();
    const newad = web3.utils.toChecksumAddress(address);
    return newad;
  } catch (e) {
    console.log("Checksum", e);
  }
};

export const GetTokenInfo = async () => {
  try {
    var standardtoken = [];
    var liquiditytoken = [];
    const contract = await Usestandardtoken(
      CHAINS[getChainId()].STANDARD_TOKEN_ADDRESS
    );
    var length = await contract.methods.tokenLength().call();
    console.log("Usestandardtoken_length", length);
    if (!length) {
      return [];
    }
    for (let i = length - 1; i >= length - 3; i--) {
      console.log("tokenInfo", i);
      if (i >= 0) {
        const tokenInfo = await contract.methods
          ._tokeninfo(i.toString())
          .call();
        console.log("tokenInfo", tokenInfo);
        standardtoken.push(tokenInfo);
        console.log("Useliquiditytoken_length");
      }
    }
    console.log("Useliquiditytoken_length");
    const liquidityContract = await Useliquiditytoken(
      CHAINS[getChainId()].LIQUIDITY_TOKEN_ADDRESS
    );
    console.log("Useliquiditytoken_length");
    var liqLength = await liquidityContract.methods.tokenLength().call();
    console.log("Useliquiditytoken_length", liqLength);
    for (let j = liqLength - 1; j > liqLength - 3; j--) {
      if (j >= 0) {
        const token = await contract.methods._tokeninfo(j.toString()).call();
        liquiditytoken.push(token);
      }
    }
    let standararray = [];
    let liquidityarray = [];
    console.log(standardtoken, "standardtoken");
    if (standardtoken?.length > 0) {
      standardtoken?.map((val, i) => {
        console.log(val, "standardtoken");
        if (i === 2) {
          return true;
        } else {
          standararray.push(val);
        }
      });
    }
    if (liquiditytoken?.length > 0) {
      liquiditytoken.map((val, i) => {
        console.log(val, "liquidityarray");
        if (i === 2) {
          return true;
        } else {
          liquidityarray.push(val);
        }
      });
    }
    return [...standararray, ...liquidityarray];
  } catch (e) {
    console.log("error", e);
  }
};

export const editDescription = async (ADDRESS, data) => {
  try {
    console.log("editDescription", ADDRESS, data);
    const account = getAccount();
    const saleContract = await UseSale(ADDRESS);
    const Info = await saleContract?.methods
      ?.updateTokenInfo(data)
      .send({ from: account });
    return Info;
  } catch (err) {
    console.log("editDescription", err);
  }
};
