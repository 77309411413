import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';
import { iconTheme, position, style } from "../../hooks/useToast";
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from "../Footer";
import Trendingslider from '../trendingslider';
import { getAccount, getAllocationInfo, getChainId } from '../../hooks/useAccount'
import Chart from "react-apexcharts";
import Countdown, {zeroPad}  from 'react-countdown';
import { Container, ProgressBar, InputGroup, FormControl } from 'react-bootstrap';
import '../../css/styles.css';
import favicon from "../../images/Bitdeal/logo_curve.png"
import detailbanner from "../../images/detail_banner.png"

import { Link } from 'react-router-dom';
import WhitelistModal from "../WhitelistModal"
import RemoveWhitelistmodal from "../RemoveWhitelistmodal"
import ChangeStatusmodal from "../ChangeStatusmodal"
import { approveContractbuy, checkIsApproved, Getliquiditytokenamount, Getsalebalance, getSaleInfoCard, GetSalePerAccount, Getunsoldtoken, UseTokenInfo, getAffliateInfo, UsegetChildrenOf, getProxyOwner, toFixedNumber } from '../../hooks/useContract';
import { isSaleLive, isUpcoming, Salediffernce, UpcomingDiffernce } from '../../hooks/useProjects';
import { ONEDAYINSECONDS } from '../../config/env';
import { BuyToken, calculateBuy, claimToken } from '../../hooks/useBuy';
import { Finalise, getUnsold, SetWhitelisted, updateStopEvent, withdrawBNB } from '../../hooks/useAdmin';
import { getallsalehook, gettrendingdisplayhook } from '../../hooks/usebackend';
// import CsvModal from '../Multisender/CsvModal';
import SampleCsvModal from './sampleCsvModal';
import { CHAINS } from "../../config/env";
import isEmpty from 'is-empty';
import DescriptionModal from '../Projects/DescriptionModal';
import { padToDigits } from "../../helper/timeFormat"


const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <div className='cout_man_div'>
      <div>{zeroPad(days)}</div>
      <div>{zeroPad(hours)}</div>
      <div>{zeroPad(minutes)}</div>
      <div>{zeroPad(seconds)}</div>
    </div>
  } else {
    // Render a countdown
    return <div className='cout_man_div'>
      {/* <span>{days}<span>Days</span> </span>
                <span>{hours}<span>Hours</span></span>
                <span>{minutes}<span>Minuits</span></span>   
                <span>{seconds}<span>Seconds</span> </span>    */}
      <div>{zeroPad(days)}</div>
      <div>{zeroPad(hours)}</div>
      <div>{zeroPad(minutes)}</div>
      <div>{zeroPad(seconds)}</div>
    </div>;
  }
};


class PrivatesaleDetail extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");



  }

  componentDidMount() {
    document.getElementById("privatesale_parent").classList.add("active");
    // this.showLoader();
    // this.hideLoader();
    //  this.setState({ accountInfo: getAccount() });
    this.gettrending();
    this.handlegetkyc()
    this.refreshData()
  }

  handlegetkyc = async () => {
    let auditkycinfo = await getallsalehook();
    if (auditkycinfo?.data?.data?.length > 0) {
      this.setState({ auditkyc: auditkycinfo?.data?.data })
    }

    let singleaudit = auditkycinfo?.data?.data?.find(e => e.saleaddress == window.location.pathname.split('/')[2])
    console.log("auditkuycinfo", auditkycinfo?.data?.data, singleaudit);

    if (singleaudit)
      this.setState({ singleaudit: singleaudit })
  }
  async gettrending() {
    const { saleData } = this.props
    let data = await gettrendingdisplayhook();
    console.log("dataa trending", data?.data?.data);
    if (data?.data?.data?.length > 0)
      this.setState({ trending: data?.data?.data })
    let finddata = data?.data?.data?.find((e) => e?.saleaddress == this.state.saleAddress)
    this.setState({ singletrending: finddata })
  }


  constructor(props) {
    super(props);
    this.state = {
      walletModal: false,
      csvModal: false,
      saleAddress: window.location.pathname.split('/')[2],
      accountInfo: '',
      series: [44, 55, 41, 17, 15],
      options: {
        labels: ["Total Tokens", "Sold Tokens", "Unsold Tokens", "Liquidity Tokens"],
        colors:['#793d12', '#000000', '#c2641c',"#915a31"],
        chart: {
          type: 'pie',
        },
        stroke: {
          show: false,
          curve: 'straight',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0, 
      },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 220
              },
              dataLabels: {
                enabled: false
              },
               
              legend: {
                show:false,
                position: 'bottom',
                onItemHover: {
                  highlightDataSeries: true
              },
              }
            },
          },
          {
            breakpoint: 600,
            options: {
              chart: {
                width: 400
              },
              dataLabels: {
                enabled: false
              },
               
              legend: {
                show:false,
                position: 'bottom',
                onItemHover: {
                  highlightDataSeries: true
              },

              }
            }
          },
          {
            breakpoint: 1199,
            options: {
              chart: {
                width: 500
              },
              dataLabels: {
                enabled: false
              },
               
              legend: {
                show:false,
                position: 'bottom',
                onItemHover: {
                  highlightDataSeries: true
              },

              }
            }
          },
          {
            breakpoint: 2000,
            options: {
              chart: {
                width: 500
              },
              dataLabels: {
                enabled: false
              },
               
              legend: {
                show:false,
                position: 'bottom',
                onItemHover: {
                  highlightDataSeries: true
              },

              }
            }
          },
          {
            breakpoint: 10000,
            options: {
              chart: {
                width: 500
              },
              dataLabels: {
                enabled: false
              },
               
              legend: {
                show:false,
                position: 'bottom',
                onItemHover: {
          highlightDataSeries: true
      },

              }
            }
          }
        ]
      },
      iswhitelisted: true,
      whitelistModal: false,
      removewhitelistModal: false,
      changestatueModal: false,
      buyToken: "",
      saleInfo: {},
      userData: {},
      allocations: [],
      buyTokenDecimal: 18,
      amount: 0,
      claim: 0,
      max: 0,
      isMax: false,
      liqtoken: "",
      unsoldtoken: "",
      chartstatus: false,
      charttokendecimal: "",
      trending: [],
      singletrending: {},
      approvebutton: false,
      auditkyc: [],
      singleaudit: {},
      AffliateInfo: {},
      getChildren: [],
      tokeninfo: {},
      descModal: false,
      isSaleEnd: false

    };
  }

  handlewithdraw = async () => {
    let account = getAccount();
    let withdraw = await withdrawBNB(this.state.saleAddress, account);
  }

  approveFunction = async (accountInfo, tokenAddress, saleAddress) => {
    const approval = await checkIsApproved(accountInfo, tokenAddress, saleAddress);
    console.log("approval0", approval);
    this.setState({ approvebutton: approval })


  }


  async approvetoken() {
    await approveContractbuy(localStorage.getItem("accountInfo"), this.state.saleInfo.useWithToken, this.state.saleAddress);
    this.setState({ approvebutton: true })
  }

  loadData = async () => {
    this.showLoader();
    // if(window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "true"){
    //   console.log("db");
    //   let saleDetail =  JSON.parse(localStorage.getItem("saledata"))
    //   this.setState({ saleInfo: saleDetail});
    // }
    // else{
    const saleDetail = await getSaleInfoCard(this.state.saleAddress);
    var saleData = { ...saleDetail }
    saleData.presaleRate = saleData.presaleRate / 10 ** 18
    saleData.pancakeRate = saleData.pancakeRate / 10 ** 18
    this.setState({ saleInfo: saleData });

    if (localStorage.getItem("accountInfo")) {
      const user = await GetSalePerAccount(localStorage.getItem("accountInfo"), this.state.saleAddress);
      this.setState({ userData: user });

      if (saleDetail.isClaimable) {
        const allocated = await getAllocationInfo(user.actualBalance, user.userClaimbale, saleDetail.vestingInterval, saleDetail.vestingPercent, user.initialClaim);
        this.setState({ allocations: allocated });
        console.log("allcations L ", allocated)
      }

      if (!saleDetail?.buytype) {
        this.approveFunction(localStorage.getItem("accountInfo"), saleDetail?.useWithToken, saleDetail?.saleAddress)
      }
    }
    // }
    this.hideLoader();
  }

  refreshData = async () => {
    this.showLoader();
    //  if(window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "true"){
    //   console.log("db");
    //   let saleDetail =  JSON.parse(localStorage.getItem("saledata"))
    //   this.setState({ saleInfo: saleDetail});
    // }
    // else{
    console.log("address", this.state.saleAddress);
    const saleDetail = await getSaleInfoCard(this.state.saleAddress);
    console.log("saledetail", saleDetail);


    const AffliateInfo = await getAffliateInfo(this.state.saleAddress);
    console.log("AffliateInfo", AffliateInfo)
    this.setState({ AffliateInfo: AffliateInfo })


    const getChildrendata = await UsegetChildrenOf(this.state.saleAddress, getAccount());
    this.setState({ getChildren: getChildrendata })
    console.log("getChildrengetChildren", getChildrendata, getChildrendata?.length);


    console.log("saleDetail", saleDetail);
    const token = await UseTokenInfo(saleDetail?.tokenAddress);
    let decimal;
    console.log("tokentokentoken", token);
    this.setState({ charttokendecimal: 18 })
    let liqtoken = await Getliquiditytokenamount(this.state.saleAddress);
    console.log("liqtoken", liqtoken);

    this.setState({ "liqtoken": liqtoken / 10 ** 18 })
    let unsoldtoken = await Getunsoldtoken(this.state.saleAddress);
    console.log("liqtoken", liqtoken);
    this.setState({ unsoldtoken: unsoldtoken / 10 ** 18 });
    var saleData = { ...saleDetail }
    saleData.presaleRate = saleData.presaleRate / 10 ** 18
    saleData.pancakeRate = saleData.pancakeRate / 10 ** 18
    this.setState({ saleInfo: saleData });

    let saleEnd = isSaleLive(saleDetail.startTime, saleDetail.endTime, saleDetail.isPresaleOpen)
    console.log("saleEnd", saleEnd)
    this.setState({ isSaleEnd: saleEnd });
    this.setState({ "chartstatus": true });
    this.GetBuytokeninfo(saleDetail?.useWithToken)
    if (localStorage.getItem("accountInfo")) {
      const user = await GetSalePerAccount(localStorage.getItem("accountInfo"), this.state.saleAddress);
      console.log("user", user);
      this.setState({ userData: user });
      if (saleDetail.isClaimable) {
        const allocated = await getAllocationInfo(user.actualBalance, saleDetail.vestingInterval, saleDetail.vestingPercent, user.initialClaim);
        this.setState({ allocations: allocated });
      }

      if (!saleDetail?.buytype) {
        this.approveFunction(localStorage.getItem("accountInfo"), saleDetail?.useWithToken, saleDetail?.saleAddress)
      }

    }
    // }

    this.hideLoader()
  }

  GetBuytokeninfo = async (value) => {

    if (value === "0x0000000000000000000000000000000000000000") {
      console.log("kxcnvjxch");
      this.setState({ buyToken: "BNB" });
      this.setState({ buyTokenDecimal: 18 })
    } else {
      const token = await UseTokenInfo(this.state.saleInfo && this.state.saleInfo?.useWithToken);
      console.log("token", token);
      this.setState({ buyToken: token?.symbol });
      this.setState({ buyTokenDecimal: Number(token?.decimals) })

    }
  }

  async max() {
    this.setState({ max: this.state.saleInfo && (parseInt(this.state.saleInfo?.maxEthLimit) / 10 ** this.state.buyTokenDecimal), isMax: true })
    this.setState({ amount: this.state.saleInfo && (parseInt(this.state.saleInfo?.maxEthLimit) / 10 ** this.state.buyTokenDecimal) })

  }

  hidewithdraw = async () => {
    var balance = Getsalebalance(this.state.saleAddress, this.state?.saleInfo?.isWithoutToken);
    if (balance > 0) {
      return false
    }
    else {
      return true
    }
  }


  //   async calculateToken(value){

  //     const tokenValue = await calculateBuy(this.state.saleAddress,value);
  //     this.setState({ claim: (tokenValue/10**this.state.saleInfo?.decimals) })

  // }

  // async calculateToken(value){console.log("calculate claim");
  //     if(this.state.saleInfo.useWithToken == "0x0000000000000000000000000000000000000000"){
  //       const tokenValue = await calculateBuy(this.state.saleAddress,value);
  //       console.log("calculate claim in bnb" , tokenValue , this.state.saleInfo.decimals);
  //       this.setState({ claim: (tokenValue/10**18) })
  //       this.setState({isMax : false}) 
  //     }
  //     else{
  //         let tokeninfo = await UseTokenInfo(this.state.saleInfo.useWithToken)
  //         const tokenValue = await calculateBuy(this.state.saleAddress,value);
  //         console.log("calculate claim token" , tokenValue , tokeninfo?.decimals);
  //         this.setState({ claim: (tokenValue/10**tokeninfo?.decimals).toFixed(2) });
  //         this.setState({isMax : false}) 
  //     }
  // }

  async calculateToken(value) {
    console.log("maxEthLimit", this.state.saleInfo?.maxEthLimit)
    const tokenValue = await calculateBuy(this.state.saleAddress, value);
    this.setState({ claim: (tokenValue) })
    this.setState({ isMax: false })
  }


  // async buy() {
  //   console.log("buyyyyyyyyyyyyyyyyyyyyyyy");
  //   await BuyToken(this.state.saleAddress, this.state.amount, this.state.isMax, this.state.max, getAccount(), this.state.buyTokenDecimal, this.state.buyToken);
  //   window.location.reload();
  //   // this.reset();
  //   // this.props.onUpdate();
  // }


  async buy() {
    console.log("buyyyyyyyyyyyyyyyyyyyyyyy");
    const owner = await getProxyOwner();
    const afflink = window.location.pathname.split('/')[3];
    var AffLink = (afflink?.length != 42 || afflink == "" || afflink == undefined) ? owner : afflink;
    await BuyToken(this.state.saleAddress, this.state.amount, this.state.isMax, this.state.max, getAccount(), this.state.buyTokenDecimal, AffLink, this.state.saleInfo.tokenAddress, parseFloat(this.state.AffliateInfo[0]) / 10 ** 18, this?.state?.tokeninfo?.symbol)

    // await BuyToken(this.state.saleAddress,this.state.amount,this.state.isMax,this.state.max,getAccount(),this.state.buyTokenDecimal,AffLink);

    window.location.reload();
    // this.reset();
    // this.props.onUpdate();
  }



  async Claim() {
    await claimToken(this.state.saleAddress, getAccount());
    // this.props.onUpdate();
  }

  async stopSaleEvent() {
    await updateStopEvent(this.state.saleAddress, getAccount());
    await this.refreshData()

  }



  async withdrawToken() {
    await getUnsold(this.state.saleAddress, getAccount());
    await this.refreshData()
      ;
  }

  async BNBwithdraw() {
    await withdrawBNB(this.state.saleAddress, getAccount());
    await this.refreshData()

  }

  async Finalisesale() {
    await Finalise(this.state.saleAddress, getAccount());
    await this.refreshData()

  }

  async Handlewhitelist(value) {
    await SetWhitelisted(this.state.saleAddress, value, getAccount());
    await this.refreshData()
  }



  async Showmodal() {
    this.setState({ whitelistModal: false })
    this.setState({ csvModal: true })
  }


  async showWitelistModal() {
    this.setState({ whitelistModal: true })

  }


  async copyCode(a, b) {
    toast.success("Affliate link Copied", {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
    })
  }


  handleShowDesc = () => this.setState({ descModal: true })
  handleClose = () => {
    this.setState({ descModal: false })
    this.refreshData()
  }

  lockDaysCal() {
    if (this.state.saleInfo.lpUnlockon == 0) {
      return 0
    }
    let diffTime = Math.abs(new Date(parseFloat(this.state.saleInfo.endTime) * 1000).getTime() - new Date(parseFloat(this.state.saleInfo.lpUnlockon) * 1000).getTime());
    let days = diffTime / (24 * 60 * 60 * 1000);
    console.log("days", days)
    return days.toFixed(0)
  }

  render() {

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const { saleInfo, userInfo, whitelistModal, removewhitelistModal, changestatueModal, userData, iswhitelisted, csvModal } = this.state
    { console.log("saleinfo", saleInfo); }
    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">
        <div className="whole_bg">
          <Header />

          <div className="whole_sec pb-5">
            <div className='flex_side_right'>
              <Sidebar />
              {/* ongoing_sec */}
              <div className='right_side_sec'>
                {/* <div className="text-white topBar d-flex justify-content-between">
                  
                  <small>Trending</small>
                <small>
                  #1&nbsp;<span>Husy</span>
                </small>
                <small>
                  #2&nbsp;<span>ORL</span>
                </small>
                <small>
                  #3&nbsp;<span>UWC</span>
                </small>
                <small>
                  #4&nbsp;<span>777</span>
                </small>
                <small>
                  #5&nbsp;<span>IDXS</span>
                </small>
                <small>
                  #6&nbsp;<span>COUGNU</span>
                </small>
                <small>
                  #7&nbsp;<span>CRICLE</span>
                </small>
                <small>
                  #8&nbsp;<span>KASA</span>
                </small>
                <small>
                  #9&nbsp;<span>FIFAPP</span>
                </small>
                <small>
                  #10&nbsp;<span>SOG</span>
                </small>
                <small>
                  #11&nbsp;<span>COOSHA</span>
                </small>
                <small>
                  #12&nbsp;<span>Honey</span>
                </small>
                
                
              </div> */}
                <Trendingslider/>
                <div className="right_side_spacing pt-3">


                <div className="bg_layer mb-0 mt-4">
                <div className='card_header_pos'>
                  <div className='card_header_detail'>
                  <img src={detailbanner} className='img-fluid' />
                  
                  </div>

                  <div className='profimg profimg_detl'>
                  <img src={saleInfo && saleInfo.logo} alt={saleInfo && saleInfo.symbol} className='img_ind_circle' />
</div>

</div>
<div className='pos_z_body_car pb-5'>
  <div className='text-right mt-2'>

    
  {saleInfo && isSaleLive(saleInfo?.startTime, saleInfo?.endTime, saleInfo?.isPresaleOpen) ?
                                    <span className="badge_live mt-0">
                                      <span className='blk_txt'>Live</span>
                                    </span> : (isUpcoming(saleInfo?.startTime) ?
                                      <span className="badge_upcoming mt-0">
                                        <span className='blk_txt'>Upcoming</span>
                                      </span> :
                                      <span className="badge_finish mt-0">
                                        <span className='blk_txt'>Closed</span>
                                      </span>)
                                  }



  </div>
<p className='detail_headingname text-center'>Zklaunch Project</p>
<div className='text-center'>
<div className='inside_soc_f'>



                                      {saleInfo?.owner == localStorage.getItem("accountInfo") ?
                                      <Link to={`/launchpadedit/${this.state.saleAddress}`} className='btn_social_new btn_social_new_link ml-2'>
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                      </Link>
                                        : <></>} 

<a target="_blank" href={saleInfo && saleInfo.social && saleInfo?.social[0]} className='btn_social_new btn_social_new_a_new ml-1' rel="noreferrer">
                                          <i class="fa fa-globe" aria-hidden="true"></i>
                                        </a>

                                        <a target="_blank" href={saleInfo && saleInfo?.social && saleInfo?.social[1]} className='btn_social_new btn_social_new_a_new ml-1' rel="noreferrer">
                                          <i class="fa fa-twitter" aria-hidden="true"></i>
                                        </a>

                                        {saleInfo && saleInfo?.social && saleInfo?.social[2] !== "" ? <a target="_blank" href={saleInfo && saleInfo?.social && saleInfo?.social[2]} className='btn_social_new btn_social_new_a_new ml-1' rel="noreferrer">
                                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                        </a> :""}

                                        {saleInfo && saleInfo.social && saleInfo?.social[6] !== "" ?   <a target="_blank" href={saleInfo && saleInfo.social && saleInfo?.social[6]} className='btn_social_new btn_social_new_a_new ml-1' rel="noreferrer">
                                          <i class="fa fa-instagram" aria-hidden="true"></i>
                                        </a> :""}
                                    </div>

</div>

<div className='text-center'>
<div className='flex_badges_dv flex_badges_dv_new mt-4'>
                                   


                                     <p className='text-right mb-0 ml-0'>

                                     <span className="badge_grad_new mt-0 mr-1">
                                         <a className='blk_txt text_in_badge_a' href="" target="_blank">V.I.P</a>
                                       </span>

                                       <span className="badge_grad_new mt-0 mr-1">
                                         <a className='blk_txt text_in_badge_a' href="" target="_blank">DOXX</a>
                                       </span>

                                       {this.state.auditkyc.find(e => e.saleaddress == window.location.pathname.split('/')[2]) &&

                                       <span className="badge_grad_new mt-0 mr-1">

                                         <a className='blk_txt text_in_badge_a' href={this.state.singleaudit.kyc} target="_blank" rel="noreferrer">KYC</a>
                                       </span>
  } 

<span className="badge_grad_new mt-0 mr-1">
                                         <a className='blk_txt text_in_badge_a' href="" target="_blank">SAFU</a>
                                       </span>
                                    


                                       {this.state.auditkyc.find(e => e.saleaddress == window.location.pathname.split('/')[2]) &&
                                  
                                       <span className="badge_grad_new mt-0 mr-0">
                                         <a className='blk_txt text_in_badge_a' href={this.state.singleaudit.audit} target="_blank" rel="noreferrer">AUDIT</a>
                                       </span>
   } 
                                      
  
                                     </p>
                                 </div>
</div>

<div className='text-center'>



{saleInfo && isUpcoming(saleInfo.startTime) ?
                              <>
                                <p className='text-center sale_deta_name mt-3'>Sale Starts In</p>
                                <div className='countdown_new text-center'>
                                  {console.log("Salediffernce(saleInfo.endTime)", UpcomingDiffernce(saleInfo.startTime))}
                                  <Countdown date={Date.now() + (UpcomingDiffernce(saleInfo.startTime))} renderer={renderer} className="countdown_grey" zeroPadTime={2}/>
                                </div>
                              </> :

                              (saleInfo && isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen) ?
                                <>
                                  <p className='text-center sale_deta_name mt-4'>Sale Ends In</p>
                                  <div className='countdown_new text-center'>
                                    {console.log("Salediffernce(saleInfo.endTime)", Salediffernce(saleInfo.endTime))}
                                    <Countdown date={Date.now() + Salediffernce(saleInfo?.endTime)} renderer={renderer} className="countdown_grey" zeroPadTime={2}/>
                                  </div>
                                </> :
                                <>
                                  <p className='text-center sale_deta_name mt-4'>Sale Ended</p>
                                  <div className='countdown_new text-center'>
                                    <p className='text-center sale_deta_name_orb mt-2'>Not Live</p>
                                  </div>
                                </>)}

                                <div className='text-center'>
  <div className='row'>
    <div className='col-12 col-sm-10 col-md-8 col-lg-6 mx-auto'>
                            {saleInfo?.LaunchpadType ?
                              <>
                                  <div className='progress_bar_div'>
                                <ProgressBar now={saleInfo && parseInt(parseInt(saleInfo?.earnedCap) / parseInt(saleInfo?.hardCap) * 100)} className='yellow_bar mt-3' />
                                <span className='desc_grey_txt per_v_po'>{saleInfo && parseInt(parseInt(saleInfo?.earnedCap) / parseInt(saleInfo?.hardCap) * 100)} % </span>
                                </div>
                                <p className='white_txt_sm d-flex justify-content-between mt-1'>
                                  <span className='desc_grey_txt'>{saleInfo && ((saleInfo?.earnedCap) / 10 ** this.state.buyTokenDecimal).toFixed(3)} ZKETH</span>
                                  <span className='desc_grey_txt'>{(parseInt(saleInfo.hardCap) / 10 ** this.state.buyTokenDecimal).toFixed(2)} {this.state.buyToken} ZKETH</span>
                                </p>             <p className='text-white text-left'>Amount (Min: {saleInfo && saleInfo?.minEthLimit / 10 ** this.state.buyTokenDecimal} ZKETH & Max: {saleInfo && saleInfo?.maxEthLimit / 10 ** this.state.buyTokenDecimal}  {this.state.buyToken})</p>
                                <p className='input_desc_sm text-left'>You Will Get : {toFixedNumber(this.state.claim) / 10 ** 18} {this.state.saleInfo?.name}</p>
                              </>

                              : <>
                               
                              </>}
                              </div>
                              </div>
                              </div>


  

</div>

<div className='text-center'>
  <div className='row'>
    <div className='col-12 col-sm-10 col-md-8 col-lg-6 mx-auto'>

    

<div className="inputs input-groups date_inoput_grps mb-2 btn_positoned">
                              <InputGroup className="datepicker_input">
                                <FormControl id="amountmax" placeholder="0.0"
                                  value={this.state.amount}
                                  onChange={(e) => {
                                    var numbers = /^\d+((.)|(.\d{0,4})?)$/;
                                    if (!numbers.test(e.target.value) && e.target.value !== "") {
                                      return false
                                    }
                                    this.setState({ amount: e.target.value });
                                    this.calculateToken(e.target.value);
                                  }}
                                  aria-describedby="basic-addon2"
                                />

                              </InputGroup>
                              <InputGroup.Append className='cur_pointer'>
                                <button 
                                disabled
                                // onClick={async () => { await this.max(); await this.calculateToken(this.state.amount) }}
                                 variant="outline-secondary" className="trans_cal_btn">
                                  Max
                                </button>
                              </InputGroup.Append>
                            </div>

                            <div className='praiv_btn_new'>
                              {/* {!window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "false" && <div> */}
                              {saleInfo.buytype ?

                                <>
                                  {saleInfo && isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen) || isUpcoming(saleInfo.startTime, saleInfo.endTime) ?
                                    <button onClick={this.buy.bind(this)} disabled={saleInfo && saleInfo.isWhitelisted ? userData && !(userData.userWhitelistedAmount > 0) : false} className="get-started-btn orbitron_font text-white mt-3">{saleInfo && saleInfo.isWhitelisted ? (userData && !(userData.userWhitelistedAmount > 0) ? "Not Whitelisted" : "Buy") : "Buy"} with {this.state.buyToken} </button> :
                                    // (isUpcoming(saleInfo.startTime,saleInfo.endTime) ? <button disabled={true} className="get-started-btn mr-2" > Upcoming </button> :
                                    ((!isSaleLive(saleInfo.startTime, saleInfo.endTime) ? <button disabled={true} className="get-started-btn orbitron_font text-white mt-3" > Sale InActive </button> : <button onClick={this.buy.bind(this)} className="get-started-btn orbitron_font text-white mt-3" > Buy </button>))
                                  }
                                </> :



                                <>
                                  {!this.state.approvebutton ?
                                    <button className="get-started-btn-border orbitron_font text-white mt-3 ml-3" disabled
                                    // onClick={() => { this.approvetoken() }}
                                    >
                                      Approve
                                    </button> :
                                    <>
                                      {saleInfo && isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen) || isUpcoming(saleInfo.startTime, saleInfo.endTime) ?
                                        <button onClick={this.buy.bind(this)} disabled={saleInfo && saleInfo.isWhitelisted ? userData && !(userData.userWhitelistedAmount > 0) : false} className="get-started-btn orbitron_font text-white mt-3">{saleInfo && saleInfo.isWhitelisted ? (userData && !(userData.userWhitelistedAmount > 0) ? "Not Whitelisted" : "Buy") : "Buy"} with {this.state.buyToken} </button> :
                                        // (isUpcoming(saleInfo.startTime,saleInfo.endTime) ? <button disabled={true} className="get-started-btn mr-2" > Upcoming </button> :
                                        ((!isSaleLive(saleInfo.startTime, saleInfo.endTime) ? <button disabled={true} className="get-started-btn orbitron_font text-white mt-3" > Sale InActive </button> : <button onClick={this.buy.bind(this)} className="get-started-btn orbitron_font text-white mt-3" > Buy </button>))
                                      }
                                    </>
                                  }</>}

                              {/* {!window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "false" && <> */}
                              {saleInfo && saleInfo.isVested ?
                                <button className="get-started-btn orbitron_font text-white mt-3" disabled={!this.state.saleInfo?.isClaimable} onClick={() => this.Claim()}>Vested  {this.state.saleInfo?.symbol} </button> :
                                (saleInfo && saleInfo.isClaimable ?

                                  <button onClick={() => this.Claim()} disabled={(!saleInfo.isClaimable || userData.userClaimbale <= 0)} className="get-started-btn-border orbitron_font text-white mt-3 ml-3"> Claim  {this.state.saleInfo?.symbol}  </button> :
                                  "")}
                              {/* </>} */}
                              {/* </div>} */}
                            </div>


                            <div className='card_bg_steps mt-4'>
                    <div>
                        <div className='card_step text-left'>
                            <div className={this.state.currentStep == 1 ? "card_bg_wizard active" : "card_bg_wizard"}
                            //    onClick={()=>{this.setState({ currentStep: 1})}}
                            >
                                <div className='card-body'>
                                    <div className='d-flex align-items-start h-100'>
                                        <div className='wizard_badge_num'>
                                            
                                        </div>
                                        <div className='pl-3 create_wiz_desc'>
                                            <h5 className='text-left'>Waiting for pool start
</h5>
                                            <p className='mb-0 text-left'>No one can purchase</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
               
                        <div className='card_step'>
                            <div className={this.state.currentStep == 2 ? "card_bg_wizard active" : "card_bg_wizard"}
                            //    onClick={()=>{this.setState({ currentStep: 3})}}
                            >
                                <div className='card-body'>
                                    <div className='d-flex align-items-start h-100'>
                                        <div className='wizard_badge_num'>
                                            
                                        </div>
                                        <div className='pl-3 create_wiz_desc'>
                                            <h5 className='text-left'>Pool Start</h5>

                                            <p className='mb-0 text-left'>Pool starts at 2024.05.07 13:00 (UTC)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      

                        <div className='card_step'>
                            <div className={this.state.currentStep == 3 ? "card_bg_wizard active" : "card_bg_wizard"}
                            //    onClick={()=>{this.setState({ currentStep: 3})}}
                            >
                                <div className='card-body'>
                                    <div className='d-flex align-items-start h-100'>
                                        <div className='wizard_badge_num'>
                                            
                                        </div>
                                        <div className='pl-3 create_wiz_desc'>
                                            <h5 className='text-left'>Pool Ended
</h5>
                                            <p className='mb-0 text-left'>Pool ends at 2024.05.10 13:00 (UTC)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      

                    </div>

                    <div  className='px-4 pb-4'>
                      <p className='white_txt_sm d-flex justify-content-between mt-0 mb-4'>
                                        <span className='desc_grey_txt'>Status</span>
                                        <span className='desc_grey_txt font_12'>
                                        {saleInfo && isSaleLive(saleInfo.startTime, saleInfo.endTime, saleInfo.isPresaleOpen) ?
                                <span className='desc_grey_txt font_12'>Live</span> :
                                (isUpcoming(saleInfo.startTime) ?
                                  <span className='desc_grey_txt font_12'>Upcoming</span> :
                                  <span className='desc_grey_txt font_12'>Closed</span>)}
                                        </span>
                                    </p>

                                    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-4'>
                                        <span className='desc_grey_txt'>Sale Type</span>
                                        <span className='desc_grey_txt font_12'>
                                        {saleInfo && saleInfo.isWhitelisted ?
                                <span className='desc_grey_txt font_12'>Private</span> :


                                <span className='desc_grey_txt font_12'>Public</span>}
                                        </span>
                                    </p>

                                    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-4'>
                                        <span className='desc_grey_txt'>Current Raised</span>
                                        <span className='desc_grey_txt font_12'>
                                        0 ZKETH
                                        </span>
                                    </p>
                    </div>
                 
                    </div>
    </div>
  </div>


</div>


</div>
                 
                </div>



                <div className='pb-5'>

<div className='card_bg_steps mt-5 mb-5'>
  <div className='card-body p-3 p-md-5'>
    <div className='video_div'>
    <iframe width="100%" height="340"
src="https://www.youtube.com/embed/tgbNymZ7vqY">
</iframe>
    </div>
    <div className='d-flex align-items-center justify-content-between'>

    <p className='overlay_card_head text-blak pb-3 mt-5 font_25'>About</p>
  
  
   


  <div className='btn_black ml-3 text_blk_icon px-3' onClick={() => this.handleShowDesc()}>
    <i class="fa fa-pencil text_blk_icon" aria-hidden="true" />
    </div>
  </div>
                <p className='desc_grey_txt mb-0'>{saleInfo.description}
                </p>


    <p className='overlay_card_head text-blak pb-3 mt-5 font_25'>Token</p>
    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Address </span>
              
                <span className='desc_grey_txt font_12'>
                <a href={`${CHAINS[getChainId()].Testnet}${saleInfo && saleInfo.tokenAddress}`} target='_blank' className='desc_grey_txt font_12 word_brsk_tect_new' rel="noreferrer">{saleInfo && saleInfo?.tokenAddress}</a>
                </span>
            </p>
            <hr className='hr_black'/>
            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Name</span>
              
                <span className='desc_grey_txt font_12'>
                Meme of Neko
                </span>
            </p>
            <hr className='hr_black'/>
            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Symbol</span>
              
                <span className='desc_grey_txt font_12'>
                MEKO
                </span>
            </p>

            <hr className='hr_black'/>
            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Decimals</span>
              
                <span className='desc_grey_txt font_12'>
                6
                </span>
            </p>

            <hr className='hr_black'/>
            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Total Supply</span>
              
                <span className='desc_grey_txt font_12'>
                10,000,000,000
                </span>
            </p>
            <hr className='hr_black'/>



            {saleInfo?.LaunchpadType && 
            <div className='mt-5 text-center'>
            
              {this.state.chartstatus && saleInfo?.LaunchpadType && 
              <Chart options={this.state.options}
              // series={this.state.series}
              series={[((saleInfo?.hardCap / 10 ** this.state.charttokendecimal) * saleInfo?.presaleRate),
              ((saleInfo?.hardCap / 10 ** this.state.charttokendecimal) * saleInfo?.presaleRate) - (this.state.unsoldtoken), this.state.unsoldtoken, this.state.liqtoken]}
                className="chart_donut_res" type="pie" />}
                <div className='d-flex align-items-center justify-content-center'>
                <div className='legend_div'>
                  <div className='d-flex align-items-center mb-3'>
                    <div className='bg_legend' style={{backgroundColor:'#793d12'}}/>
                    <p className='legend_lable_text'>Total Tokens</p>

                  </div>

                  <div className='d-flex align-items-center mb-3'>
                    <div className='bg_legend' style={{backgroundColor:'#000000'}}/>
                    <p className='legend_lable_text'>Sold Tokens</p>

                  </div>

                  <div className='d-flex align-items-center mb-3'>
                    <div className='bg_legend' style={{backgroundColor:'#c2641c'}}/>
                    <p className='legend_lable_text'>Unsold Tokens</p>

                  </div>

                  <div className='d-flex align-items-center mb-3'>
                    <div className='bg_legend' style={{backgroundColor:'#915a31'}}/>
                    <p className='legend_lable_text'>Liquidity Tokens</p>

                  </div>
                  </div>
                </div>
                </div>
                }


    <p className='overlay_card_head text-blak pb-3 mt-5 font_25'>Pool Info</p>
    <div className='pb-4'>
    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Address</span>
              
              <span className='desc_grey_txt font_12'>
              <a href={`${CHAINS[getChainId()].Testnet}${this.state.saleAddress}`} target='_blank' className='desc_grey_txt font_12 a_brk_new' rel="noreferrer">{this?.state?.saleAddress}</a>
                </span>
            </p>
            <hr className='hr_black'/>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Tokens For Presale</span>
              
              <span className='desc_grey_txt font_12'>
              4,715,392,388 MEKO
                </span>
            </p>
            <hr className='hr_black'/>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Tokens For Liquidity</span>
              
              <span className='desc_grey_txt font_12'>
              2,284,607,611.986 MEKO
                </span>
            </p>
            <hr className='hr_black'/>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>SoftCap</span>
              
              <span className='desc_grey_txt font_12'>
              {saleInfo && (parseInt(saleInfo?.softCap) / 10 ** 18).toFixed(2)} {this?.state?.buyToken}
                </span>
            </p>
            <hr className='hr_black'/>



            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Start Time</span>
              
              <span className='desc_grey_txt font_12'>
              {saleInfo && padToDigits(new Date(parseFloat(saleInfo.startTime) * 1000).toLocaleDateString()) + "  "
                                  + padToDigits(new Date(parseFloat(saleInfo.startTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(saleInfo.startTime) * 1000).getMinutes()) + ":"
                                  + padToDigits(new Date(parseFloat(saleInfo.startTime) * 1000).getSeconds()) + "(GMT)"}
                </span>
            </p>
            <hr className='hr_black'/>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>End Time</span>
              
              <span className='desc_grey_txt font_12'>
              {saleInfo && padToDigits(new Date(parseFloat(saleInfo.endTime) * 1000).toLocaleDateString()) + "  "
                                  + padToDigits(new Date(parseFloat(saleInfo.endTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(saleInfo.endTime) * 1000).getMinutes()) + ":"
                                  + padToDigits(new Date(parseFloat(saleInfo.endTime) * 1000).getSeconds()) + "(GMT)"}
                </span>
            </p>
            <hr className='hr_black'/>


            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Listing On</span>
              
              <span className='desc_grey_txt font_12'>
              Raydium AMM V4
                </span>
            </p>
            <hr className='hr_black'/>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Liquidity Percent</span>
              
              <span className='desc_grey_txt font_12'>
            51%
                </span>
            </p>
            <hr className='hr_black'/>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
              <span className='desc_grey_txt'>Liquidity Lockup Time</span>
              
              <span className='desc_grey_txt font_12'>
              150 days after pool ends
                </span>
            </p>
              
  </div>
  </div>
</div>
</div>


                </div>
              </div>
            </div>
          </div>
          </div>
          <Footer />
          {whitelistModal && <WhitelistModal saleAddress={saleInfo.saleAddress} buyToken={this.state.buyToken} onDismiss={() => this.setState({ whitelistModal: false })} modalShowHide={() => { this.Showmodal() }} />}
          {removewhitelistModal && <RemoveWhitelistmodal saleAddress={saleInfo.saleAddress} onDismiss={() => this.setState({ removewhitelistModal: false })} />}
          {changestatueModal && <ChangeStatusmodal saleAddress={saleInfo.saleAddress} onDismiss={() => this.setState({ changestatueModal: false })} />}
          {/* {csvModal && <CsvModal onDismiss={() => {this.setState({ csvModal: false })}} modalHide={()=>{this.showWitelistModal()}}/>} */}
          {csvModal && <SampleCsvModal buyToken={this.state.buyToken} onDismiss={() => { this.setState({ csvModal: false }) }} modalHide={() => { this.showWitelistModal() }} />}
          {this.state.descModal && <DescriptionModal Close={() => { this.handleClose() }} SaleInfo={saleInfo} />}

        </div>
      </div>
    )
  }
}

export default PrivatesaleDetail