import React, { Component } from 'react';
import { Modal, InputGroup,FormControl,ProgressBar } from 'react-bootstrap';



class Videomodal extends Component
{
    constructor(props) {
        super(props);
        this.state = {   
                   
            videoModal: true
          
           
        };
    }


  

    render() {
        
  
        const {videoModal} = this.state

        
      return (


        <Modal className="wallet-modal" show={videoModal} centered size="md">
        <Modal.Header className='pt-3 header_odal_head'>
        <h3 className="sec-head ">Video Intro</h3>
            <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

        </Modal.Header>
        <Modal.Body className="select-wallet modal_body">



        <iframe
              width="100%"
              src="https://www.youtube.com/embed/tgbNymZ7vqY"
              className="iframe_style"
            ></iframe>

        </Modal.Body>
    </Modal>



      )
    }

}


export default Videomodal