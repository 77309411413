import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";

import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Trendingslider from '../trendingslider';


import { getAccount } from '../../hooks/useAccount'





import {  Container, FormControl, InputGroup } from 'react-bootstrap';
import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"

import { Link } from 'react-router-dom';
import { getSaleInfo } from '../../hooks/useContract';
import {  updateTokenInfo } from '../../hooks/useAdmin';

class LaunchpadEdit extends Component {
  showLoader()
  {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader()
  {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");



  }
  
   

    constructor(props) {
        super(props);
        this.state = {          
            accountInfo: '',
            saleAddress: window.location.pathname.split('/')[2],
            saleInfo: {},
           
        };
    }
 
async Getdata(){
    const saleDetail = await getSaleInfo(this.state.saleAddress);
  
    this.setState({ saleInfo: saleDetail });
    this.hideLoader();
    
}


async setSocialInfo(){
    await updateTokenInfo(this.state.saleInfo,this.state.saleAddress,getAccount());
    // this.props.onUpdate();
 }




componentDidMount()
{
    
    document.getElementById("launchpad_parent").classList.add("active");
//    this.showLoader();
  this.Getdata()
}
    render() {
        
      const location = this.props.location.pathname.split('/')[1];

      const { walletModal,saleInfo} = this.state

     
      
	return (
    <div id="loader_main">
    <div id="loader_div">
    <span className="spin_round">

    </span>
    <img src={favicon} className="logo_load" />
  </div>
        <div className='logo_overlay' id="logo_overlay">
        <div className="whole_bg">
           <Header/>
          
           <div className="whole_sec pb-5">
           <div className='flex_side_right'>
            <Sidebar />
           {/* ongoing_sec */}
           <div className='right_side_sec'>
          
              <Trendingslider/>
              <div className="right_side_spacing">
              <Container className="mt-5 pb-3 px-1">
                    <div className='col-12  col-md-12 col-lg-12 mx-auto px-0'>
                  
           <div className="tab_img">
                 <div className='card_grad_new card'>
            <div className='card-body'>
                  
            <p className='overlay_card_head text-blak pb-3'>Edit Launchpad</p>
          

            <div>
                <div className='row'>

              
                <div className='col-12 col-md-12 mb-0'>
                        
                        <p className='input_desc_sm'>Logo URL</p>
                        <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl id="file" placeholder={ !saleInfo?.logo ? saleInfo?.logo : saleInfo?.logo }
                            onChange={(e)=>{ let data = saleInfo; data.logo=e.target.value; this.setState({ saleInfo: data }); }} 
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                        <div className='note_desc mt-1 mb-0'>
                    <p>URL must be end with a supported image extension .png, .jpg,</p>
                    </div>
                            </div>

                            <div className='col-12 col-md-12 mb-0'>
                        
                        <p className='input_desc_sm'>Banner URL</p>
                        <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl id="file1" 
                           
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                        <div className='note_desc mt-1 mb-0'>
                    <p>URL must be end with a supported image extension .png, .jpg,</p>
                    </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Website</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            
                            <FormControl placeholder={!saleInfo?.website  ? saleInfo?.social && saleInfo?.social[0] : saleInfo?.website }  id="logolink1" 
                            
                            onChange={(e)=>{ let data = saleInfo; data.website=e.target.value; this.setState({ saleInfo: data }); }} 
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                        </div>
                        <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Twitter</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl placeholder={!saleInfo?.twitter  ? saleInfo?.social && saleInfo?.social[1] : saleInfo?.twitter } 
                            onChange={(e)=>{ let data = saleInfo; data.twitter=e.target.value; this.setState({ saleInfo: data }); }} 
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                        </div>
                        <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Telegram</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl placeholder={!saleInfo?.telegram  ? saleInfo?.social && saleInfo?.social[2] : saleInfo?.telegram } 
                             onChange={(e)=>{ let data = saleInfo; data.telegram=e.target.value; this.setState({ saleInfo: data }); }} 
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                        </div>
                      

                        

                        <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Github</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl id="logolinkgit" placeholder={!saleInfo?.githup  ? saleInfo?.social && saleInfo?.social[7] : saleInfo?.githup }
                            onChange={(e)=>{ let data = saleInfo; data.githup=e.target.value; this.setState({ saleInfo: data }); }} 
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                        </div>

                       

                        <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Instagram</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl  id="logolinkinsta" placeholder={!saleInfo?.instagram  ? saleInfo?.social && saleInfo?.social[8] : saleInfo?.instagram }
                           onChange={(e)=>{ let data = saleInfo; data.instagram=e.target.value; this.setState({ saleInfo: data }); }} 
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Discord</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl  id="logolinkdiscord" placeholder={!saleInfo?.discord  ? saleInfo?.social && saleInfo?.social[9] : saleInfo?.discord }
                            onChange={(e)=>{ let data = saleInfo; data.discord=e.target.value; this.setState({ saleInfo: data }); }} 
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                        </div>
                        
                        <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Reddit</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl  id="logolinkreddit" placeholder={!saleInfo?.reddit  ? saleInfo?.social && saleInfo?.social[10] : saleInfo?.reddit }
                            onChange={(e)=>{ let data = saleInfo; data.reddit=e.target.value; this.setState({ saleInfo: data }); }} 
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                        </div>

                        <div className='col-12 col-md-12 mb-0'>
                    <p className='input_desc_sm'>Youtube Video</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl  id="logolinkyoutube" placeholder={!saleInfo?.githup  ? saleInfo?.social && saleInfo?.social[11] : saleInfo?.youtube }
                            onChange={(e)=>{ let data = saleInfo; data.youtube=e.target.value; this.setState({ saleInfo: data }); }} 
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>

                    <div className='note_desc mt-1 mb-0'>
                    <p>Input your youtube URL</p>
                    </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Description</p>
                    <div className="inputs input-groups text_are_grp">
                        <InputGroup className="">
                        {console.log("sal",saleInfo)}
                            <textarea placeholder={ !saleInfo?.description ? saleInfo?.description : saleInfo?.description } id="description" rows="3"
                            onChange={(e)=>{ let data = saleInfo; console.log("data",data); this.setState({ saleInfo: data.description=e.target.value }); }}
                                aria-describedby="basic-addon2"
                            />
                          
                        </InputGroup>
                    </div>
                    </div>



                    
                    <div className='col-12 col-md-12 text-center'>
                        <Link to="/launchpaddetail" className="btn_black_outline mt-2 mr-2">
                           Back
                        </Link>
                        <button onClick={()=>this.setSocialInfo()}className="btn_black mt-2">
                           Update
                        </button>
                        </div>
                </div>
            </div>

          
                      
             </div>
             </div>
            </div>
            </div>
            </Container>
            </div>
<div className='pb-5'>
            <div className="bg_layer mb-5 mt-4 pt-2">
                <div className="right_side_spacing py-5">
                     
                     <div className="row">
                     
                      <div className="col-12 col-md-7 col-xl-6 mt-4 mt-md-0">
                        <h3 className="h3_res">Premium Launch Order</h3>
                        <h5 className="h5_res">All in one package </h5>
                        <div className='mt-4 pb-4 div_btn_ful_width'>
                          <button className='get-started-btn orbitron_font text-white'>
                          Contact Agent
                          </button>
                          <button className='get-started-btn-border orbitron_font ml-3'>
                          Learn Premium
                          </button>
                        </div>
        
                      </div>
                      <div className="col-12 col-md-5 col-xl-6">
                     <p className="title_span mt-4">Ultimate guideline for launching with 1 v 1 support during the process. Instructing project from technical to marketing/branding aspect for the best outcome.</p>
                      
                      </div>
                     </div>
                    </div>
                    </div>
                    </div>
        

                
               
        </div>

       
            </div>
           </div>
           </div>
           <Footer />
           </div>
           </div>

           
        )
    }
}

export default LaunchpadEdit